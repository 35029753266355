import moment from "moment";
import { firstLetterUpperCase } from "../../../utils";
// import {Button} from "./index";
import { NUMBER_AND_DAYS_MAPING } from "../../../constants";
import {displayTime} from "../utils";
import { deleteIcon, editIcon } from "../../../assets";
import {PrimaryButton} from "../../../components";


export function Notifications({notifications,setIsShowModal,setNotificationToEdit,handleDelete}){

    // console.log(notifications);

    const handleEditClick = (notification) => {
        setNotificationToEdit(notification);
        setIsShowModal(true)
    }

    return <>
        {
            notifications.length === 0 && <>

            <p className='text-center mt-4 text-secondry-color text-lg'>You Don't have notifications</p>
            <div className='mx-auto w-[200px] mt-4'>
                <PrimaryButton style={{padding: "12px 0"}} onClick={()=>setIsShowModal(true)}>
                    Create One
                </PrimaryButton>
            </div>

            </>
        }
        {
            notifications.length !== 0 && <>
            
            <div className="max-w-screen-xl mx-auto">
                <div className="mt-8 shadow-sm border border-secondry-color/20 rounded-lg overflow-x-auto">
                    <ul className="divide-y divide-secondry-color/20 space-y-3">
                        {
                            notifications.map((notification, idx) => (
                                <li key={idx} className="px-4 py-5 duration-150 hover:border-white hover:rounded-xl hover:bg-secondry-color/20 flex justify-between">
                                    <div className="space-y-3 flex-1">
                                        <div className="flex items-center gap-x-3">
                                            <div>
                                                <h3 className="text-base text-gray-800 font-semibold mt-1">{notification.activityName? firstLetterUpperCase(notification.activityName):""} {notification.activityCategory? firstLetterUpperCase(notification.activityCategory):notification.surveytitle.title}</h3>
                                            </div>
                                        </div>
                                        <p className="text-gray-600 sm:text-sm">
                                            {/* {item.job_description} */}
                                        </p>
                                        <div className="text-sm text-gray-600 flex items-center gap-2 md:gap-6">
                                            <span className="flex items-center gap-2">
                                                {moment(notification.startDate).format("ll")}
                                            </span>
                                            -
                                            <span className="flex items-center gap-2">
                                                {moment(notification.endDate).format("ll")}
                                            </span>
                                        </div>
                                        <ul className="space-y-3 pt-8">
                                            {/* Radio groups */}
                                            {
                                                notification.days.map((day, idx) => (
                                                    <li key={idx} className="flex flex-col justify-center gap-2">
                                                        <div className='flex flex-wrap gap-2 items-center'>
                                                            <input checked={true} onChange={()=>{}} type="checkbox" id={idx} className="accent-accent-color form-radio border-gray-400 text-indigo-600 focus:ring-indigo-600 duration-150" />
                                                            <label htmlFor={idx} className="text-sm text-gray-700 font-medium">
                                                                {NUMBER_AND_DAYS_MAPING[day.day].mid}
                                                            </label>
                                                            {
                                                                day.times.map((time,idx) => (
                                                                <span key={idx} className='text-xs bg-secondry-color/40 text-white px-1 font-bold rounded-md flex items-center gap-1'>
                                                                    <span>{displayTime(time)}</span>
                                                                </span>
                                                                ))
                                                            }
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                    <div className='min-w-[32px] flex gap-2 items-start flex-col'>
                                        <button
                                        onClick={()=>handleEditClick(notification)}
                                            title='Edit'
                                            className="p-1.5 md:p-2.5 text-indigo-600 duration-150 bg-secondry-color/20 rounded-lg hover:bg-secondry-color/40 active:bg-indigo-200"
                                        >
                                            <img src={editIcon} alt='edit-icon' className='w-[20px]'/>
                                        </button>
                                        <button
                                            onClick={(e)=>handleDelete(e,notification)}
                                            title='Delete'
                                            className="p-1.5 md:p-2.5 text-indigo-600 duration-150 bg-secondry-color/20 rounded-lg hover:bg-secondry-color/40 active:bg-indigo-200"
                                        >
                                            <img src={deleteIcon} alt='delete-icon' className='w-[20px]'/>
                                        </button>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
            
            </>
        }
    </>
}