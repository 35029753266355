import React from 'react';
import {BsInfoCircle} from "react-icons/bs";
import {Tooltip}  from 'react-tooltip';


function InfoIconWithToolTip(props) {
  const remProps = {...props};
  const content = remProps.content;
  const tooltipStyle = remProps.tooltipStyle || {};
  delete remProps.content;
  delete remProps.tooltipStyle;
  const rest = content.replace(/[^a-zA-Z]/gi, '');
  const id = `${rest}-tooltip`;


  return (
    <>
      <ToolTipInfoIcon  {...remProps} id={id} style={{ cursor:"pointer", display:"inline-block"}}/>
      <RectTooltip 
        style={{
          ...tooltipStyle
        }} 
        html={content} 
        anchorSelect={`#${id}`}
      />   
    </>
  )
}


function ToolTipInfoIcon(props) {
  // return <img {...props} className='w-[12px]' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADd0lEQVR4nO2aT08UQRDFfwSENRiRhRWPcjQY5VMoRFTghuJNgxeRoFeVm4ieSEj4HBpC0KghUSP+QbmoKJwED0a8uUDQjKn4JunEmYWdnd0dCC+ZZHeqp7prurq6+tXALnYu0kAncBeYAD4BP4F1Xfb7o2TW5ixQT0KQAi4AD4E/gJfn9RuYAnqBmnIYsBe4BnxzBrUGPAFuaGaO6I3v0VWveya7CTzVM/7zy8CgXk5JcApYdAbwGrgI1EXQdQC4BLxx9C0A7RQR9qbGnQ7fAidi1N8GvHP0jxVjdg5p4NbBL+AKUBl3J/zTeRXIOrPdFJfyZk23p6hzlOLjGDCvPr9oDAUh4yh8BTRSOtQDz9T3orwiElKOO70Aaik9aoGXjptFWjPjjjvZZpcvqoE7CtEWWod1L180OF5hASDvEOsv7KhrYjhgA7R7UddMVjosum15s/P3CYtOUbEcYIjdi4oBZ/FvycWuO/tEZYIMqQLeS48ZlRMpJ+0odLMLcq3bBepsl56lzXKzXidCFIpqGbNc4GJ3UeFE0nPkwCM1stwpqejTGCfDGqSVVq9FTABLuVGuAxth4+ySpY9JPqY11tNBwnsS2nki6RjSWEeChBMSnomho7ATYVzokr77QcLPEtopLumGtEif8QL/YUXCKHlVGIplSKP0fQ8SrktYaKwvhSE1Dk+wcw1Z2UaulcnlWnEudh9lWewTEhoDmHRDunOFX39DNPIs6YYM5doQOyU0tjDphkxLX0dYMuYnjcYAJtWQtJM07g9rNKUOjcZMqiGXpcvWdCjOq5FxsUk0pAKYla6ezTaaJTU8mUBDOqTn61bKEINqPBsDvxunIVXAnPT0b+WBlMP1GqEcZ/ZbiEGDenY+n6KQz1ZkRY6V25BWYDUquzPmvAGjLcuFjEg5G8toFAUp0UKeiORykNj7gBmNYaaQOmNGiZlfVrD/pUIaeK6+F+Io+DQ7U2tudpzio9Xp07Lyw3EpbnLcLCvu1cJh3KhSdFp13Olg3J2knADgiVCOq/paoVLGnKN/tNi19zZn2j1xsX0Rv2JIK3fy0w5PrhRntXjT2Rlw0hlPGaml17fEO7UobFfralDRqFttph2ewE87+sv1BUSNWPFJHQG8PK8NZbE95TIgCHXiYu3U9kC1xx/ORzX2+4OOpyNqG3qe2AXbHH8BUFp9NS4UzQMAAAAASUVORK5CYII=" alt='info-icon'/>
  return <BsInfoCircle {...props}/>;
}

function RectTooltip(props){
  const remProps = {...props};
  const children = remProps.children;
  delete remProps.children;
  
  return <Tooltip {...remProps} variant="info" style={{fontSize: "14px",maxWidth: "200px",fontWeight: "normal"}}>
            {children}
         </Tooltip> 
}


export {
  InfoIconWithToolTip,
  ToolTipInfoIcon,
  RectTooltip
};


