import React,{useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {createUser} from "../../Store/actions";
import {PrimaryButton,PasswordInputWithEye} from "../../components";
import { Main } from '../../layout';

function Index() {
    const dispatch = useDispatch();
    const [user, setUser] = useState({
            surname: "",
            forename:"",
            email: "",
            password: "",
            profession: "",
            organizationName: "",
            isManager: false,
            termAndConditions: false
        });
    // const [image, setImage] = useState("");
    // const [imageUrl, setImageUrl] = useState(null);
    const loggedInUser = useSelector(state => state.loggedInUser);
    const loading = useSelector(state => state.globalStates.loading);
    const navigator = useNavigate();
    const [creatingUser, setCreatingUser] = useState(false);


    const handleChnange = (e)=>{
        setUser(prev =>{
            if(e.target.name === "isManager"){
                return {
                    ...prev,
                    [e.target.name]: e.target.checked
                }  
            }
            if(e.target.name === "termAndConditions"){
                return {
                    ...prev,
                    [e.target.name]: e.target.checked
                }
            }
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    // const handleImageChange = (e)=>{
    //             const file = e.target.files[0];
    //             setImage(e.target.value);
    
    //             const reader = new FileReader();
    //             reader.onload = () => {
    //                 setImageUrl(reader.result);
    //             };
    //             reader.readAsDataURL(file);
    // }

    const handleSubmit = (e) =>{
        e.preventDefault();
        dispatch(createUser(user));
        setCreatingUser(true);
    }

 
    useEffect(() => {
        if(loggedInUser){
            if(creatingUser){
              navigator("/un-regular-surveys/238946829375");
            }
            else {
              navigator("/");
            }
        }
    }, [loggedInUser,navigator,creatingUser]);

  return (
    <>
     <Main>
        <h2 className="mb-3 text-3xl font-semibold text-center">Create Account</h2>
        <p className="text-sm text-center text-gray-400">Already have account?
                        <Link to="/login" className='focus:underline hover:underline'>
                           Login here
                        </Link>
        </p>
                   
        <form noValidate="" action="" onSubmit={handleSubmit} className="w-[500px] max-w-full space-y-8 ng-untouched ng-pristine ng-valid my-6">
                        <div className="space-y-4">
                            <div className="space-y-2">
                                <label htmlFor="forename" className="block text-sm">Forename</label>
                                <input required value={user.forename} onChange={handleChnange} type="text" name="forename" id="forename" placeholder="forename" className="w-full px-3 py-2 border rounded-md border-brandbg2 bg-brandbg1 text-brandtext1 focus:border-brandbg2" />
                            </div>
                            <div className="space-y-2">
                                <label htmlFor="surname" className="block text-sm">Surname</label>
                                <input required value={user.surname} onChange={handleChnange} type="text" name="surname" id="surname" placeholder="surname" className="w-full px-3 py-2 border rounded-md border-brandbg2 bg-brandbg1 text-brandtext1 focus:border-brandbg2" />
                            </div>
                            <div className="space-y-2">
                                <label htmlFor="email" className="block text-sm">Email address</label>
                                <input required value={user.email} onChange={handleChnange} type="email" name="email" id="email" placeholder="leroy@jenkins.com" className="w-full px-3 py-2 border rounded-md border-brandbg2 bg-brandbg1 text-brandtext1 focus:border-brandbg2" />
                            </div>
                            <div className="space-y-2">
                                <div className="flex justify-between">
                                    <label htmlFor="password" className="text-sm">Password</label>
                                </div>
                                <PasswordInputWithEye value={user.password} onChange={handleChnange} name={"password"} id={"password"}/>
                                {/* <input required value={user.password} onChange={handleChnange} type="password" name="password" id="password" placeholder="*****" className="w-full px-3 py-2 border rounded-md border-brandbg2 bg-brandbg1 text-brandtext1 focus:border-brandbg2" /> */}
                            </div>
                            <div className="space-y-2">
                                <div className="flex items-center gap-2">
                                    <label className="text-sm">Are you a: </label>
                                </div>
                                <div className="flex justify-between gap-2">
                                    <div className="flex flex-1 items-center pl-4 border border-gray-200 rounded dark:border-gray-700">
                                        <input required  type="radio" id='student' value="student" name='profession' onChange={handleChnange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                        <label htmlFor="student" className="w-full py-4 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Student</label>
                                    </div>
                                    <div className="flex flex-1 items-center pl-4 border border-gray-200 rounded dark:border-gray-700">
                                        <input required type="radio" id='working professional' value="working professional" name='profession' onChange={handleChnange} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                        <label htmlFor="working professional" className="w-full py-4 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Working Professional</label>
                                    </div>
                                </div>
                            </div>

                            {
                                user.profession && <>
                                 {
                                    user.profession === "student"? <>
                                     <div className="space-y-2">
                                        <div className="flex justify-between">
                                            <label htmlFor="password" className="text-sm">School Or University Name</label>
                                        </div>
                                        <input value={user.organizationName}  onChange={handleChnange} type="text" name="organizationName" id="password" placeholder="eg: Harvard" className="w-full px-3 py-2 border rounded-md border-brandbg2 bg-brandbg1 text-brandtext1 focus:border-brandbg2" />
                                    </div>
                                    </>:<>
                                    <div className="space-y-2">
                                        <div className="flex justify-between">
                                            <label htmlFor="password" className="text-sm">Company or Organisation Name</label>
                                        </div>
                                        <input value={user.organizationName} onChange={handleChnange} type="text" name="organizationName" id="password" placeholder="eg: Google" className="w-full px-3 py-2 border rounded-md border-brandbg2 bg-brandbg1 text-brandtext1 focus:border-brandbg2" />
                                    </div>
                                    </>
                                 }
                                </>
                            }

                            <div className="space-y-2">
                                <div className="flex items-start">
                                    <div className="flex items-center h-5">
                                        <input value={user.termAndConditions} onChange={handleChnange} id="terms" name='termAndConditions' aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required=""/>
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label htmlFor="terms" className="font-light text-gray-500 dark:text-gray-300">I accept the <Link to="/term-and-conditions" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Terms and Conditions</Link></label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <PrimaryButton disabled={user.termAndConditions? false:true} className="text-white py-3 px-4 w-full uppercase font-bold disabled:cursor-not-allowed">
                            {loading? "Creating...":"Sign Up"}
                        </PrimaryButton>
        </form>
     </Main>
    </>    
  )
}

export default Index;


