import React, { useMemo } from 'react';
import {mapper__100_100_to_0_100} from "../../../utils";
import { getBrainColor } from '../../UserDashboard/components/ScoreInBrainWithLabels';

const text = `Please feel that you can speak to me… or use the wellbeing resources (link/phone number) if you would like more support…. Don’t forget that you can report unacceptable behaviours using our hotline….. You anonymity and privacy has been protected.`;

function HeatMap({histogramData}) {
    histogramData = histogramData.map(item => {return {...item,XLScore: mapper__100_100_to_0_100(item.XLScore)}})
    const rows = useMemo(()=>Math.ceil(Math.sqrt(histogramData.length)),[histogramData]);

 

  return (
        <>
          <div className={`grid ${`grid-cols-${rows}`} grid-rows-${rows} w-[100%] h-[100%] relative`}>
            {
              histogramData && histogramData.map((item,i) => {
                // console.log(item.XLScore);
                return <div  key={i} className={`border hover:cursor-pointer relative`} title={item.XLScore <0? text:item.XLScore} style={{backgroundColor: getBrainColor(item.XLScore)}} >
                          {
                            item.streak && <div title={`How many days this individual has been below 50`} className="absolute top-1 right-1 w-5 h-5 rounded-full bg-blue-800 flex justify-center items-center text-[12px] text-white font-bold cursor-pointer">{item.streak}</div>
                          }
                       </div>
              })
            }
          </div>
        </>
  )
}

export {HeatMap};