import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {Main} from "../layout";
import {ProgressBar,PrivacyPolicyPopup, PrimaryButton, SecondryButton, CookiePolicyPopup} from "../components";
import { Link } from "react-router-dom";
import { createWaitingList, getNumberOfPeopleAreWaiting } from "../Store/actions";
import {cognitiveBlueprintMapping,realTimeFeedback,customisedStrategicCoaching,flowStateOptimisation} from "../assets";




  function LandingPageA(){
      const [showPopup, setShowPopup] = useState(false);
      const [showPrivacyPolity, setShowPrivacyPolity] = useState(false);
      const [showCookiePolity, setShowCookiePolity] = useState(false);

      const list = [
        {
          title: "Maximise Productivity through Flow",
          description: "Enhance your focus and efficiency dramatically. Research shows that being in a flow state can increase productivity by up to 500%."
        },
        {
          title: "Enhance Creativity",
          description: "Stay ahead in innovation by reaching and maintaining your creative peak with our tailored tools."
        },
        {
          title: "Accelerate Learning",
          description: "Develop new skills faster with advanced insights that keep you agile in a dynamic market."
        },
        {
          title: "Advanced Problem-Solving",
          description: "Transform challenges into opportunities with enhanced problem-solving capabilities facilitated by flow states."
        }
      ]
      
      function Title({children}){
        return <h1 className="text-center mb-4 text-xl md:text-2xl font-bold text-primary-button-color">{children}</h1>
      }

      if(showPrivacyPolity){
        return <PrivacyPolicyPopup setIsOpen={setShowPrivacyPolity}/>
      }

      if(showCookiePolity){
        return <CookiePolicyPopup setIsOpen={setShowCookiePolity}/>
      }

      return (
        <>
          <Main>
            {/* Main Content Section */}
            <main className="max-w-7xl mx-auto py-12">
              <Header setShowPopup={setShowPopup}/>

              <Section>
                  <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-md">
                    <Content>
                        <h1 className="pt-8 md:pt-0 mb-2 text-xl md:text-2xl text-center font-bold text-primary-button-color">Discover the Ultimate Peak Performance Coach</h1>
                    
                        <div className="flex flex-col md:flex-row mt-12">
                          <div className="mb-5 md:mb-0 md:w-[50%] md:pr-6">
                              <Para className="font-semibold">Imagine having a personal coach who sees your hidden potential and tailors strategies to help you unlock it in real-time.</Para>
                              <Para className="mt-5">The XLRate platform is the ultimate Peak Performance Coach for visionaries, elite professionals and executives. Its exclusive <span className="font-bold">Fitbit for your mind</span> technology evaluates your cognitive strengths and areas for improvement in 60 seconds.</Para>
                              <Para className="mt-5">The XLRate coach then provides you with data-driven, personalised actions and strategies to achieve high-performance ‘flow’ states and transform you into the best version of yourself.</Para>
                          </div>
                          <div className="md:w-[50%] md:pl-6">
                            <img className="rounded-md shadow-xl md:mt-2" src="https://res.cloudinary.com/dsnjr85ys/image/upload/v1717407153/XLRate%20app.png" alt="XLRate app: A peak performance coach in your pocket."/>
                            <small className="text-secondry-color text-center mt-2 block">XLRate app: A peak performance coach in your pocket.</small>
                          </div>
                        </div>


                        <div className="py-10">
                          <Title>What is a Flow State?</Title>
                          <Para>
                            Flow states, often called "in the zone," are periods of intense focus and immersion in an activity. During flow, individuals experience heightened creativity, improved problem-solving abilities, and a significant boost in productivity. XLRate helps you tap into these optimal mental states to perform at your peak when you need it most.
                          </Para>
                        </div>


                        <div className="py-10">
                          <Title>Why Elite Performers Choose XLRate</Title>
                          {
                            list.map((item,idx) => (
                              <div key={idx} className="w-full">
                                  <div className="rounded flex md:px-6 py-2 h-full items-center">
                                      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"
                                          className="text-secondry-button-color w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                          <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                          <path d="M22 4L12 14.01l-3-3"></path>
                                      </svg>
                                      <Para><span className="text-secondry-color"><span className="font-semibold">{item.title}</span> {item.description}</span></Para>
                                  </div>
                              </div>
                            ))
                          }
                        </div>


                        <div className="py-10">
                          <Title>XLRate's Founder</Title>
                          <div className="flex flex-col-reverse md:flex-row mt-8">
                            <div className="md:w-1/2 flex flex-col items-center md:pr-5">
                                <img className="rounded-md md:w-[87%] mt-2" src="https://res.cloudinary.com/dsnjr85ys/image/upload/v1717581396/Benjafield_Neuropsychology_research_gsvqop.png" alt="founder"/>
                                <small className="text-center text-secondry-color mt-2">Howard Benjafield’s Neuro-Psychology Research</small>
                            </div>
                            <div className="md:w-1/2 md:pl-5 mb-3 md:mb-0">
                                <Para>Howard Benjafield, an expert in the Neuro-Psychology of Productive Cognitive Flow, leads the XLRate team. <br/><br/>
                                      With a background that includes innovative research at world-leading organisations like Harvard Medical School and in Defense and Security, he's dedicated to using cutting-edge science and technology to enhance human performance and well-being. He works exclusively with a small group of hand-chosen elite clients. <br/><br/>
                                      Howard's passion drives XLRate's mission to help visionaries and high-performance professionals realise and release their hidden potential, significantly impacting their professional and personal lives.
                                </Para>
                            </div>
                          </div>
                        </div>


                        <div className="pt-10 pb-8 md:pb-0">
                          <Title>XLRate Delivers Tailored Cognitive Optimisation Throughout Your Day</Title>
                          <Para>
                            XLRate provides real-time insights and personalised feedback to help you adjust your work approach to match your most effective cognitive states. This capability enhances your productivity and effectiveness in leadership roles, enabling better decision-making and team management.
                          </Para>
                        </div>
                    </Content>
                  </div>
              </Section>

              <Days90Include/>
              <Days90Expect/>
              
              <Feature/>

              <CTA setShowPopup={setShowPopup}/>
            </main>

            {/* Footer Section */}
            <Footer setShowPrivacyPolity={setShowPrivacyPolity} setShowCookiePolity={setShowCookiePolity}/>
            
          </Main>
          {
            showPopup && <Popup setShowPopup={setShowPopup}/>
          }
        </>
      )
  }

  export {LandingPageA};

  const Header = ({setShowPopup}) => {
      return (
        <>
          <Section>
            <div className="flex flex-col md:items-center max-w-7xl md:px-8">
              <h1 className="max-w-3xl text-3xl md:text-6xl leading-10 md:leading-[70px] text-center text-primary-button-color font-bold mb-14"> Unleash Your Executive Potential with the XLRate Flow Coaching 90-day programme.</h1>
            </div>
            <img
              src="https://res.cloudinary.com/dsnjr85ys/image/upload/v1717406588/XLRate%20platform%20in%20a%20Luxury%20office.png"
              className="w-full max-w-screen-sm mx-auto rounded-md shadow-[0_3px_10px_rgb(0,0,0,0.2)] md:w-auto lg:max-w-screen-md"
              alt="xlrate-office"
            />
          </Section>
        </>
      );
  };


  function Feature(){
  
    const items = [
      {
        title: "Cognitive Blueprint Mapping",
        description: "Tailor your mental performance optimally for critical tasks using our sophisticated machine-learning techniques.",
        icon: cognitiveBlueprintMapping,
      },
      {
        title: "Real-Time Feedback",
        description: "Continuously monitor your cognitive performance like a fitness tracker monitors physical health. ",
        icon: realTimeFeedback,
      },
      {
        title: "Customised Strategic Coaching",
        description: "Receive coaching that aligns with your schedule and professional growth, ensuring constant development.",
        icon: customisedStrategicCoaching,
      },
      {
        title: "Flow State Optimisation",
        description: "Learn to achieve and maintain peak mental states to boost operational effectiveness and strategic thinking.",
        icon: flowStateOptimisation,
      },
    ]

    return (<>
        <Section>
          <div className="mx-auto max-w-7xl">
                <div className="mb-10 mx-[20px] lg:mb-16">
                        <h2 className="text-xl md:text-2xl text-center font-bold text-primary-button-color">
                            Experience the Next Level of Performance and Personal Growth
                        </h2>
                </div>
                <div className="flex justify-center items-streach  gap-x-5 gap-y-5 lg:gap-y-0 flex-wrap md:flex-wrap lg:flex-nowrap lg:flex-row lg:justify-between lg:gap-x-8">
                    {
                      items.map((item,idx) => (
                        <div key={idx} className="group shadow-[0_3px_10px_rgb(0,0,0,0.2)] relative w-full rounded-2xl p-4 transition-all duration-500 max-md:max-w-md max-md:mx-auto md:w-2/5 xl:p-7 xl:w-1/4 hover:bg-secondry-button-color">
                            <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-full flex justify-center items-center mb-5 w-28 h-28 md:w-[208px] md:h-[208px] mx-auto">
                                <img src={item.icon} alt="icon"/>
                            </div>
                            <h4 className="text-md md:text-xl text-center md:text-start font-semibold text-gray-900 mb-3 capitalize transition-all duration-500 group-hover:text-white">{item.title}</h4>
                            <Para className="text-center md:text-start group-hover:text-white transition-all duration-500">
                                {item.description}
                            </Para>
                        </div>
                      ))
                    }
                </div>
          </div>
        </Section>
    </>)
  }

  function CTA({setShowPopup}) {
    const [numberOfPeople, setNumberOfPeople] = useState(0);


    useEffect(() => {
      getNumberOfPeopleAreWaiting()
      .then(res => {
        setNumberOfPeople(res.people);
      })
      .catch(error => {
        console.error(error);
      })
    }, []);

    return (
      <Section>
        <div className="rounded-xl py-14 shadow-[0_3px_10px_rgb(0,0,0,0.2)] sm:px-6 lg:px-8">
          <Content pxOnly>
              <div className="flex flex-col-reverse md:flex-row">
                <div className="md-6 flex-1 pt-8 md:pt-0">
                    <h4 className="text-md  md:text-xl font-semibold text-gray-900 mb-2">Ready to Elevate Your Performance through 'Flow'?</h4>
                    <Para className="">Join the exclusive waitlist for the XLRate Flow Programme to unlock your potential. Experience the transformative power that has benefited a select group of high achievers.</Para>
                  
                    <h4 className="mt-10 text-md md:text-xl font-semibold text-gray-900 mb-2">Transform Your Cognitive Performance</h4>
                    <Para>Seize this unique opportunity to enhance your cognitive abilities and unlock your highest potential.</Para>

                    <h4 className="mt-10 text-md md:text-xl font-semibold text-gray-900 mb-2">Limited Availability</h4>
                    <Para>There will be only <span className="font-semibold">2</span> spaces released for the next 90-day programme, and currently, <span className="font-semibold">{numberOfPeople}</span> people are on the waiting list.</Para>
                </div>
                <div className="flex-1 flex items-start justify-center">
                  <img className="md:hidden" src="https://res.cloudinary.com/dsnjr85ys/image/upload/v1704211715/Copy_XLRate_new_logo_landscape_gewvxp.png" alt="name"/>
                  <img className="h-full hidden md:block md:h-[390px]"  src="https://res.cloudinary.com/dsnjr85ys/image/upload/v1704051668/XLRate_Logo_w_Tag_New_122023_1080sq_wr8svt.png" alt="logo"/>
                </div>
              </div>
              <div className="mt-10 shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full md:w-[200px] rounded-md mx-auto">
                      <SecondryButton
                        onClick={(e)=>setShowPopup(true)}
                        style={{padding: "12px 20px"}}
                      >
                        JOIN WAITLIST
                      </SecondryButton>
              </div>
          </Content>
        </div>
      </Section>
    );
  }

  function Popup({setShowPopup}){
    const [data, setData] = useState({
      firstName: "",
      surName: "",
      email: "",
      showForm: true,
      showQuestions: false,
      showThankYou: false,
      answers: null,
    });
    const [thankYouMessageData, setThankYouMessageData] = useState(null);

    const handleChange = (e) => {

      setData(prev => {
        return {
          ...prev,
          [e.target.name]: e.target.value
        }
      })
    }




    return (<>
      <div className="w-[100vw] h-[100vh] min-h-[100vh] bg-black/50 fixed top-0 left-0">
            {
              data.showForm && <UserDetail setShowPopup={setShowPopup} data={data} setData={setData} handleChange={handleChange}/>
            }
            {
              data.showQuestions && <QuestionsDetail data={data} setData={setData} setThankYouMessageData={setThankYouMessageData}/>
            }
            {
              data.showThankYou && <ThankYouMessage thankYouMessageData={thankYouMessageData} setShowPopup={setShowPopup}/>
            }
      </div>
    </>)
  }

  function UserDetail({data,setData,handleChange,setShowPopup}){

    const isValid = (email) =>  {

      const filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

      if (!filter.test(email)) {
        return false;
      }

      return true;
      
    }


    const handleStart = (e) => {
      if(data.email && data.firstName && data.surName){
        e.preventDefault();

        if(!isValid(data.email)){
          toast.error("Invalid Email");
          return;
        }

        setData(prev => {
          return {
            ...prev,
            showForm: false,
            showQuestions: true,
          }
        });
      }
    }


    const handleOutSideClick = (e) => {

      if(e.target.id === "user-detail"){
        setShowPopup(false)
      }

    }


    return (
      <>
      <div onClick={handleOutSideClick} id="user-detail" className="w-full h-full flex justify-center items-center px-2">
          <form className="w-[450px] max-w-full mx-auto  bg-white p-10 rounded-md">
            <div className="relative z-0 w-full mb-5 group">
                <input required value={data.firstName} onChange={handleChange}  autoComplete="off" type="text" name="firstName" id="firstName" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-secondry-button-color peer" placeholder=" "/>
                <label htmlFor="firstName" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-secondry-button-color peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">First name</label>
            </div>
            <div className="relative z-0 w-full mb-5 group">
                <input required value={data.surName} onChange={handleChange} autoComplete="off" type="text" name="surName" id="surName" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-secondry-button-color peer" placeholder=" "/>
                <label htmlFor="surName" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-secondry-button-color peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Last name</label>
            </div>
            <div className="relative z-0 w-full mb-5 group">
                <input required value={data.email} onChange={handleChange} autoComplete="off" type="email" name="email" id="floating_email" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-secondry-button-color peer" placeholder=" "/>
                <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-secondry-button-color peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
            </div>
            <PrimaryButton onClick={handleStart} style={{padding: "14px 0",marginTop: "40px"}} type="submit">
              Start
            </PrimaryButton>
          </form>
      </div>
      </>
    )
  }

  let timeOut = undefined;
  function QuestionsDetail({data,setData,setThankYouMessageData}){

    const [questions] = useState([
      {
        title: "Cognitive Performance Importance",
        instruction: "Please indicate how much you agree with the following statement.",
        question: "Enhancing my cognitive performance is critical to my professional success.",
        type: "rangeSlider",
        startTag: "Strongly disagree",
        endTag: "Strongly agree",
        range: {min: 0,max: 100}
      },
      {
        title: "Top Challenges",
        instruction: "Select 3 options that resonate with you.",
        question: "What critical challenges do you face right now?",
        hint: "Hint: No.1 should be the one you think about first when you wake up and last before you go to sleep).",
        type: "multipleChoise",
        choises: ["Time Management","Stress Management","Decision Making","Focus and Concentration","Energy Levels","Work-Life Balance"],
        other: true,
        name: "challenge"
      },
      {
        title: "Current Satisfaction Cognitive Performance",
        instruction: "Please indicate how much you agree with the following statement.",
        question: "I am satisfied with my current methods for managing and enhancing cognitive performance.",
        type: "rangeSlider",
        startTag: "Strongly disagree",
        endTag: "Strongly agree",
        range: {min: 0,max: 100}
      },
      {
        title: "Indispensable Features",
        instruction: "Select up to 3 features",
        question: "What would make the XLRate Peak performance programme indispensable to you?",
        type: "multipleChoise",
        choises: ["1-2-1 onboarding with your personal XLRate expert","Discovering your Cognitive Performance rhythms and cycles.","Real-time Personalised Peak Performance and Flow Coaching","Understanding your unique Cognitive blueprint for Peak performance states","Cutting edge ‘Wearable’ ‘Peak performance’ Device for continuous monitoring after the 90-day programme","Weekly Peak Performance and Lived Experience reports","Weekly 1-2-1 coaching and strategy consultations with Flow experts","Your personal Peak performance plan","12 months access to the XLRate Flow platform"],
        other: true
      },
      {
        title: "Recommendation Likelihood",
        instruction: "Please indicate how much you agree with the following statement.",
        question: "I would be likely to recommend a programme like XLRate to my peers or colleagues.",
        type: "rangeSlider",
        startTag: "Strongly Disagree",
        endTag: "Strongly Agree",
        range: {min: 0,max: 100}
      },
      {
        title: "Investment Willingness",
        instruction: null,
        question: "How much would you be willing to invest in a 90 day programme like XLRate?",
        type: "rangeSlider",
        startTag: "Less than £1000",
        endTag: "More than £5000",
        range: {min: 0,max: 5000},
        label: true
      },
      {
        title: "Beta Testing Participation",
        instruction: null,
        question: "How much would you be willing to pay per month to participate in a XLRate beta testing programme?",
        hint: "Hint: Monthly reduced payments in exchange for providing feedback and testimonials.",
        type: "rangeSlider",
        startTag: "Less than £100",
        endTag: "£300 or more",
        range: {min: 0,max: 300},
        label: true
      },
      {
        title: "Cost Barrier",
        instruction: "Please indicate how much you agree with the following statement.",
        question: "If I decided not to join the XLRate Peak Performance programme it would most likely be due to the cost.",
        type: "rangeSlider",
        startTag: "Strongly Disagree",
        endTag: "Strongly Agree",
        range: {min: 0,max: 100}
      },
      {
        title: "Desired Outcomes",
        instruction: null,
        question: "Which of the following outcomes would make you feel the investment in XLRate is worthwhile?",
        type: "multipleChoise",
        choises: [
            "Increased Productivity (Achieving more in less time)",
            "Better Problem-Solving (Improved decision-making skills)",
            "Accelerated Learning (Faster and more effective learning)",
            "Increased Fulfilment (Greater satisfaction and happiness at work)",
            "Flow State Optimisation (More frequent and sustained flow states)",
            "Personal Development (Overall growth and self-improvement)"
          ],
        other: false
      },
    ]);
    const [quesIndex, setQuesIndex] = useState(0);
    const [challengesOptions, setChallengesOptions] = useState([
      {
          order: null, 
          option: "Feeling Overwhelmed",
          isSelected: false,
          tag: "Emotional Barriers"
      },
      {
          order: null, 
          option: "Fear of Failure",
          isSelected: false,
          tag: "Emotional Barriers"
      },
      {
          order: null, 
          option: "Confidence Issues",
          isSelected: false,
          tag: "Emotional Barriers"
      },
      {
          order: null, 
          option: "Stress and Burnout",
          isSelected: false,
          tag: "Emotional Barriers"
      },
      {
          order: null, 
          option: "Imposter Syndrome",
          isSelected: false,
          tag: "Emotional Barriers"
      },
      {
          order: null, 
          option: "Limited Skillset",
          isSelected: false,
          tag: "Skillset Limitations"
      },
      {
          order: null, 
          option: "Lack of Clear Goals",
          isSelected: false,
          tag: "Skillset Limitations"
      },
      {
          order: null, 
          option: "Decision-Making Pressures",
          isSelected: false,
          tag: "Skillset Limitations"
      },
      {
          order: null, 
          option: "Procrastination",
          isSelected: false,
          tag: "Motivational Challenges"
      },
      {
          order: null, 
          option: "Lack of Direction",
          isSelected: false,
          tag: "Motivational Challenges"
      },
      {
          order: null, 
          option: "Seeking Motivation",
          isSelected: false,
          tag: "Motivational Challenges"
      },
      {
          order: null, 
          option: "Finding Focus",
          isSelected: false,
          tag: "Motivational Challenges"
      },
      {
          order: null, 
          option: "Balancing High-Stakes Responsibilities",
          isSelected: false,
          tag: "Leadership Challenges"
      },
      {
          order: null, 
          option: "Managing Large Teams",
          isSelected: false,
          tag: "Leadership Challenges"
      },
      {
          order: null, 
          option: "Lack of Support",
          isSelected: false,
          tag: "Leadership Challenges"
      },
      {
          order: null, 
          option: "Fostering Better Workplace Relationships",
          isSelected: false,
          tag: "Leadership Challenges"
      },
      {
          order: null, 
          option: "Enhancing Work-Life Balance",
          isSelected: false,
          tag: "Work-Life Balance"
      },
    ]);
    const [answers, setanswers] = useState([]);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const question = questions[quesIndex];

    const getLabel = (value) => {

      let ans = undefined;

      if(value >= 0 && value <= 1000){
        ans = `0 - £1000`;
      }
      if(value > 1000 && value <= 1999){
        ans = `£1000 - £1999`;
      }
      if(value >= 2000 && value <= 2999){
        ans = `£2000 - £2999`;
      }
      if(value >= 3000 && value <= 3999){
        ans = `£3000 - £3999`;
      }
      if(value >= 4000 && value <= 4999){
        ans = `£4000 - £4999`;
      }
      if(Number(value) === 5000){
        ans = `£5000 +`;
      }
      if(quesIndex === 5){
        return ans;
      }


      if(value < 100){
        ans = `Less than £100`;
      }
      if(value >= 100 && value <= 149){
        ans = `£100 - £149`;
      }
      if(value >= 150 && value <= 199){
        ans = `£150 - £199`;
      }
      if(value >= 200 && value <= 249){
        ans = `£200 - £249`;
      }
      if(value >= 250 && value <= 299){
        ans = `£250 - £299`;
      }
      if(Number(value) === 300){
        ans = `£300 or more`;
      }

      return ans
    }

    const prepareAnswers = (ans,idx) => {
      let key = undefined;
      let value = undefined;

      if(questions[idx].type === "rangeSlider"){
        key = "value";
        value = Number(ans);
      }
      if(questions[idx].type === "multipleChoise"){
        key = "choices";
        value = ans;
      }
      if(questions[idx].name === "challenge"){
        const as = challengesOptions.filter(item => item.isSelected).sort((a,b) => a.order-b.order).map(item => item.option);
        key = "choices";
        value = ans? [...ans,...as]:as;
      }


      return {
        order: idx+1,title: questions[idx].title,
        question: questions[idx].question,
        type: questions[idx].type,
        [key]: value
      }
    }

    const handleNext = async (e) => {
      if(e === null){
        if(quesIndex === questions.length-1){
          // console.log("last question");
          const waitingList = {
            firstName: data.firstName,
            surName: data.surName,
            email: data.email,
            pageName: "A",
            answers: answers.map(prepareAnswers)
          };
          setIsSubmiting(true);
          const res = await createWaitingList(waitingList);
          setIsSubmiting(false);

          if(res.success){
            setThankYouMessageData({currentNumber: res.currentNumber,numberOfPeople: res.numberOfPeople});
            setData(prev => {
              return {
                ...prev,
                showQuestions: false,
                showThankYou: true
              }
            });
          }
          else {
            toast.error("Something went wrong!");
          }
          return;
        }
        setQuesIndex(prev => prev+1);
        return;
      }

      if(question.type === "rangeSlider"){
        if(timeOut !== undefined){
          clearTimeout(timeOut);
        }


        setanswers(prev => {
          const newArray = [...prev];
          newArray[quesIndex] = e.target.value;
          return newArray;
        });

        timeOut = setTimeout(() => {
                    setQuesIndex(prev => prev+1);
                  }, 1000);
        return;
      }

      if(question.type === "input"){
        setanswers(prev => {
          const newArray = [...prev];
          newArray[quesIndex] = {
            ...newArray[quesIndex],
            [e.target.name]: e.target.value
          };

          return newArray;
        });
        return;
      }

      if(question.type === "multipleChoise"){

        // if other 
        if(e.target){
          setanswers(prev => {
            const newArray = [...prev];
            
            newArray[quesIndex] = [e.target.value]

            return newArray;
          });
          return;
        }

        const choise = e;

        setanswers(prev => {
          const newArray = [...prev];
          const choises = newArray[quesIndex];

          if(!choises){
            newArray[quesIndex] = [choise];
          }
          if(choises){
            const isPresent = choises.find(ch => ch === choise);
            newArray[quesIndex] = isPresent === undefined? [...choises,choise]:choises.filter(ch => ch !== choise);
          }

          return newArray;
        });
        return;
      }
    }

    const handleBack = () => {
      if(quesIndex !== 0){
        setQuesIndex(prev => prev-1);
        return;
      }

      setData(prev => {
        return {
          ...prev,
          showQuestions: false,
          showForm: true
        }
      });
    }


    return (
      <>
        <div className="w-full h-full py-10 md:my-0 bg-primary-color overflow-auto">
            <div className="w-[700px] max-w-full rounded-md p-2 mx-auto">
              {
                quesIndex === 0 && <div className="mb-10">
                              <p className="text-secondry-color/80 mb-4">Welcome! Thank you for your interest in our exclusive ‘90 day XLRate Flow coaching program’.</p>
                              <p className="text-secondry-color/80">Before you join the waitlist, help us understand your needs so we can tailor a highly impactful coaching experience for you.</p>
                            </div>
              }
              <div className="mb-20">
                <ProgressBar totalSteps={questions.length} completedSteps={quesIndex} bgClass="bg-primary-button-color"/>
              </div>
              <div className="mb-3">
                <span onClick={handleBack}  className="cursor-pointer font-bold underline text-secondry-button-color">Back</span>
              </div>
              {/* <h2 className="text-secondry-color mb-2">{question.title}</h2> */}
              {
                question.instruction && <h3 className="text-secondry-color mb-6">{question.instruction}</h3>
              }
              <h1 className="text-secondry-color font-bold text-xl md:text-4xl">{question.question}</h1>
              {
                question.hint && <small className="text-secondry-color/50">{question.hint}</small>
              }


              <></>
              <div className="mt-8">
                      {
                          question.type === "rangeSlider" && <>
                                                              <div className="range h-14 w-full mt-14">
                                                                  {
                                                                     question.label &&  <div className="sliderValue relative w-[calc(100%-16px)]">
                                                                                          <span
                                                                                            className={`text-[12px] bg-primary-button-color flex justify-center absolute h-12 w-[100px] transform -translate-x-[43%] ${(answers[quesIndex] || (question.range.min+question.range.max)/2) ? 'scale-100' : 'scale-0'} font-medium top-[-40px] leading-[55px] rounded-md z-10 text-white transition-transform duration-300 ease-in-out`}
                                                                                            style={{ left: `${(((answers[quesIndex] || (question.range.min+question.range.max)/2) - question.range.min) / (question.range.max - question.range.min)) * 100}%`}}
                                                                                          >
                                                                                            {getLabel(answers[quesIndex] || (question.range.min+question.range.max)/2)}
                                                                                            <span className="absolute content-[''] h-12 w-12 bg-primary-button-color border-3 border-white z-[-1] left-1/2 transform -translate-x-1/2 rotate-45 rounded-bl-full rounded-tl-full rounded-tr-full shadow-md" />
                                                                                          </span>
                                                                                        </div>
                                                                  }
                                                                  <div className="field flex items-center justify-center h-full relative">
                                                                    {/* <div className="value absolute text-lg text-purple-700 font-semibold left-[-22px]">{range.min}</div> */}
                                                                    <input
                                                                      type="range"
                                                                      min={question.range.min}
                                                                      max={question.range.max}
                                                                      value={answers[quesIndex] || (question.range.min+question.range.max)/2}
                                                                      onChange={handleNext}
                                                                      // className="w-full h-[3px] bg-gray-300 rounded outline-none border-none"
                                                                      className="w-full accent-secondry-button-color appearance-none bg-secondry-color/10 rounded-full"
                                                                    />
                                                                    {/* <div className="value absolute text-lg text-purple-700 font-semibold right-[-43px]">{range.max}</div> */}
                                                                  </div>
                                                                </div>
                                                                <div className="flex justify-between font-bold text-secondry-color/40">
                                                                  <span>{question.startTag}</span>
                                                                  <span>{question.endTag}</span>
                                                                </div>
                                                            </>
                      }

                      {
                          question.type === "input" && <>
                                                                {
                                                                  question.inputNames.map((name,idx) => {
                                                                    const value = answers[quesIndex]? answers[quesIndex][name]:""
                                                                    return <div key={idx} className="relative z-0 w-full mb-5 group">
                                                                                <input value={value} onChange={handleNext} autoComplete="off" type="text" name={name} id={name} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-secondry-button-color peer" placeholder=" "/>
                                                                                <label htmlFor={name} className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-secondry-button-color peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">{name}</label>
                                                                            </div>
                                                                          })
                                                                }
                                                                <div className="flex justify-end">
                                                                  <PrimaryButton onClick={()=>handleNext(null)} style={{padding: "12px 0",marginTop: "40px",width: "130px"}}>
                                                                    Next
                                                                  </PrimaryButton>
                                                                </div>
                                                              </>
                      }
                      {
                          question.type === "multipleChoise" && <>
                                                                {
                                                                  question.name !== "challenge" && <>
                                                                  
                                                                        <div className="flex gap-4 flex-wrap">
                                                                          {
                                                                            question.choises.map((choise,idx) => 
                                                                              {
                                                                                const isSelected = answers[quesIndex] === undefined? false:answers[quesIndex].indexOf(choise) !== -1?true:false
                                                                                const index = answers[quesIndex] === undefined? -1:answers[quesIndex].indexOf(choise);

                                                                                return <button onClick={()=> handleNext(choise)} key={idx} className={`${isSelected? "bg-secondry-button-color text-white font-bold":"bg-secondry-color/40"} transition ease-in-out px-4 py-2 rounded-md flex items-center justify-start gap-2`}><span className={`${index === -1? "":"bg-primary-button-color inline-block w-[15px] rounded-full h-[15px] text-[10px]"}`}>{index === -1? "":index+1}</span><span className="text-start">{choise}</span></button>
                                                                              }
                                                                            )
                                                                          }
                                                                        </div>

                                                                  </>
                                                                }
                                                                {
                                                                  question.name === "challenge" && <Options options={challengesOptions} setOptions={setChallengesOptions}/>
                                                                }
                                                                {             
                                                                    question.other && <>
                                                                              <div className="relative z-0 w-full mb-5 group mt-10">
                                                                                  <input value={answers[quesIndex]? question.choises.indexOf(answers[quesIndex][0]) === -1? answers[quesIndex][0]:"":""} onChange={handleNext} autoComplete="off" type="text" name={"other"} id={"other"} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-secondry-button-color peer" placeholder=" "/>
                                                                                  <label htmlFor={"other"} className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-secondry-button-color peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Other</label>
                                                                              </div>
                                                                    </>
                                                                }

                                                                
                                                                
                                                                <div className="flex justify-end">
                                                                  <PrimaryButton onClick={()=>handleNext(null)} style={{padding: "12px 0",marginTop: "40px",width: "130px"}}>
                                                                    {quesIndex === questions.length-1? `${isSubmiting? "Saving..":"Finish"}`:"Next"}
                                                                  </PrimaryButton>
                                                                </div>
                                                              </>
                      }

              </div>
            </div>
        </div>
      </>
    )
  }

  function ThankYouMessage({setShowPopup,thankYouMessageData}){


    return <>
          <div className="w-full h-full flex justify-center items-center px-2">
                <div className="mx-auto max-w-md overflow-hidden rounded-3xl text-gray-700 shadow-md">
                      <div className="flex flex-col items-center bg-white p-10">
                          <p className="mb-4 text-center font-medium text-gray-500">Thank you.</p>
                          <p className="mb-4 text-center font-medium text-gray-500">Your position on the waitlist is {thankYouMessageData?.currentNumber}, out of {thankYouMessageData?.numberOfPeople} people eagerly awaiting their spot.</p>
                          <p className="mb-4 text-center font-medium text-gray-500">We just sent you a welcome email with further details. We look forward to welcoming you to the exclusive XLRate community.</p>
                          <p className="mb-4 text-center font-medium text-gray-500">Stay tuned for updates and prepare to transform your cognitive performance!</p>

                          <div className="w-full mt-10">
                              <SecondryButton onClick={()=>setShowPopup(false)} style={{padding: "12px 0"}}>Home</SecondryButton>
                          </div>
                      </div>
                </div>
          </div>
    </>
  }

  export function List({list}){

    if(!list){
      return null
    }

    return (<>
        <ul className="list-none mb-8">
          {
            list.map((item,idx) => (
              <li key={idx}>
                <div className="flex py-1">
                  {item.value}
                </div>
                {
                  item.subList && <>
                      <ul className="list-none pl-8">
                        {
                          item.subList.map((subItem,idx) => (
                            <li key={idx}>
                              {
                                typeof subItem === "string" && <div className="flex py-1 text-secondry-color font-semibold">{subItem}</div>
                              }
                              {
                                typeof subItem !== "string" && <>
                                  <div className="flex py-1 text-secondry-color font-semibold">
                                    {subItem.value}
                                  </div>
                                  <ul className="list-disc pl-8 mb-6">
                                      {
                                          subItem.subList?.map((subItem,idx) => <li className="py-1" key={idx}>{subItem}</li>)
                                      }
                                  </ul>
                                </>
                              }
                            </li>
                          ))
                        }
                      </ul>
                  </>
                }
              </li>
            ))
          }
        </ul>
    </>)
  }

  export function Footer({setShowPrivacyPolity,setShowCookiePolity}){


    return(<>
      <footer className="p-6 flex flex-col md:flex-row items-center text-secondry-color flex-wrap gap-2 justify-center">
            <p className="text-sm">© 2024 XLRate. All rights reserved.</p>
            <Link onClick={e=>{e.preventDefault();setShowPrivacyPolity(true)}} to={"/privacy-policy"} className="text-sm underline">Privacy Policy</Link>
            <Link onClick={e=>{e.preventDefault();setShowCookiePolity(true)}} to={"/cookie-policy"} className="text-sm underline">Cookies Policy</Link>
      </footer>
    </>)
  }

  export function Para({children,className}){


    return <p className={`text-secondry-color text-base md:text-lg ${className}`}>{children}</p>
  }

  function Options({options,setOptions}){



    const handleClick = (item) => {
      setOptions(prev => orderOptions(prev,item));
    }


    return (
        <div className="flex border border-accent-color p-2 rounded-lg flex-col gap-3 mt-14 max-h-[50vh] overflow-auto">
                {
                    options.map((item,idx) => (
                        <div key={idx}>
                            {
                                (options[idx-1] === undefined || options[idx-1].tag !== item.tag) && <h2 className="mb-3 font-bold text-secondry-color">{item.tag}</h2>
                            }
                            <div onClick={()=>handleClick(item)} className={`ml-10 ${item.isSelected? "bg-secondry-button-color text-primary-color":"bg-secondry-color/20"} p-3 rounded-lg flex justify-between items-center bg-primary-color cursor-pointer text-secondry-color`}>
                                <span className='pr-2'>{item.option}</span>
                                <span className={`w-4 h-4 text-[10px] flex justify-center items-center rounded ${item.isSelected? "text-[24px] text-primary-color":"border border-accent-color"}`}>{item.isSelected? item.order:""}</span>
                            </div>
                        </div>
                    ))
                }
        </div>
    )
  }

  export {Options};


  function orderOptions(arr,item){
    const newArray = arr.map(opt => {
        if(opt.option === item.option && opt.isSelected){
            return {...opt,isSelected: false,order: null};
        }
        if(opt.option === item.option && !opt.isSelected){
            return {...opt,isSelected: true,order: null};
        }
        return opt;
    });
    const max = newArray.reduce((acc,it) => {
        if(it.order && it.order > acc){
            return it.order;
        }
        return acc;
    },0);
    const result = newArray.map(opt => (opt.option === item.option && opt.isSelected)? {...opt,order: max+1}:opt);
    return result;
  }

  function Days90Expect(){
    const data = [
      {
        weeks: "Weeks 1-2",
        title: "Assessment and Baseline Setting",
        description: "",
        points: ["1-2-1 personal onboarding","Comprehensive cognitive assessment","Establishing your cognitive baseline","Initial personalised strategy session","Weekly one-to-one expert analysis and peak performance coaching sessions"],
        img: "https://res.cloudinary.com/dsnjr85ys/image/upload/v1717491216/1-2-1_Consulting_bn2ovc.png"
      },
      {
        weeks: "Weeks 3-4",
        title: "Foundation Building",
        description: "",
        points: ["Introduction to flow state principles","Initial cognitive optimisation exercises","Real-time feedback implementation","Weekly one-to-one expert analysis and peak performance coaching sessions"],
        img: "https://res.cloudinary.com/dsnjr85ys/image/upload/v1717497979/Brain_mapping_foundations_lvbx5i.png"
      },
      {
        weeks: "Weeks 5-8",
        title: "Deep Dive and Customisation",
        description: "",
        points: ["Advanced cognitive mapping and tailored strategies","Intensive coaching sessions focused on specific goals","Continuous real-time performance tracking","Weekly one-to-one expert analysis and peak performance coaching sessions"],
        img: "https://res.cloudinary.com/dsnjr85ys/image/upload/v1717490701/XLRate%20Expert%20Peak%20performance%20data%20%1Danalysis.png"
      },
      {
        weeks: "Weeks 9-12",
        title: "Mastery and Integration",
        description: "",
        points: ["Mastery of flow state techniques","Integration of cognitive optimisation into daily routines","Final performance assessment and long-term strategy development","Weekly one-to-one expert analysis and peak performance coaching sessions"],
        img: "https://res.cloudinary.com/dsnjr85ys/image/upload/v1717497250/XLRate%20solution.jpg"
      },
      {
        weeks: "12 Months",
        title: "Ongoing Support",
        description: "",
        points: ["12 month access to the exclusive XLRate Peak performance platform","Access to exclusive resources and community","Optional continued coaching for sustained high performance"],
        img: "https://res.cloudinary.com/dsnjr85ys/image/upload/v1717499386/XLRate%2012%20month%20support.png"
      }
    ]

    return (<>
            <Section>
              <div className="">
                <h1 className="mb-12 text-xl md:text-2xl text-center font-bold text-primary-button-color">What to Expect - The 90-Day Programme</h1>

                {
                  data.map((item,idx) => (
                    <div key={idx} className={`rounded-md shadow-[0_3px_10px_rgb(0,0,0,0.2)] overflow-hidden mb-5`}>
                      <Content pxOnly>
                        <div className={`flex py-[20px] md:py-0 flex-col ${idx % 2 !== 0? "lg:flex-row-reverse":"lg:flex-row"}`}>
                            <div className="md:m-6"><img src={item.img} alt="" className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] md:h-80 aspect-video rounded-md" /></div>
                          
                            <div className="flex flex-col justify-center flex-1 p-6">
                              <span className="text-xs uppercase text-secondry-color">{item.weeks}</span>
                              <h3 className="text-xl font-bold text-primary-button-color mb-6">{item.title}</h3>
                              <ul className="list-disc ml-6">
                                  {
                                    item.points.map((point,idx) => <li className="my-[4px] text-secondry-color" key={idx}><Para>{point}</Para></li>)
                                  }
                              </ul>
                            </div>
                        </div>
                      </Content>
                    </div>
                  ))
                }
              </div>
            </Section>
    </>)
  }

  function Days90Include(){
    const list = [
      {
        title: "Personalised Feedback and Coaching",
        description: "with tailored insights and coaching so you can transition and access your optimal peak performance states throughout the day.",
        img: ""
      },
      {
        title: "Real-Time Monitoring",
        description: "Continuous cognitive state monitoring with immediate feedback.",
        img: ""
      },
      {
        title: "Personal State-of-the-Art Wearable Device",
        description: "to facilitate continuous assessment of your flow state potential.",
        img: ""
      },
      {
        title: "Weekly reports",
        description: "Analysis of cognitive performance trends with metrics and personalised recommendations.",
        img: ""
      },
      {
        title: "Weekly One-to-One Consultations",
        description: "1-2-1 coaching sessions with the lead peak performance neuro-psychologists to review reports, insights and progress.",
        img: ""
      },
      {
        title: "Cognitive Blueprint Mapping",
        description: "Personalised peak performance blueprint derived from machine-learning techniques.",
        img: ""
      },
      {
        title: "Customised Strategic Coaching",
        description: "so your peak performance states are aligned with your schedule and professional growth goals.",
        img: ""
      },
      {
        title: "Flow State Optimisation",
        description: "Techniques to achieve and maintain peak mental states for enhanced productivity and creativity.",
        img: ""
      },
      {
        title: "Long-Term Cognitive Tracking",
        description: "Analysis of cognitive performance trends with long-term metrics and reports.",
        img: ""
      },
      {
        title: "Regular Performance Feedback",
        description: "Continuous feedback on strategies and actions for ongoing improvement.",
        img: ""
      },
      {
        title: "Customised Reminders and Notifications",
        description: "Notifications to prompt cognitive assessments and other tasks at optimal times.",
        img: ""
      },
      {
        title: "Exclusive Access to Experts",
        description: "Monthly live Q&A sessions with renowned performance coaches and experts.",
        img: ""
      },
      {
        title: "Post 90-Day Continuous Flow State Assessment",
        description: "Wearable device for ongoing cognitive state monitoring and realtime personalised strategies to enter peak performance states.",
        img: ""
      },
    ]

    return (<>
            <Section>
              <div className="container mx-auto  shadow-[0_3px_10px_rgb(0,0,0,0.2)]  rounded-md">
                <Content>
                  <h1 className="pt-8 md:pt-0 mb-10 text-xl md:text-2xl text-center font-bold text-primary-button-color">XLRate Flow Coaching 90-day programme includes</h1>
                  <div className="pb-8 md:pb-0 flex flex-wrap justify-between gap-2">
                      {
                        list.map((item,idx) => (
                          <div key={idx} className="w-full">
                              <div className="rounded flex md:px-6 py-2 h-full items-center">
                                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"
                                      className="text-secondry-button-color w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                      <path d="M22 4L12 14.01l-3-3"></path>
                                  </svg>
                                  <Para><span className="text-secondry-color"><span className="font-semibold">{item.title}</span> {item.description}</span></Para>
                              </div>
                          </div>
                        ))
                      }
                  </div>
                </Content>
              </div>
            </Section>
    </>)
  }

  function Section({children}){
    
    return(<>
        <section className="mx-auto w-full max-w-7xl md:px-6 lg:px-8 py-8">
          <div className="">
            {children}
          </div>
        </section>
    </>)
  }

  function Content({children,pxOnly}){

    return <div className={`${pxOnly? "px-[20px] md:px-[80px]":"p-[20px] md:p-[80px]"}`}>
      {children}
    </div>
  }

