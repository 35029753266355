import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { usePreviousLocationSetter } from "./usePreviousLocationSetter";


function AdminRoutes() {
  const loggedInUser  = useSelector((state) => state.loggedInUser);
  usePreviousLocationSetter();

  if (!loggedInUser) {
    return <Navigate to="/login" replace />;
  }

  if(loggedInUser.role !== "admin"){
    return <Navigate to="/login" replace />;
  }

  return <Outlet/>;
}

export default AdminRoutes;