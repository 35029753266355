import { useEffect, useState } from 'react';

const leadershipText = [
    {
        description:  <>You tend to control your team’s behaviour by either rewarding good behaviour and sanctioning team members in order to control bad behaviour. Basically you manage by command and control, and though you may have the best intentions and believe your leadership style is meritocratic, you inadvertently create an environment and culture with a high level of extrinsic pressure which ultimately acts as a constraint, limiting your team's ability to perform and develop.<br/><br/> Start experimenting with your Leadership ‘action challenges’ to improve your leadership style. Choose one to implement today and see if it will make a change to the team score over time?</>,
        style: "Transactional"
    },
    {
        description:  <>You don’t use intrinsic or extrinsic strategies to lead, and tend only to engage with your team if and when problems arise. You have good intentions, and believe in giving your teams lots of space to learn and grow. Yet to get your team to really perform you will need to be truly engaged, building them an environment and culture that is fun, reinforces the higher purpose of the activity, and creates opportunities for them to expand their personal potential.<br/><br/> Start experimenting with your Leadership ‘action challenges’ to improve your leadership style. Choose one to implement today and see if it will make a change to the team score over time?</>,
        style: "Laissez-faire"
    },
    {
        description:  <>You will try your best to manage your team by leveraging any intrinsic and extrinsic strategies available to you. The challenge with this approach is that the extrinsic strategies tend to cancel out the increases in performance and wellbeing of intrinsic strategies. This   tends to produce a team XL -and wellbeing and performance- broadly similar to the ‘Laissez-faire’ leadership style. <br/><br/> Start experimenting with your Leadership ‘action challenges’ to improve your leadership style. Choose one to implement today and see if it will make a change to the team score over time?</>,
        style: "All out"
    },
    {
        description:  <>You maximise your teams XL score and their wellbeing and performance by creating an environment and culture that amplifies their intrinsic drivers and minimises their extrinsic constraints. This leadership style produces an average XL score of between 26 to 40 and the top 5% of ‘Desire starter’ style leaders create a Teams with average XL score of 60 and above. <br/><br/> Start experimenting with your Leadership ‘action challenges’ to improve your leadership style. Choose one to implement today and see if it will make a change to the team score over time?</>,
        style: "Desire starter"
    }  
]



function useLeadershipText() {
  const [index, setIndex] = useState(0);  
  const [leaderShipStyle, setLeaderShipStyle] = useState("");  
  const [text, setText] = useState(""); 


  useEffect(() => {
    setText(leadershipText[index]?.description)
    setLeaderShipStyle(leadershipText[index]?.style)
  }, [index]);

  return [text,leaderShipStyle,setIndex];
}

export {useLeadershipText};