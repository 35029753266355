import {SET_MENU_OPEN,SET_BADGE,SET_IMPROVEMENT_STREAK,TOGGLE_SHOW_USER_NAMES,SET_PERSOANL_IMPROVEMENT_LEVEL,SET_PERSOANL_IMPROVEMENT_POINTS,TOGGLE_MODAL,SET_IS_UPDATE,SET_SURVEY_USER,RESET_SET_SURVEY_USER,SET_LOADING,RESET_LOADING, SET_SURVEY_NAME} from "../../constants";
import { SET_CHALLENGE,SET_PREVIOUS_ROUTE } from "../../constants/actions";

// surveyUser ///// survey user is a user which is doing survey but that is not loggedIn
const defaultState = {
  isModalOpen:false,
  isUpdate: false,
  surveyUser: null,
  loading:false,
  badge: "", 
  showUserNames: true,
  isMenuOpen: false,
  surveyName: "",
  PIPoints: 0, // personal improvement points
  PILevel: 1, // personal improvement Level
  IStreak: 0, // improvement Streak
  prevIStreak: 0,
  prevPIPoints: 0,
  showChallenge: false,
  previousRoute: "",
};

function globalStates(state = defaultState, action) {

    switch (action.type) {

      case TOGGLE_MODAL:
        return {
            ...state,
            isModalOpen: !state.isModalOpen,
        };

      case SET_IS_UPDATE:
        return {
            ...state,
            isUpdate: action.payload,
        };

      case SET_SURVEY_USER:
        return {
            ...state,
            surveyUser: action.payload,
        };

      case RESET_SET_SURVEY_USER:
        return {
            ...state,
            surveyUser: null,
        };
      
      case SET_LOADING:
        return {
            ...state,
            loading: true,
        };
      
      case RESET_LOADING:
        return {
            ...state,
            loading: false,
        };
      
      case SET_PERSOANL_IMPROVEMENT_POINTS:
        return {
            ...state,
            prevPIPoints: state.PIPoints,
            PIPoints: action.payload,
        };  

      case SET_PERSOANL_IMPROVEMENT_LEVEL:
        return {
            ...state,
            PILevel: action.payload,
        }; 

      case SET_IMPROVEMENT_STREAK:
        return {
            ...state,
            prevIStreak: state.IStreak,
            IStreak: action.payload
        };   

      case SET_BADGE:
        return {
            ...state,
            badge: action.payload,
        }; 

      case TOGGLE_SHOW_USER_NAMES:
        return {
            ...state,
            showUserNames: !state.showUserNames,
        };  

      case SET_SURVEY_NAME:
        return {
            ...state,
            surveyName: action.payload,
        }; 

      case SET_MENU_OPEN:
        return {
            ...state,
            isMenuOpen: action.payload,
        };

      case SET_CHALLENGE:
        return {
            ...state,
            showChallenge: action.payload,
        }; 

      case SET_PREVIOUS_ROUTE:
        return {
            ...state,
            previousRoute: action.payload,
        };  

      default:
        return state
    }
}


export default globalStates;