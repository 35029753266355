import { ActionChallengeModal, SecondryButton } from "../../../components";
import { useEffect, useState } from "react";
import {motion} from "framer-motion";
import {FACTOR_COLORS} from "../../../constants"
import { useSelector } from "react-redux";

function ActionChallenges({newTitle,currentActivityName,higestDiffrance,currentActionChallengesWithSteps,setSecVis,isACAnimation,afterComplete}){
    const duration = isACAnimation? .5:0;
    const initial = {opacity: 0};
    const animate = {opacity: 1};
    const toolTipText = `Discover the secret to your peak performance! Choose from our Action Challenges, specially designed to transform this critical factor and unlock your full potential. Your journey to success starts now. What are you waiting for?`;
    const [showACModel, setShowACModel] = useState(false);
    const [currentActionChallenge, setCurrentActionChallenge] = useState(null);
    const [insDec] = useIsIncreaseOrDicrease();
    const loggedInUser = useSelector(state => state.loggedInUser);
    

    useEffect(()=>{
        const time = setTimeout(() => {
            if(setSecVis){
                setSecVis(prev => {
                    return {
                        ...prev,
                        streamGraph: true
                    }
                })
            }
        }, isACAnimation? 4000:0);

        return () => {
            clearTimeout(time);
        }
    },[setSecVis,isACAnimation])

    const title = `Click the Personalised Coaching execise that resonates with you the most. Each has been specifical calculated to enhance your peak performance potential by transforming your mindset by ${insDec(higestDiffrance.factor)} your sense of:`
                          
    return (<>
        <div className="text-[11px] md:text-[14px]">
            <motion.h1 
            initial={initial}
            animate={animate}
            transition={{duration: duration,delay: isACAnimation? duration*0:0}}
            id='dashboard-step-7' 
            className='text-[16px] md:text-[18px] rounded font-bold relative z-30 mb-4 text-secondry-color'>
                {
                    !newTitle && <>
                    Click one Coaching action now & improve your XL by {insDec(higestDiffrance.factor)}
                    <span id="introSelector10" className="bg-secondry-color rounded-md p-[2px] ml-1">
                        <span style={{color: FACTOR_COLORS[`${higestDiffrance.factor}`.toUpperCase()]}} className="px-2 underline">{higestDiffrance.factor}</span> 
                    </span>
                    </>
                }
                {
                    newTitle && <p className="text-center">
                        {title}
                        <span id="introSelector10" className="bg-secondry-color rounded-md p-[2px] ml-1">
                            <span style={{color: FACTOR_COLORS[`${higestDiffrance.factor}`.toUpperCase()]}} className="px-2 underline">{higestDiffrance.factor}</span> 
                        </span>
                    </p>
                }
                 
            </motion.h1>
            {
                !newTitle && <p className="mb-8 text-secondry-color">{toolTipText}</p>
            }
            
            <div id='dashboard-step-9' className="flex flex-wrap gap-2">
                {
                    currentActionChallengesWithSteps && currentActionChallengesWithSteps.map((ch,i) => {
                        return <motion.div
                            initial={initial}
                            animate={animate}
                            transition={{duration: duration,delay:isACAnimation? ((duration*1)+i):0}}
                            key={i}
                            className="w-full"
                            id={i === 0? "introSelector11":""}
                            >
                                <SecondryButton
                                    onClick={(e)=>{
                                        setCurrentActionChallenge(ch);
                                        setShowACModel(true);
                                    }} 
                                    key={i} 
                                    style={{padding: "12px 10px"}}
                                >
                                    {ch.actionChallenge}
                                </SecondryButton>
                        </motion.div>
                    })
                }
            </div>
        </div>
        <ActionChallengeModal currentActivityName={currentActivityName} afterComplete={afterComplete} userId={loggedInUser._id} factor={higestDiffrance.factor} currentActionChallenge={currentActionChallenge} setShow={setShowACModel} show={showACModel}/>
    </>)
}

export {ActionChallenges};



function useIsIncreaseOrDicrease(){
    const  incDec = (factor) => {
        return ["Fun","Purpose","Growth"].indexOf(factor) === -1? "decreasing":"increasing"
    }
    
    return  [incDec];
}