import React,{useEffect,useState} from "react";
import { toast } from "react-toastify";
import {
  includeExcludeSurveys
} from "../../../Store/actions";
import {XL_FACTORS} from "../../../constants";
import {Tr,Td} from "../../../components";
import {mapper__100_100_to_0_100} from "../../../utils";

function CompletedSurvey({ survey, index ,group}) {

  const [isExcluded, setIsExcluded] = useState(false);
  const title = survey?.user?.activities.find(ac => ac.category === survey.surveyName)?.name;



  const onIsExcludedChange = async (id)=>{
    const res = await includeExcludeSurveys(id,{ isexcluded: !isExcluded})
    
    if(res.success){
      toast.success(res.message);
      setIsExcluded(!isExcluded);
      // setCompletedSurveys(prev => {
      //   return prev.map(survey => survey._id === id? {...survey,isexcluded: !isExcluded}:survey)
      // })
    }
  }

  const calcullateTotalDuration = (survey)=> {
    let totalTime = 0;

    for (const key in survey) {
      if(XL_FACTORS.indexOf(key) !== -1){
          const ans = survey[key];
          totalTime += (ans.endtime - ans.starttime); 
      }
    }
 
    return parseFloat((totalTime/1000)/60).toFixed(2) + " Mins";
  }

  const mapRange =  (value, inMin, inMax, outMin, outMax)=> {
    const ans = ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    return ans.toFixed(2)
  }

  useEffect(() => {
    if(survey.isexcluded){
      setIsExcluded(true)
    }
    else{
      setIsExcluded(false)
    }
  }, [setIsExcluded,survey.isexcluded]);

  

  return (

    <Tr>
      <Td className="pl-4">{index + 1}</Td>

      <Td>{survey?.user?.forename}{` `}{survey?.user?.surname}</Td>

      <Td className="text-muted">{survey?.user?.email}</Td>

      <Td className="text-muted">org</Td>

      <Td className="text-muted">dept</Td>
 
      <Td>
        <select
          className="form-control category-select"
          id="exampleFormControlSelect1"
        >
          <option>{group?.groupname}</option>
        </select>
      </Td>
    
      <Td className="text-muted">{survey.surveyName ? `${survey?.surveytitle?.title}`:survey?.surveytitle?.title}</Td>
      
      <Td className="text-muted">{survey.surveyName}</Td>

      <Td className="text-muted">{title}</Td>

      <Td className="text-muted">{new Date(survey?.createdAt).toLocaleString().split(",")[0]}</Td>

      <Td className="text-muted">{new Date(survey?.createdAt).toLocaleString().split(",")[1]}</Td>
 
      <Td className="text-muted">{calcullateTotalDuration(survey)}</Td>

      <Td className="text-muted">{survey.surveyPeriod?  `${new Date(new Date(survey?.createdAt).setMinutes(new Date(survey.createdAt).getMinutes()-survey.surveyPeriod)).toLocaleString().split(",")[1]}`:"NA"}</Td>

      {
        XL_FACTORS.map(XLFactor => (
          <Td key={XLFactor}>
            <span className="text-muted">
              {survey[XLFactor] ? mapRange(survey[XLFactor].rating.$numberDecimal,1,7,0,100) : "NA"}
            </span>
          </Td>
        ))
      }
   
      {/* <Td className="text-muted">{survey?.lxscore}</Td> */}

      <Td className="text-muted">{mapper__100_100_to_0_100(survey?.lxscore).toFixed(2)}</Td>

      <Td className="text-muted">{mapper__100_100_to_0_100(survey?.minLXScore).toFixed(2)}</Td>

      <Td className="text-muted">{mapper__100_100_to_0_100(survey?.maxLXScore).toFixed(2)}</Td>
      
      <Td className="text-muted flex flex-col">
        {survey?.event?.description}
        <div className="flex flex-wrap gap-1 mt-2">
            {
              survey?.event?.tags?.map((tag,idx) => (
                <span key={idx} className="bg-blue-600 text-white p-1 rounded-md">{tag}</span>
                ))
            }
        </div>
      </Td>

      <Td className="text-muted">{survey?.xlChangeFromLastScore}</Td>
      
      <Td className="text-muted"><input type='checkbox'onChange={()=>onIsExcludedChange(survey?._id)} checked={isExcluded}  className='exclude-btn' value={isExcluded}/></Td>

      <Td>
          <div className="flex flex-wrap gap-1 mt-2">
            {
              survey?.event?.feelings?.map((feeling,idx) => (
                <span key={idx} className="bg-blue-600 text-white p-1 rounded-md">{feeling}</span>
                ))
            }
          </div>
      </Td>
    </Tr>
  );
}

export default CompletedSurvey;
