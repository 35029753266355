import React,{useEffect,useState} from "react";
import {Tree } from "react-arborist";
// prev versions 
import {FaEye, FaPlus, FaUser} from "react-icons/fa";
import {BiEdit} from "react-icons/bi";
import {
  AiFillDelete,
} from "react-icons/ai";
import {CrossSVG, PrimaryButton, SecondryButton} from "../../../components";
import {
  updateGroupName,
  deleteGroup,
  addGroup,
  addOrRemoveGroupFromGroup, 
  addUserToGroup,
  removeUserFromGroup, 
  addScheduledTime,
  updateScheduledTime,
  allowStopAccessToDashboardOfUsers,
  getScheduledTimeById,
  getGroupById,
  updateBatchUsers,
  getUserById,
  updateUser
} from "../../../Store/actions";
import { useDispatch ,useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import {AddSurveyTiming as AddSurveyTimingForm} from "./index";
import {dateFormet} from "../../../utils";
import { toast } from "react-toastify";
import {Loading,ModalHeader} from "../../../components";
import moment from "moment";
import { MakeTreeFromArray, addIdAndName , getAllUsers, getUsersFromGroupWithoutTiming } from "./utils";
import Loader from "../../../components/Loading/Loading";
import { PopupContainer } from "../../../layout";
import { DashboardStatus, EmptyField, ManagerGroupsOrTeams, OnboardingEmailReceived, OnBoardingStatus, OneLineField, PersonalSurveys, UpdateUserModel } from "../../Users/Index";

  
function TreeComp({data,setScheduledTimes,scheduledTimes,surveyTitles}) {
      const [treeData, setTreeData] = useState(null);
      const [selectedUsers, setselectedUsers] = useState([]);
      const navigator = useNavigate();
      const dispatch = useDispatch();
      const [timingData, setTimingData] = useState({
        days: [],
        duration: {start: "",end: ""},
        frequency: "",
        time: {hours: "",minutes: ""},
        surveytitle: "",
        unRegularSurvey: "",
        _id: "",
      });
      const [_id, set_Id] = useState(null);
      const loggedInUser = useSelector(state => state.loggedInUser);
      const [isTimeUpdate, setIsTimeUpdate] = useState(false);
      const [showAddGroupModal, setShowAddGroupModal] = useState(false);
      const [groupName, setGroupName] = useState("");
      const [isUniversal, setIsUniversal] = useState(false);
      const [groupId, setGroupId] = useState(null);
      const [isUpdateGroup, setIsUpdateGroup] = useState(false);
      const [surveytitle] = useState("");
      // const [addOrUpdateSurveyTitle, setAddOrUpdateSurveyTitle] = useState(false);
      // const [surveyTitlesToUpdate,setSurveyTitlesToUpdate] = useState([]);
      const [selectedSurveyNames,setSelectedSurveyNames] = useState([]);
      const [displaySurveyTimingModal, setDisplaySurveyTimingModal] = useState(false);
      const [selectedGroup, setSelectedGroup] = useState(null);
      const [showUpdateBatchUsersPopup, setShowUpdateBatchUsersPopup] = useState(false);
      const [showViewUserPopup, setShowViewUserPopup] = useState(false);
      const [showUserNames, setShowUserNames] = useState(false);
 


      useEffect(() => {
            // dispatch(getAllScheduledTimes())
            const newData = JSON.parse(JSON.stringify(data));
            const temp = MakeTreeFromArray(newData,loggedInUser);
            setTreeData(temp);
      }, [data,dispatch,loggedInUser]);

      const handdleSelect = (nodes)=> {
        // console.log("aa");
        let temp = nodes[0]?.tree.selectedNodes;
  
        if(temp){
          setGroupId(temp[0].data._id);
          setSelectedGroup(temp[0]);
        }

        // set survey title for group 
        // const surveyTitlesForGroup = temp?temp[0].data.surveys:null;
  
        // if(surveyTitlesForGroup){
        //   setSurveyTitlesToUpdate(surveyTitlesForGroup);
        // }
       
    
        if(temp){
            let usersArr = [];

            temp.forEach(g => {
                if(!g.data.email){
                    const {userids,users,userSurveys} = getAllUsers(g);
                    setSelectedSurveyNames([...userSurveys][0]);
                    usersArr.push({
                        users: [...userids],
                        groupname: g.data.name,
                        createdAt: g.data.createdAt,
                        usersDetails: users,
                        userSurveys: [...userSurveys]
                    })
                }
            })
            setselectedUsers(usersArr);
        }


        if(nodes[0]?.data?._id){
          set_Id(nodes[0].data._id)
        }
      }

      const handdleViewDashboard = ()=> {
        let temp = undefined;

        if(surveytitle){
          temp = selectedUsers.map(su => {return{...su,surveyTitle: surveytitle,selectedSurveyNames}});
        }
        else{
          temp = selectedUsers;
        }

        if(temp[0]?.users?.length <= 1){
          toast.error("You can't see dashboard");
          return;
        }
        console.log(temp[0]);
        navigator("/dashboard/management",{ state: temp[0] })
      }

      const moveNode = (sourceId,sourceParentId,destnationId) => {
          let source = undefined;
          
          // remove source from its previous position  
          treeData.forEach(root => {
            function myVisit(d){
              if(d.id === sourceParentId){
                source = d.children.find(c => c.id === sourceId);
                d.children = d.children.filter(c => c.id !== sourceId);
              }
              if(d.children){
                d.children.forEach(child => myVisit(child));
              }
            }
            myVisit(root);
          })

          // add source to its new position   
          treeData.forEach(root => {
            function myVisit(d){
              if(d.id === destnationId){
                d.children.push(source);
              }
              if(d.children){
                d.children.forEach(child => myVisit(child));
              }
            }
            myVisit(root);
          })

          setTreeData([...treeData]);
      }

      const handleMove = async (e)=> {
        const isUser = e.dragNodes[0].data.email
        const sourceId = e.dragIds[0];
        const sourceParentId = e.dragNodes[0].parent?.id;
        const destinationId = e.parentId;
        const mongoSourceId = sourceId.split("---")[0];
        const mongoSourceParentId = sourceParentId.split("---")[0];
        const mongoDestinationId = destinationId.split("---")[0];

        if(destinationId === sourceParentId){
          return;
        }

        if(isUser && destinationId && mongoSourceId && mongoSourceParentId){
          const res = await removeUserFromGroup(mongoSourceId,mongoSourceParentId);
          const res1 = await addUserToGroup(mongoSourceId,mongoDestinationId);

          if(res.success && res1.success){
            moveNode(sourceId,sourceParentId,destinationId);
            toast.success("Moved Successfully!");
          }
          return;
        }

        if(destinationId && mongoDestinationId && mongoSourceId){
          const res = await addOrRemoveGroupFromGroup(mongoDestinationId,{groups: [mongoSourceId]});
         
          if(res.success){
            moveNode(sourceId,sourceParentId,destinationId);
            toast.success("Moved Successfully!");
          }
        }
      }

      const showAddOrUpdateGroupModal = (id,text) =>{
        if(text){
          setGroupName(text);
          setIsUpdateGroup(true);
        }
        else {
          setGroupName("");
          setIsUpdateGroup(false);
        }
        setGroupId(id);
        setShowAddGroupModal(true);
      }

      const handleAddGroupClick = (groupId) => {
        showAddOrUpdateGroupModal(groupId);
      }

      const handleEditGroupClick = (groupId,groupName) => {
        showAddOrUpdateGroupModal(groupId,groupName);
      }

      const handleAddSurveyTimingClick = (groupId) => {
          set_Id(groupId);
          setIsTimeUpdate(false);
          setTimingData(()=>{
            return {
              days: [],
              duration: {start: "",end: ""},
              frequency: "",
              time: {hours: ``,minutes: ``},
              surveytitle: "",
              _id: ""
            }
          });
          setDisplaySurveyTimingModal(true);
      }

      const handleUpdateSurveyTimingClick = async (groupId) => {
        set_Id(groupId);
        setIsTimeUpdate(true);
        const temp = await scheduledTimes.find(t => t.group === groupId);
        const res = await getScheduledTimeById(temp._id);
        const time = res.surveyPeriodFrequencyAndTime;
        if(time){
          setTimingData(()=>{
            return {
              days: time.days,
              duration: {start: dateFormet(time.duration.start),end: dateFormet(time.duration.end)},
              frequency: time.frequency,
              time: {hours: `${time.time.hours>9? time.time.hours:`0${time.time.hours}`}`,minutes: `${time.time.minutes>9? time.time.minutes:`0${time.time.minutes}`}`},
              surveytitle: time.surveytitle,
              unRegularSurvey: time.unRegularSurvey,
              _id: time._id
            }
          });
        }
        setDisplaySurveyTimingModal(true);
      }

      const handleEditBatchUsersClick = (groupId)=> {
        set_Id(groupId);
        setShowUpdateBatchUsersPopup(true);
      }

      const handleDashboardToggleClick = (e) => {
          const users = selectedUsers[0].users;
          let canAccessDashboard = false;
         
          if(e.target.checked){
            canAccessDashboard = true;
          }
          // console.log(canAccessDashboard);
          dispatch(allowStopAccessToDashboardOfUsers(users,canAccessDashboard));
      }

      // const handleChangeOfSurveyTitle = (e)=>{
      //   if(e.target.checked){
      //     setSurveyTitlesToUpdate(prev =>{
      //       return [...prev,e.target.value]
      //     })
      //   }
      //   else {
      //     setSurveyTitlesToUpdate(prev =>{
      //       return prev.filter(item => item !== e.target.value)
      //     })
      //   }
      // }

      // const handleAddSurveyTitleSubmit =()=>{
      //   // dispatch(addOrRemoveSurveyTitleFromGroup(_id,{surveys: surveyTitlesToUpdate}));
      // }

      const handleAddGroupSubmit = async ()=>{
        if(!isUpdateGroup){
          const res = await addGroup({parent: groupId,groupname: groupName,isUniversal: isUniversal});
          if(res.success){
            addGroupLocally(res);
            toast.success("Group Added Successfully!");
            setShowAddGroupModal(false);
          }
          else {
            console.log(res);
            toast.error("Error While Adding Group!");
          }
        }
        else {
          const res = await updateGroupName(groupId,{groupname: groupName});
        
          if(res.success){
            updateGroupNameLocally(res);
            toast.success("Group Updated Successfully!");
            setShowAddGroupModal(false);
          }
          else {
            toast.success("Error While Updating Group!");
          }
        }

        function addGroupLocally(res){
          const group = res.group;
          addIdAndName(group);
          treeData.forEach(root => {
            function visitTree(d){
              if(d._id === groupId){
                d.children.push(group);
              }
              if(d.children){
                d.children.forEach(child => visitTree(child));
              }
            }
            visitTree(root);
          });
          setTreeData([...treeData]);
        }

        function updateGroupNameLocally(res){
          const group = res.group;
    
          treeData.forEach(root => {
            function visitTree(d){
              if(d._id === groupId){
                d.groupname = group.groupname;
                d.name = group.groupname;
              }
              if(d.children){
                d.children.forEach(child => visitTree(child));
              }
            }
            visitTree(root);
          });
          setTreeData([...treeData]);
        }
      }

      const handdleAddSurveyTimingSubmit = async ()=> {
        const users = getUsersFromGroupWithoutTiming(groupId,selectedGroup.data,scheduledTimes)
    
        if(isTimeUpdate){
          let temp = {
            ...timingData,
            days: timingData.days.sort((a,b) => a-b),
            users
          }
          const res = await updateScheduledTime(timingData._id,temp);
          
          if(res.success){
            toast.success("Timing Updated Successfully!");
          }
          else {
            toast.success("Error While Updating Timing");
          }
        }
        else {
          let temp = {
            ...timingData,
            days: timingData.days.sort((a,b) => a-b),
            users
          }
          delete temp._id;

          const res = await addScheduledTime({...temp,group: _id});
          
          if(res.success){
            setScheduledTimes(prev => {
              return [...prev,res.surveyPeriodFrequencyAndTime]
            })
            toast.success("Timing Added Successfully!");
          }
          else {
            toast.success("Error While Adding Timing");
          }
        }
      }

      if(!treeData){
        return <Loader/>
      }
    
      return (
      <>
        <Header setShowUserNames={setShowUserNames} showUserNames={showUserNames} selectedUsers={selectedUsers} handdleViewDashboard={handdleViewDashboard}/>
  
        <Tree
            data={treeData}
            openByDefault={false}
            width={1000}
            indent={24}
            height={750}
            rowHeight={36}
            paddingTop={30}
            paddingBottom={10}
            padding={25 /* sets both */}
            onSelect={(nodes)=>{handdleSelect(nodes)}}
            onMove={(e)=>handleMove(e)}
            handleAddGroupClick={handleAddGroupClick}
            handleEditGroupClick={handleEditGroupClick}
            handleAddSurveyTimingClick={handleAddSurveyTimingClick}
            handleUpdateSurveyTimingClick={handleUpdateSurveyTimingClick}
            handleEditBatchUsersClick={handleEditBatchUsersClick}
            handleDashboardToggleClick={handleDashboardToggleClick}
            setShowViewUserPopup={setShowViewUserPopup}
            loggedInUser={loggedInUser}
            treeData={treeData}
            setTreeData={setTreeData}
            scheduledTimes={scheduledTimes}
            showUserNames={showUserNames}
          >
            {Node}
        </Tree>

        {/* add timing modal  */}
        <AddSurveyTimingForm onSubmit={handdleAddSurveyTimingSubmit} addName={isTimeUpdate? "Update Timing":"Add Timing"} isOpen={displaySurveyTimingModal} setIsOpen={setDisplaySurveyTimingModal} setTimingData={setTimingData} timingData={timingData}/>
        

        {/* group modal  */}
        <GroupModal addName={isUpdateGroup?"Update Group":"Add Group"} isOpen={showAddGroupModal} onSubmit={handleAddGroupSubmit} setIsOpen={setShowAddGroupModal} groupName={groupName} setGroupName={setGroupName} setIsUniversal={setIsUniversal} isUniversal={isUniversal}/>


        {/* update batch users  */}
        {
          showUpdateBatchUsersPopup && <UpdateBatchUsers setVisilivity={setShowUpdateBatchUsersPopup} groupId={_id} surveyTitles={surveyTitles}/>
        }


        {/* view user  */}
        {
          showViewUserPopup && <ViewUserModal setVisilivity={setShowViewUserPopup} selectedGroup={selectedGroup}/>
        }
      </>
      )
}

export default TreeComp;


function Node({ node,style, dragHandle,tree }) {
    const {
      handleAddGroupClick,
      handleEditGroupClick,
      handleAddSurveyTimingClick,
      handleUpdateSurveyTimingClick,
      handleEditBatchUsersClick,
      handleDashboardToggleClick,
      treeData,
      setTreeData,
      scheduledTimes,
      showUserNames,
      setShowViewUserPopup,
      loggedInUser
    } = tree.props;
    const isEdit = scheduledTimes? scheduledTimes?.find(t => t.group === node.data._id):null;
    const {users} = getAllUsers(node);
    const trmp = [...users].every(el => el.canAccessDashboard); 
    const canAccessDashboard = trmp
    // const [canAccessDashboard, setCanAccessDashboard] = useState(trmp);

    const onDeleteSubmit = async (node)=> {
        if(!window.confirm("Are you sure?")){
          return;
        }

        const groupId = node.data._id;
        const res = await deleteGroup(groupId);
        
        if(res.success){
          treeData.forEach(root => {
            function visitTree(d){
              const isChildOf = d.children?.find(child => child._id === groupId);
              if(isChildOf){
                const remaningChild = d.children.filter(child => child._id !== groupId)
                d.children = remaningChild;
              }
              if(d.children){
                d.children.forEach(child => visitTree(child));
              }
            }
            visitTree(root);
          });
          setTreeData([...treeData]);
          toast.success("Group Deleted Successfully!");
        }
        else {
          toast.success("Error While Deleting Group!");
        }
        
    }

    const handleDashboardViewChange = (e) => {
      // setCanAccessDashboard(p=>!p)
      handleDashboardToggleClick(e);
    }

    function OnboardingStatus({status}){

      if(!status){
        return ""
      }

      const steps = ["hasCompletedFirstLogin","isIntroSeen","isPreSurveyDone","isWelcomeSeen","isPersonalImprovementGoalsSeen","isPersonalImprovementObstaclesSeen","isFinalProjectionSeen","isEnterActivityTitleSeen","hasCompletedFirstSurvey","hasSeenDashboardFirstTime"];

      if(!node.data.hasCreatedNewPassword){
        return <>
        <span title="Onboarding" >On</span>
        <span className="bg-red-600 w-[10px] h-[10px] rounded-full"/>
        <span title="Pre Survey" >Pre</span>
        <span className={`${node.data.isPreSurveyDone? "bg-green-600":"bg-yellow-400"} w-[10px] h-[10px] rounded-full`}/>
        <span title="Post Survey" >Post</span>
        <span className={`${node.data.isPostSurveyDone? "bg-green-600":"bg-yellow-400"} w-[10px] h-[10px] rounded-full`}/>
        </>
      }

      const isFinished = steps.every(step => node.data[step] === true);

      if(isFinished){
        return <>
        <span title="Onboarding" >On</span>
        <span className="bg-green-600 w-[10px] h-[10px] rounded-full"/>
        <span title="Pre Survey" >Pre</span>
        <span className={`${node.data.isPreSurveyDone? "bg-green-600":"bg-yellow-400"} w-[10px] h-[10px] rounded-full`}/>
        <span title="Post Survey" >Post</span>
        <span className={`${node.data.isPostSurveyDone? "bg-green-600":"bg-yellow-400"} w-[10px] h-[10px] rounded-full`}/>
        </>
      }

      return <>
      <span title="Onboarding" >On</span>
      <span className="bg-yellow-400 w-[10px] h-[10px] rounded-full"/>
      <span title="Pre Survey" >Pre</span>
      <span className={`${node.data.isPreSurveyDone? "bg-green-600":"bg-yellow-400"} w-[10px] h-[10px] rounded-full`}/>
      <span title="Post Survey" >Post</span>
      <span className={`${node.data.isPostSurveyDone? "bg-green-600":"bg-yellow-400"} w-[10px] h-[10px] rounded-full`}/>
      </>
    }


  
    

    return (
        <>
        <div  ref={dragHandle} className="ml-4 tree flex items-center" style={{...style,backgroundColor: (node.isSelected || node.willReceiveDrop)?"#ccc":"transparent"}}>
          <div onClick={() => node.toggle()} className="left flex items-center gap-[5px] flex-1">
            <span>{node?.data?.email ? <FaUser size={12}/> : "🗀"}</span>
            <span className="name overflow-x-hidden">{!node?.data?.email ? node.data.name:showUserNames? node.data.name:"user"}</span>
            <OnboardingStatus status={node?.data?.email? true:false}/>
          </div>
          
          {
            node.isSelected && <>
                <div className={`right flex justify-end items-center gap-[5px] pr-[5px]`}>
                    <FaPlus
                    title="Add Child Group"
                    className="cursor-pointer"
                    onClick={()=> handleAddGroupClick(node.data._id)}
                    />

                    {
                      isEdit? (
                        <BiEdit 
                          title="Edit Survey Timing"
                          className="cursor-pointer"
                          onClick={()=>handleUpdateSurveyTimingClick(node.data._id)}
                        />
                      ):(
                        <FaPlus
                          title="Add Survey Timing"
                          className="cursor-pointer"
                          onClick={()=>handleAddSurveyTimingClick(node.data._id)}
                        />
                      )
                    }
                    
                    <AiFillDelete 
                    title="Delete" 
                    onClick={()=>{onDeleteSubmit(node)}}/>


                    <BiEdit 
                      title="Edit Group"
                      className="cursor-pointer"
                      onClick={()=>handleEditGroupClick(node.data._id,node.data.groupname)}
                    />

                    <BiEdit 
                    title="Edit Batch Users"
                    className="cursor-pointer"
                    onClick={()=>handleEditBatchUsersClick(node.data._id)}
                    />

                    {
                      (node?.data?.email && loggedInUser?.role === "admin") && <FaEye onClick={()=> setShowViewUserPopup(true)} title="View User" className="cursor-pointer" />
                    }

                    <div className="flex items-center">
                      <input title={canAccessDashboard? "can access dashboard":"can't access dashboard"} value="" onChange={handleDashboardViewChange} checked={canAccessDashboard} type="checkbox" data-id={node.data._id} className="dashboardAccess"/>
                    </div>
                </div> 
            </>
          }
        </div>

       
      </>
    );
}

function UpdateBatchUsers({setVisilivity,groupId,surveyTitles}){
    const [userIds, setUserIds] = useState(null);
    const [error, setError] = useState(null);
    const [data, setData] = useState({postSurveyId: "",preSurveyId: "",postSurveyDate: "",onbordingEmailTime: ""});
    const [groupName, setGroupName] = useState(null);
    

    const handleOnChange = (e) => {
      setData(prev => {
        return {
          ...prev,
          [e.target.name]: e.target.value
        }
      })
    }

    const handleSubmit = async () => {
      let isAllExists = true

      Object.keys(data).forEach(key => {
        if(!data[key]){
          isAllExists = false;
        }
      })

      if(!isAllExists){
        toast.error("All fields are required");
        return;
      }

      const d = {
        data: {
          ...data,
          onbordingEmailTime: new Date(data.onbordingEmailTime),
          postSurveyDate: new Date(data.postSurveyDate)
        },
        userIds
      }

      const res = await updateBatchUsers(d);

      if(res.success){
        toast.success("Updated Successfully");
        setVisilivity(false);
      }
      else{
        toast.error(res.message);
      }
    }

    useEffect(() => {
      (async ()=> {
        const res = await getGroupById(groupId);
        if(res.success){
          const user = res.group.users.find(u => u !== null);
          
          if(!user){
            setError("No User Found");
            return;
          }
          if(user.requiredActivities?.length === 0){
            setError("Users in this group are not batch users");
            return;
          }
          setGroupName(res.group.groupname);
          setUserIds(res.group.users.map(u => u?._id)); 
          setData({
            postSurveyId: user.postSurveyId,
            preSurveyId: user.preSurveyId,
            postSurveyDate: moment(user.postSurveyDate).format("YYYY-MM-DDTHH:mm:SS"),
            onbordingEmailTime: moment(user.onbordingEmailTime).format("YYYY-MM-DDTHH:mm:SS")
          })
        }
        else {
          toast.error("Something went wrong!");
        }
      })()

      return () => {
        setError(null);
      }
    }, [groupId]);


    if(error || !surveyTitles){
      return <PopupContainer>
                <ModalHeader handleCrosClick={()=>{setVisilivity(false)}}/>
                <p className="mt-4 text-red-800 bg-red-100">{error}</p>
              </PopupContainer>
    }

    if(!userIds){
      return <PopupContainer><Loading/></PopupContainer>
    }



    return (
      <>
        <PopupContainer>
           <ModalHeader leftText={groupName} handleCrosClick={()=>{setVisilivity(false)}}/>
           <div className="pt-4  max-w-full">
              <div className="w-full">
                  <label htmlFor="postSurveyId">Post Survey</label>
                  <select value={data.postSurveyId} onChange={handleOnChange} className="block w-full p-2 rounded-md mt-1" name="postSurveyId" id="postSurveyId">
                      {
                        surveyTitles.map(st => <option key={st._id} value={st._id}>{st.title}</option>)
                      }
                  </select>
              </div>
              <div className="w-full mt-4">
                  <label htmlFor="preSurveyId">Pre Survey</label>
                  <select value={data.preSurveyId} onChange={handleOnChange} className="block w-full p-2 rounded-md mt-2" name="preSurveyId" id="preSurveyId">
                      {
                        surveyTitles.map(st => <option key={st._id} value={st._id}>{st.title}</option>)
                      }
                  </select>
              </div>
              <div className="w-full mt-4">
                  <label htmlFor="postSurveyDate">Post Survey Date</label>
                  <input value={data.postSurveyDate} onChange={handleOnChange} type="datetime-local" id="postSurveyDate" name="postSurveyDate" className="block w-full p-2 rounded-md mt-2"/>
              </div>
              <div className="w-full mt-4">
                  <label htmlFor="onbordingEmailTime">Onboarding Email Time</label>
                  <input value={data.onbordingEmailTime} onChange={handleOnChange} type="datetime-local" id="onbordingEmailTime" name="onbordingEmailTime" className="block w-full p-2 rounded-md mt-2"/>
              </div>
              <PrimaryButton style={{marginTop: "20px"}} onClick={handleSubmit}>Done</PrimaryButton>
           </div>
        </PopupContainer>
      </>
    )
}

function Header({setShowUserNames,showUserNames,selectedUsers,handdleViewDashboard}) {

  return <>
    <div className="flex items-center justify-between gap-4 border-b border-secondry-color/10 py-5">
        <div className="flex items-center gap-4 pl-2">
              <FaPlus 
              onClick={()=>{}} 
              title="Add Root Group" />

              <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" value="" className="sr-only peer" onChange={()=>setShowUserNames(p => !p)} checked={showUserNames}/>
                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{showUserNames? "Hide User Names":"Show User Names"}</span>
              </label>
        </div>
        <div className="">
              <ViewDashboardUI selectedUsers={selectedUsers} handdleViewDashboard={handdleViewDashboard}/>
        </div>
    </div>
  </>
}

function ViewDashboardUI({handdleViewDashboard,selectedUsers}){

  if(selectedUsers.length === 0){
    return null;
  }

  return <>
      <div className={"flex gap-2 items-center pr-2"}>
        <p className="mb-0">{selectedUsers[0].groupname}</p>
        <p className="mb-0">{selectedUsers[0]?.userSurveys[0]}</p>
        <PrimaryButton style={{width: "150px",padding: "12px 0"}} onClick={handdleViewDashboard}>View dashboard</PrimaryButton>
      </div>
  </>
}

function GroupModal({addName,isOpen,onSubmit,setIsOpen,groupName,setGroupName,setIsUniversal,isUniversal}){


  if(!isOpen){
    return null;
  }

  return (
        <PopupContainer>
          <div className="mb-14">
             <input 
                type="text"
                value={groupName} 
                onChange={(e)=>{setGroupName(e.target.value)}}
                className="w-full border rounded-md p-2"
                placeholder="group name.."
              />
              <label htmlFor="universal" className="mt-2 flex items-center">
                Is Universal: 
                <input type="checkbox" id="universal" className="ml-2" onChange={e=> setIsUniversal(prev => !prev)} checked={isUniversal}/>
              </label>
          </div>

          <div className="flex gap-2">
            <PrimaryButton onClick={()=> setIsOpen(false)} style={{padding: "12px 0"}}>Cancel</PrimaryButton>
            <SecondryButton onClick={onSubmit} style={{padding: "12px 0"}}>{addName}</SecondryButton>
          </div>
        </PopupContainer>
  )

}
 

function ViewUserModal({setVisilivity,selectedGroup}){
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState();

  
  const handleUpdate = () => {
    setFormData(prev => {
      return {
      dob: user.dob ,
      profession: user.profession || "",
      title: user.title || "",
      middleNames: user.middleNames || "",
      surname: user.surname || "",
      forename: user.forename || "",
      email: user.email,
      userName: user.userName || "",
      gender: user.gender || "",
      organizationName: user.organizationName || "",
      department: user.department || "",
      team: user.team || "",
      role: user.role,
      rank: user.rank || "",
      className: user.className || "",
      level: user.level || "",
      studentNumber: user.studentNumber || "",
      mobilePhoneNumber: user.mobilePhoneNumber || "",
      landlineNumber: user.landlineNumber || "",
      address: user.address || "",
      unRegularSurveys: user.unRegularSurveys || "",
      activities: user.activities || "",
      managementGroups: user.managementGroups || [],
    }
    });
    setIsEditing(true);
  }

  const handdleUpdateSubmit = async ()=> {
    const res = await updateUser(selectedGroup.data._id,formData);

    if(res.success){
      toast.success(res.message);
      setUser(res.user);
      setIsEditing(false);
    }
    else {
      toast.error(res.message);
    }
  }

  useEffect(() => {
    (async () => {
      setisLoading(true);
      const res = await getUserById(selectedGroup?.data?._id);
      setisLoading(false);
      
      if(res.success){
        setUser(res.user);
      }
      else {
        setError(res.message);
      }
    })()
  }, [selectedGroup?.data?._id]);

  if(isLoading){
    return <PopupContainer>
              <div className="flex justify-end">
                <div onClick={()=> {setVisilivity(false)}} className="w-8 h-8 border border-secondry-color/20 p-1 rounded-md "><CrossSVG/></div>
              </div>
              <Loading/>
           </PopupContainer>
  }

  if(error){
    return <PopupContainer>
              <div className="flex justify-end">
                <div onClick={()=> {setVisilivity(false)}} className="w-8 h-8 border border-secondry-color/20 p-1 rounded-md "><CrossSVG/></div>
              </div>
              <div className="min-h-[200px] flex justify-center items-center">
                <span className="text-red-800 bg-red-50 px-2 rounded-md"><span className="font-bold text-red-600">Error:</span> {error}</span>
              </div>
           </PopupContainer>
  }

  if(!user){
    return <PopupContainer>
                <div className="flex justify-end">
                  <div onClick={()=> {setVisilivity(false)}} className="w-8 h-8 border border-secondry-color/20 p-1 rounded-md "><CrossSVG/></div>
                </div>
                <div className="min-h-[200px] flex justify-center items-center">
                  <span className="text-yellow-600 bg-yellow-50 px-2 rounded-md">User Not Found</span>
                </div>
            </PopupContainer>
  }

  return (
    <div className="position fixed top-[64px] w-full left-0 min-h-[calc(100vh-64px)] px-10 py-10 bg-primary-color">
      <div className="flex justify-end pb-4 mb-4 border-b border-secondry-color">
        <div onClick={()=> {setVisilivity(false)}} className="w-8 h-8 border border-secondry-color/20 p-1 rounded-md "><CrossSVG/></div>
      </div>
      
      {
        isEditing && <UpdateUserModel setIsOpen={setIsEditing} formData={formData} setFormData={setFormData} handdleUpdateSubmit={handdleUpdateSubmit}/>
      }
      {
        !isEditing && <>
          <div className="flex flex-col gap-3">
          {
              [user].map((user,idx) => (
                <div key={idx} className="pb-4 flex flex-wrap border-b">
                    {
                        ["Dob","Profession","Title","MiddleNames","Surname","Forename","Email","User Name","Gender","Organisation","Department","Team","Role","Rank","Class Name","Level","Student Number","Mobile Phone Number","Landline Number","Address","No. Survey User","Personal Surveys","Manager Groups/Teams","Received Onboarding Email","On Boarding Status","Dashboard Tour Status","Actions"].map((item,idx1) => (
                          <div key={idx1} className="p-2 px-3 bg-secondry-color/10 mb-1">
                              <p className="mb-1 font-bold text-sm text-secondry-color whitespace-nowrap">{item}</p>
                              {
                                item === "Profession" && <OneLineField user={user} fieldName="profession"/>
                              }
                              {
                                item === "Dob" && <OneLineField user={user} fieldName="dob"/>
                              }
                              {
                                item === "Title" && <OneLineField user={user} fieldName="title"/>
                              }
                              {
                                item === "MiddleNames" && <OneLineField user={user} fieldName="middleNames"/>
                              }
                              {
                                item === "Surname" && <OneLineField user={user} fieldName="surname"/>
                              }
                              {
                                item === "Forename" && <OneLineField user={user} fieldName="forename"/>
                              }
                              {
                                item === "Email" && <OneLineField user={user} fieldName="email"/>
                              }
                              {
                                item === "User Name" && <OneLineField user={user} fieldName="userName"/>
                              }
                              {
                                item === "Gender" && <OneLineField user={user} fieldName="gender"/>
                              }
                              {
                                item === "Organisation" && <OneLineField user={user} fieldName="organizationName"/>
                              }
                              {
                                item === "Department" && <OneLineField user={user} fieldName="department"/>
                              }
                              {
                                item === "Team" && <OneLineField user={user} fieldName="team"/>
                              }
                              {
                                item === "Role" && <OneLineField user={user} fieldName="role"/>
                              }
                              {
                                item === "Rank" && <OneLineField user={user} fieldName="rank"/>
                              }
                              {
                                item === "Class Name" && <OneLineField user={user} fieldName="className"/>
                              }
                              {
                                item === "Level" && <OneLineField user={user} fieldName="level"/>
                              }
                              {
                                item === "Student Number" && <OneLineField user={user} fieldName="studentNumber"/>
                              }
                              {
                                item === "Mobile Phone Number" && <OneLineField user={user} fieldName="mobilePhoneNumber"/>
                              }
                              {
                                item === "Landline Number" && <OneLineField user={user} fieldName="landlineNumber"/>
                              }
                              {
                                item === "Address" && <OneLineField user={user} fieldName="address"/>
                              }
                              {
                                item === "No. Survey User" && (user?.activities?.length || <EmptyField/>)
                              }
                              {
                                item === "Personal Surveys" && <PersonalSurveys user={user}/>
                              }
                              {
                                item === "Manager Groups/Teams" && <ManagerGroupsOrTeams user={user}/>
                              }
                              {
                                item === "On Boarding Status" && <OnBoardingStatus user={user}/>
                              }
                              {
                                item === "Received Onboarding Email" && <OnboardingEmailReceived user={user}/>
                              }
                              {
                                item === "Dashboard Tour Status" && <DashboardStatus user={user}/>
                              }
                              {
                                item === "Actions" && <div><SecondryButton onClick={handleUpdate}>Edit User</SecondryButton></div>
                              }
                          </div>
                        ))
                    }
                </div>
              )) 
          }
          </div>
        </>
      }
    </div>
  )
}

  


 





