function visitToTreeData(parent, visitFn, childrenFn) {
    if (!parent) return;
  
    visitFn(parent);
  
    var children = childrenFn(parent);
    if (children) {
      var count = children.length;
      for (var i = 0; i < count; i++) {
        visitToTreeData(children[i], visitFn, childrenFn);
      }
    }
}


export {visitToTreeData};