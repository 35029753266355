import { 
    DOMAIN_NAME
 } from "../../constants";
import {fetchRequest} from "../../utils";
import {toast} from "react-toastify";




export const getSurveyTitleById = async (surveyId) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/surveytitle/${surveyId}`,"GET",{});

    return res;
}

export const getUniversalSurveyTitle = async () => {

    const res = await fetchRequest(`${DOMAIN_NAME}/surveytitle/universal/surveytitle`,"GET",{});

    return res;
}

export const createSurveyTitle = async (data) =>{
    
    const res = await fetchRequest(`${DOMAIN_NAME}/surveytitle`,"POST",data);
   
    return res;
}

export const updateSurveyTitle = async (id,data) =>{
    
    const res = await fetchRequest(`${DOMAIN_NAME}/surveytitle/${id}`,"PUT",data);
   
    return res;
}

export const deleteSurveyTitle = async (id) =>{
    
    const res = await fetchRequest(`${DOMAIN_NAME}/surveytitle/${id}`,"DELETE",{});
   
    return res;
}

export const getSurveyTitleByUserIdAndSurveyId = async (userId,surveyId,isPreviousQuestions) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/surveytitle/findbyids/${userId}/${surveyId}?isPreviousQuestions=${isPreviousQuestions}`,"GET",{});

    return res;
}

export const getAllSurveyTitles = async () => {

    const res = await fetchRequest(`${DOMAIN_NAME}/surveytitle/all`,"GET",{});

    return res;
}

export const getAllSurveyTitleNames = async () => {

    const res = await fetchRequest(`${DOMAIN_NAME}/surveytitle/all/names`,"GET",{});

    return res;
}

export const addQuestions = async (surveyTitleId,questions) => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/surveytitle/addquestions/${surveyTitleId}`,"POST",{questions});
        return res;
       
    } catch (error) {
        toast.error(error.message);
    }
}

export const removeQuestion = async (surveyTitleId,questionId) => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/surveytitle/removequestion/${surveyTitleId}`,"POST",{questionId});
        return res;
    } catch (error) {
        toast.error(error.message);
    }
}

export const addActionChallenges = async (surveyTitleId,actionChallenges) => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/surveytitle/add-action-challenges/${surveyTitleId}`,"POST",{actionChallenges});
        return res;
       
    } catch (error) {
        toast.error(error.message);
    }
}

export const removeActionChallenge = async (surveyTitleId,actionChallengeId) => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/surveytitle/remove-action-challenges/${surveyTitleId}`,"POST",{actionChallengeId});
        return res;
    } catch (error) {
        toast.error(error.message);
    }
}

export const removeOneActionChallenge = async (surveyTitleId,actionChallengeId,challenge) => {

    try {
        // console.log(surveyTitleId,actionChallengeId,challenge)
        const res = await fetchRequest(`${DOMAIN_NAME}/surveytitle/remove-action-challenge-one/${surveyTitleId}`,"POST",{actionChallengeId,challenge});
        return res;
    } catch (error) {
        toast.error(error.message);
    }
}




