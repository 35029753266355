import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {Main} from "../layout";
import {PrivacyPolicyPopup, PrimaryButton, SecondryButton, CookiePolicyPopup,ProgressBar} from "../components";
import { createWaitingList, getNumberOfPeopleAreWaiting } from "../Store/actions";
import { Footer, Para } from "./LandingPageA";
import {cognitiveBlueprintMapping,realTimeFeedback,customisedStrategicCoaching,flowStateOptimisation} from "../assets";




  function LandingPageB(){
      const [showPopup, setShowPopup] = useState(false);
      const [showPrivacyPolity, setShowPrivacyPolity] = useState(false);
      const [showCookiePolity, setShowCookiePolity] = useState(false);
      

      if(showPrivacyPolity){
        return <PrivacyPolicyPopup setIsOpen={setShowPrivacyPolity}/>
      }

      if(showCookiePolity){
        return <CookiePolicyPopup setIsOpen={setShowCookiePolity}/>
      }

      return (
        <>
          <Main style={{marginTop: "0px !important"}}>
            {/* Main Content Section */}
            <main className="max-w-7xl mx-auto py-12">
              <Header setShowPopup={setShowPopup}/>

              <Feature/>

              <Sections/>

              <CTA setShowPopup={setShowPopup}/>
            </main>

            {/* Footer Section */}
            <Footer setShowPrivacyPolity={setShowPrivacyPolity} setShowCookiePolity={setShowCookiePolity}/>
          </Main>
          {
            showPopup && <Popup setShowPopup={setShowPopup}/>
          }
        </>
      )
  }

  export {LandingPageB};

  const Header = ({setShowPopup}) => {
      return (
        <div className="flex flex-col items-center justify-center px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:pt-32 md:px-0">
          
          <img
            src="https://res.cloudinary.com/dsnjr85ys/image/upload/v1717406588/XLRate%20platform%20in%20a%20Luxury%20office.png"
            className="w-full max-w-screen-sm mx-auto rounded shadow-2xl md:w-auto lg:max-w-screen-md"
            alt=""
          />

          <div className="flex flex-col md:items-center max-w-2xl md:px-8 mt-14">
            <div className="max-w-xl md:mx-auto sm:text-center lg:max-w-2xl">
              <h3 className="max-w-lg mb-[20px] text-[1.6em] md:text-[2em] font-bold leading-[1.2em] text-primary-button-color md:mx-auto">
                  Your Journey to Unmatched Cognitive Performance Starts Here
              </h3>
              <p className="text-[1.2em] md:text-[1.5em] font-bold  text-secondry-color mb-[20px]">
                  Unleash Your Potential with the XLRate Flow Coaching 90-Day Programme
              </p>
            </div>
            <form className="flex md:justify-center mb-10">
              <SecondryButton
                onClick={(e)=>{e.preventDefault();setShowPopup(true)}}
                style={{padding: "10px 20px",fontWeight: "bold"}}
              >
                Join the waitlist
              </SecondryButton>
            </form>
          </div>
          
        </div>
      );
  };

  function Feature(){
  
    const items = [
      {
        title: "Cognitive Blueprint Mapping",
        description: "Tailor your mental performance optimally for critical tasks using our sophisticated machine-learning techniques.",
        icon: cognitiveBlueprintMapping,
      },
      {
        title: "Real-Time Feedback",
        description: "Continuously monitor your cognitive performance like a fitness tracker monitors physical health. ",
        icon: realTimeFeedback,
      },
      {
        title: "Customised Strategic Coaching",
        description: "Receive coaching that aligns with your schedule and professional growth, ensuring constant development.",
        icon: customisedStrategicCoaching,
      },
      {
        title: "Flow State Optimisation",
        description: "Learn to achieve and maintain peak mental states to boost operational effectiveness and strategic thinking.",
        icon: flowStateOptimisation,
      },
    ]

    return (<>
        <Section className="py-10 md:py-24">
            <div className="mb-10 lg:mb-16">
                        <h2 className="text-xl md:text-3xl md:text-center font-bold text-primary-button-color">
                            Experience the Next Level of Performance and Personal Growth
                        </h2>
            </div>
            <div className="flex justify-center items-streach  gap-x-5 gap-y-8 lg:gap-y-0 flex-wrap md:flex-wrap lg:flex-nowrap lg:flex-row lg:justify-between lg:gap-x-8">
                    {
                      items.map((item,idx) => (
                        <div key={idx} className="group relative w-full bg-gray-100 rounded-2xl p-4 transition-all duration-500 max-md:max-w-md max-md:mx-auto md:w-2/5 xl:p-7 xl:w-1/4 hover:bg-secondry-button-color">
                            <div className="bg-white shadow-xl  rounded-full flex justify-center items-center mb-5 w-28 h-28 md:w-[208px] md:h-[208px] mx-auto">
                                <img src={item.icon} alt="icon"/>
                            </div>
                            <h4 className="text-md text-center md:text-start md:text-xl font-semibold text-primary-button-color mb-3 capitalize transition-all duration-500 group-hover:text-white">{item.title}</h4>
                            <Para className="transition-all duration-500 group-hover:text-white">
                                {item.description}
                            </Para>
                        </div>
                      ))
                    }
            </div>
        </Section>
    </>)
  }

  function CTA({setShowPopup}) {
    const [numberOfPeople, setNumberOfPeople] = useState(0);


    useEffect(() => {
      getNumberOfPeopleAreWaiting()
      .then(res => {
        setNumberOfPeople(res.people);
      })
      .catch(error => {
        console.error(error);
      })
    }, []);

    return (
      <Section>
        <div className="flex flex-col space-y-7 border border-secondry-color/20 rounded-xl px-4 py-14 text-center shadow-sm dark:bg-neutral-900 sm:px-6 lg:px-8">
          <BigHeading>
              Ready to Elevate Your Performance through 'Flow'?
          </BigHeading>

          <Para className="mx-auto max-w-5xl md:text-lg">
                Join the Waitlist for the opportunity to enter the XLRate Flow Programme and experience its true transformative power.
                
                Act Fast! There are limited spaces on the next 90-day programme, and currently, there are {numberOfPeople} individuals on the waitlist. Secure your spot now.
          </Para>

          <div className="flex flex-col justify-center space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4 max-w-md sm:mx-auto">
            <SecondryButton
              onClick={(e)=>setShowPopup(true)}
              style={{padding: "12px 20px",fontWeight: "bold"}}
            >
              Join the waitlist
            </SecondryButton>
          </div>
        </div>
      </Section>
    );
  }

  function Popup({setShowPopup}){
    const [data, setData] = useState({
      firstName: "",
      surName: "",
      email: "",
      showForm: true,
      showQuestions: false,
      showThankYou: false,
      answers: null,
    });

    const handleChange = (e) => {

      setData(prev => {
        return {
          ...prev,
          [e.target.name]: e.target.value
        }
      })
    }




    return (<>
      <div className="w-[100vw] h-[100vh] min-h-[100vh] bg-black/50 fixed top-0 left-0">
            {
              data.showForm && <UserDetail setShowPopup={setShowPopup} data={data} setData={setData} handleChange={handleChange}/>
            }
            {
              data.showQuestions && <QuestionsDetail data={data} setData={setData}/>
            }
            {
              data.showThankYou && <ThankYouMessage setShowPopup={setShowPopup}/>
            }
      </div>
    </>)
  }


  function UserDetail({data,setData,handleChange,setShowPopup}){

    const isValid = (email) =>  {

      const filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

      if (!filter.test(email)) {
        return false;
      }

      return true;
      
    }


    const handleStart = (e) => {
      if(data.email && data.firstName && data.surName){
        e.preventDefault();

        if(!isValid(data.email)){
          toast.error("Invalid Email");
          return;
        }

        setData(prev => {
          return {
            ...prev,
            showForm: false,
            showQuestions: true,
          }
        });
      }
    }


    const handleOutSideClick = (e) => {

      if(e.target.id === "user-detail"){
        setShowPopup(false)
      }

    }


    return (
      <>
      <div onClick={handleOutSideClick} id="user-detail" className="w-full h-full flex justify-center items-center px-2">
          <form className="w-[450px] max-w-full mx-auto  bg-white p-10 rounded-md">
            <div className="relative z-0 w-full mb-5 group">
                <input required value={data.firstName} onChange={handleChange}  autoComplete="off" type="text" name="firstName" id="firstName" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-secondry-button-color peer" placeholder=" "/>
                <label htmlFor="firstName" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-secondry-button-color peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">First name</label>
            </div>
            <div className="relative z-0 w-full mb-5 group">
                <input required value={data.surName} onChange={handleChange} autoComplete="off" type="text" name="surName" id="surName" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-secondry-button-color peer" placeholder=" "/>
                <label htmlFor="surName" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-secondry-button-color peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Last name</label>
            </div>
            <div className="relative z-0 w-full mb-5 group">
                <input required value={data.email} onChange={handleChange} autoComplete="off" type="email" name="email" id="floating_email" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-secondry-button-color peer" placeholder=" "/>
                <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-secondry-button-color peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
            </div>
            <PrimaryButton onClick={handleStart} style={{padding: "14px 0",marginTop: "40px"}} type="submit">
              Start
            </PrimaryButton>
          </form>
      </div>
      </>
    )
  }

  let timeOut = undefined;
  function QuestionsDetail({data,setData}){

    const [questions] = useState([
      {
        title: "Cognitive Performance Importance",
        instruction: "Please indicate how much you agree with the following statement.",
        question: "Enhancing my cognitive performance is critical to my professional success.",
        type: "rangeSlider",
        startTag: "Strongly disagree",
        endTag: "Strongly agree"
      },
      {
        title: "Top Challenges",
        instruction: "Select up to 3 challenges that you face in achieving optimal performance at work.",
        question: "What are your top three challenges in achieving optimal performance at work?",
        type: "multipleChoise",
        choises: ["Time Management","Stress Management","Decision Making","Focus and Concentration","Energy Levels","Work-Life Balance"],
        other: true
      },
      {
        title: "Current Satisfaction Cognitive Performance",
        instruction: "Please indicate how much you agree with the following statement.",
        question: "I am satisfied with my current methods for managing and enhancing cognitive performance.",
        type: "rangeSlider",
        startTag: "Strongly disagree",
        endTag: "Strongly agree"
      },
      {
        title: "Indispensable Features",
        instruction: "Select up to 3 features that would make XLRate indispensable for you.",
        question: "Which features would make XLRate indispensable for you?",
        type: "multipleChoise",
        choises: ["Personalised Coaching","Real-Time Performance Tracking","Advanced Cognitive Training Techniques","Access to Exclusive Content and Resources","Community Support and Networking","Regular Progress Reviews"],
        other: true
      },
      {
        title: "Recommendation Likelihood",
        instruction: "Please indicate how likely you are to recommend a product like XLRate to your peers or colleagues.",
        question: "I am likely to recommend a product like XLRate to my peers or colleagues.",
        type: "rangeSlider",
        startTag: "Strongly Disagree",
        endTag: "Strongly Agree"
      },
      {
        title: "Investment Willingness",
        instruction: "Please indicate how much you agree with the following statement.",
        question: "I would be willing to invest",
        type: "rangeSlider",
        startTag: "Less than £2000",
        endTag: "More than £5000"
      },
      {
        title: "Beta Testing Participation",
        instruction: "Please indicate how much you agree with the following statement.",
        question: "I would participate in a beta testing program for XLRate, at a reduced price, in exchange for providing feedback and testimonials.",
        type: "rangeSlider",
        startTag: "Strongly Disagree",
        endTag: "Strongly Agree"
      },
      {
        title: "Cost Barrier",
        instruction: "Please indicate how much you agree with the following statement.",
        question: "If I decided not to use XLRate, it would most likely be due to the cost.",
        type: "rangeSlider",
        startTag: "Strongly Disagree",
        endTag: "Strongly Agree"
      },
      {
        title: "Desired Outcomes",
        instruction: "Select up to 3 outcomes that would make you feel the investment in XLRate is worthwhile.",
        question: "Which of the following outcomes would make you feel the investment in XLRate is worthwhile?",
        type: "multipleChoise",
        choises: [
            "Increased Productivity (Achieving more in less time)",
            "Better Problem-Solving (Improved decision-making skills)",
            "Accelerated Learning (Faster and more effective learning)",
            "Increased Fulfilment (Greater satisfaction and happiness at work)",
            "Flow State Optimisation (More frequent and sustained flow states)",
            "Personal Development (Overall growth and self-improvement)"
          ],
        other: false
      },
    ]);
    const [quesIndex, setQuesIndex] = useState(0);
    const [answers, setanswers] = useState([]);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const question = questions[quesIndex];


    const prepareAnswers = (ans,idx) => {
      let key = undefined;
      let value = undefined;

      if(questions[idx].type === "rangeSlider"){
        key = "value";
        value = Number(ans);
      }
      if(questions[idx].type === "multipleChoise"){
        key = "choices";
        value = ans;
      }

      return {
        order: idx+1,title: questions[idx].title,
        question: questions[idx].question,
        type: questions[idx].type,
        [key]: value
      }
    }

    const handleNext = async (e) => {
      if(e === null){
        if(quesIndex === questions.length-1){
          // console.log("last question");
          const waitingList = {
            firstName: data.firstName,
            surName: data.surName,
            email: data.email,
            pageName: "B",
            answers: answers.map(prepareAnswers)
          };
          setIsSubmiting(true);
          const res = await createWaitingList(waitingList);
          setIsSubmiting(false);

          if(res.success){
            setData(prev => {
              return {
                ...prev,
                showQuestions: false,
                showThankYou: true
              }
            })
          }
          else {
            toast.error("Something went wrong!");
          }
          return;
        }
        setQuesIndex(prev => prev+1);
        return;
      }

      if(question.type === "rangeSlider"){
        if(timeOut !== undefined){
          clearTimeout(timeOut);
        }


        setanswers(prev => {
          const newArray = [...prev];
          newArray[quesIndex] = e.target.value;
          return newArray;
        });

        timeOut = setTimeout(() => {
                    setQuesIndex(prev => prev+1);
                  }, 1000);
        return;
      }

      if(question.type === "input"){
        setanswers(prev => {
          const newArray = [...prev];
          newArray[quesIndex] = {
            ...newArray[quesIndex],
            [e.target.name]: e.target.value
          };

          return newArray;
        });
        return;
      }

      if(question.type === "multipleChoise"){

        // if other 
        if(e.target){
          setanswers(prev => {
            const newArray = [...prev];
            
            newArray[quesIndex] = [e.target.value]

            return newArray;
          });
          return;
        }

        const choise = e;

        setanswers(prev => {
          const newArray = [...prev];
          const choises = newArray[quesIndex];

          if(!choises){
            newArray[quesIndex] = [choise];
          }
          if(choises){
            const isPresent = choises.find(ch => ch === choise);
            newArray[quesIndex] = isPresent === undefined? [...choises,choise]:choises.filter(ch => ch !== choise);
          }

          return newArray;
        });
        return;
      }
    }

    const handleBack = () => {
      if(quesIndex !== 0){
        setQuesIndex(prev => prev-1);
        return;
      }

      setData(prev => {
        return {
          ...prev,
          showQuestions: false,
          showForm: true
        }
      });
    }


    return (
      <>
        <div className="w-full h-full py-10 md:my-0 bg-primary-color overflow-auto">
            <div className="w-[700px] max-w-full rounded-md p-2 mx-auto">
              <div className="mb-10">
                <p className="text-secondry-color/80 mb-4">Welcome! Thank you for your interest in our exclusive ‘90 day XLRate Flow coaching program’.</p>
                <p className="text-secondry-color/80">Before you join the waitlist, help us understand your needs so we can tailor a highly impactful coaching experience for you.</p>
              </div>
              <div className="mb-20">
                <ProgressBar totalSteps={questions.length} completedSteps={quesIndex} bgClass="bg-primary-button-color"/>
              </div>
              <div className="mb-3">
                <span onClick={handleBack}  className="cursor-pointer font-bold underline text-secondry-button-color">Back</span>
              </div>
              {/* <h2 className="text-secondry-color mb-2">{question.title}</h2> */}
              <h3 className="text-secondry-color mb-6">{question.instruction}</h3>
              <h1 className="text-secondry-color font-bold text-xl md:text-4xl">{question.question}</h1>

              <div className="mt-8">
                        
                      {
                          question.type === "rangeSlider" && <>
                                                              <input 
                                                                type="range" 
                                                                className="w-full appearance-none bg-secondry-color/10 rounded-full"
                                                                onChange={handleNext}
                                                                value={answers[quesIndex] || 50}
                                                              />
                                                              <div className="flex justify-between font-bold text-secondry-color/40">
                                                                <span>{question.startTag}</span>
                                                                <span>{question.endTag}</span>
                                                              </div>
                                                            </>
                      }

                      {
                          question.type === "input" && <>
                                                                {
                                                                  question.inputNames.map((name,idx) => {
                                                                    const value = answers[quesIndex]? answers[quesIndex][name]:""
                                                                    return <div key={idx} className="relative z-0 w-full mb-5 group">
                                                                                <input value={value} onChange={handleNext} autoComplete="off" type="text" name={name} id={name} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-secondry-button-color peer" placeholder=" "/>
                                                                                <label htmlFor={name} className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-secondry-button-color peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">{name}</label>
                                                                            </div>
                                                                          })
                                                                }
                                                                <div className="flex justify-end">
                                                                  <PrimaryButton onClick={()=>handleNext(null)} style={{padding: "12px 0",marginTop: "40px",width: "130px"}}>
                                                                    Next
                                                                  </PrimaryButton>
                                                                </div>
                                                              </>
                      }
                      {
                          question.type === "multipleChoise" && <>
                                                                <div className="flex gap-4 flex-wrap">
                                                                    {
                                                                      question.choises.map((choise,idx) => 
                                                                        {
                                                                          const isSelected = answers[quesIndex] === undefined? false:answers[quesIndex].indexOf(choise) !== -1?true:false

                                                                          return <button onClick={()=> handleNext(choise)} key={idx} className={`${isSelected? "bg-secondry-button-color text-white font-bold":"bg-secondry-color/40"} transition ease-in-out px-4 py-2 rounded-md`}>{choise}</button>
                                                                        }
                                                                      )
                                                                    }
                                                                </div>
                                                                {
                                                                  question.other && <>
                                                                            <div className="relative z-0 w-full mb-5 group mt-10">
                                                                                <input value={answers[quesIndex]? question.choises.indexOf(answers[quesIndex][0]) === -1? answers[quesIndex][0]:"":""} onChange={handleNext} autoComplete="off" type="text" name={"other"} id={"other"} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-secondry-button-color peer" placeholder=" "/>
                                                                                <label htmlFor={"other"} className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-secondry-button-color peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Other</label>
                                                                            </div>
                                                                  </>
                                                                }
                                                                <div className="flex justify-end">
                                                                  <PrimaryButton onClick={()=>handleNext(null)} style={{padding: "12px 0",marginTop: "40px",width: "130px"}}>
                                                                    {quesIndex === questions.length-1? `${isSubmiting? "Saving..":"Finish"}`:"Next"}
                                                                  </PrimaryButton>
                                                                </div>
                                                              </>
                      }

              </div>
            </div>
        </div>
      </>
    )
  }


  function ThankYouMessage({setShowPopup}){

    return <>
    <div className="w-full h-full flex justify-center items-center px-2">
          <div className="mx-auto max-w-md overflow-hidden rounded-3xl text-gray-700 shadow-md">
                <div className="flex flex-col items-center bg-white px-4 py-10">
                  <p className="mb-4 text-center font-medium text-gray-500">Thank you for your participation!</p>
                  <p className="mb-4 text-center font-medium text-gray-500">Your feedback is invaluable and will help us shape an exceptional coaching program that meets your needs.</p>
                  <p className="mb-4 text-center font-medium text-gray-500">We just sent you a welcome email. We look forward to potentially welcoming you to the XLRate community.</p>

                  <div className="px-[10px] w-full mt-10">
                      <SecondryButton onClick={()=>setShowPopup(false)} style={{padding: "12px 0"}}>Home</SecondryButton>
                  </div>
                </div>
              </div>
          </div>
    </>
  }

  function Sections(){


    return (<>
          <Section>
            <Heading>Discover the Ultimate Peak Performance Coach for Elite Executives</Heading>
            <Para className="font-bold">Imagine having a personal coach who sees your hidden potential and tailors strategies to help you unlock it.</Para>
            <Para>XLRate's exclusive 'Fitbit for your mind' technology evaluates your cognitive strengths and areas for improvement in just 60 seconds.</Para>
          </Section>

          <Section>
            <Heading>Experience Personalised Excellence</Heading>
            <List
              list={[
                {
                  value: "Data-driven personalised actions"
                },
                {
                  value: "Strategies to achieve high-performance flow states"
                },
                {
                  value: "Transform into the best version of yourself"
                }
              ]}
            />
          </Section>

          <Section>
            <Heading>What is a Flow State?</Heading>
            <Para>Flow states, often called 'in the zone,' are periods of intense focus and immersion in an activity. During flow, you will experience</Para>
            <List
              list={[
                {
                  value: "Heightened creativity"
                },
                {
                  value: "Improved problem-solving abilities"
                },
                {
                  value: "A significant boost in productivity"
                },
              ]}
            />
            <Para className="font-bold">XLRate helps you tap into these optimal mental states to perform at your peak when you need it most.</Para>
          </Section>

          <Section>
            <Heading>XLRate Founder: Howard Benjafield</Heading>
            <Para>
              Howard Benjaﬁeld,<br/>
              An expert in the Neuro-Psychology of Productive Cognitive Flow, leads the XLRate team. <br/>
              With years of pioneering scientific research at world-renowned institutions, including Harvard Medical School, and in the defence and security sectors, Howard now works with small, select groups of elite clients.<br/> 
              His background and deep passion for developing science-based technology to assist individuals and teams in reaching their highest performance state drive his commitment to helping visionaries and elite professionals unlock their hidden potential, transforming their professional and personal lives.</Para>
          </Section>

          <Section>
            <Heading>Why Elite Performers Choose XLRate</Heading>
            <List
              list={[
                {
                  value: "Maximise Productivity through Flow",
                  subList: ["Enhance your focus and efficiency dramaticall","Research shows being in a flow state can increase productivity by up to 500%"]
                },
                {
                  value: "Enhance Creativity",
                  subList: ["Stay ahead in innovation by reaching and maintaining your creative peak with our tailored tools"]
                },
                {
                  value: "Accelerate Learning",
                  subList: ["Develop new skills faster with advanced insights that keep you agile in a dynamic market"]
                },
                {
                  value: "Advanced Problem-Solving",
                  subList: ["Transform challenges into opportunities with enhanced problem-solving capabilities facilitated by flow states"]
                },
              ]}
            />
          </Section>

          <Section>
            <Heading>XLRate Delivers Tailored Cognitive Optimisation Throughout Your Day</Heading>
            <List
             list={[
              {
                value: "Cognitive Blueprint Mapping",
                subList: ["Tailor your mental performance optimally for critical","tasks using our sophisticated machine-learning techniques"]
              },
              {
                value: "Real-Time Feedback",
                subList: ["Continuously monitor your cognitive performance like a fitness tracker monitors physical health"]
              },              
              {
                value: "Customised Strategic Coaching",
                subList: ["Receive coaching that aligns with your schedule and professional growth, ensuring constant development"]
              },              
              {
                value: "Flow State Optimisation",
                subList: ["Learn to achieve and maintain peak mental states to boost operational effectiveness and strategic thinking"]
              },              
             ]}
            />
          </Section>

          <Section>
            <Heading>What to Expect - The 90-Day Programme</Heading>
            <List
               list={[
                {
                  value: "Weekly Breakdown",
                  subList: [
                    {
                      value: "Weeks 1-2: Assessment and Baseline Setting",
                      subList: ["Comprehensive cognitive assessment","Establishing your cognitive baseline","Initial personalised strategy session","Weekly one-to-one expert analysis and peak performance coaching sessions"]
                    },
                    {
                      value: "Weeks 3-4: Foundation Building",
                      subList: ["Introduction to flow state principles","Initial cognitive optimisation exercises","Real-time feedback implementation","Weekly one-to-one expert analysis and peak performance coaching sessions"]
                    },
                    {
                      value: "Weeks 5-8: Deep Dive and Customisation",
                      subList: ["Advanced cognitive mapping and tailored strategies","Intensive coaching sessions focused on specific goals","Continuous real-time performance tracking","Weekly one-to-one expert analysis and peak performance coaching sessions"]
                    },
                    {
                      value: "Weeks 9-12: Mastery and Integration",
                      subList: ["Mastery of flow state techniques","Integration of cognitive optimisation into daily routines","Final performance assessment and long-term strategy development","Weekly one-to-one expert analysis and peak performance coaching sessions"]
                    }
                  ]
                },
                {
                  value: "Ongoing Support",
                  subList: ["Access to exclusive resources and community","Optional continued coaching for sustained high performance"]
                }                
               ]}
            />
          </Section>
    </>)
  }

  export function Section({children}){

    return <>
      <section className="py-[25px] md:py-[50px] w-full max-w-7xl mx-auto px-4 sm:px-6  lg:px-8">
        {children}
      </section>
    </>
  }

  export function BigHeading({children}){

    return <>
      <h1 className="font-bold text-[1.6em] md:text-[2em] md:text-[1.6em] text-primary-button-color mb-2">
        {children}
      </h1>
    </>
  }

  export function Heading({children}){

    return <>
      <h1 className="font-bold text-[1.1em] md:text-[1.1em] text-primary-button-color mb-2">
        {children}
      </h1>
    </>
  }

  export function SubHeading({children}){

    return <>
      <p className="font-bold mb-2 text-secondry-color mb-2">
        {children}
      </p>
    </>
  }

  export function List({list}){

    if(!list){
      return null
    }

    return (<>
        <ul className="list-none pl-4 text-secondry-color mb-2 text-base md:text-lg">
          {
            list.map((item,idx) => (
              <li key={idx}>
                <div className="flex p-1">
                  {item.value}
                </div>
                {
                  item.subList && <>
                      <ul className="list-disc pl-8">
                        {
                          item.subList.map((subItem,idx) => (
                            <li key={idx}>
                              {
                                typeof subItem === "string" && <div className="py-1">{subItem}</div>
                              }
                              {
                                typeof subItem !== "string" && <>
                                  {subItem.value}
                                  <ul className="list-disc pl-8">
                                      {
                                          subItem.subList?.map((subItem,idx) => <li className="py-1" key={idx}>{subItem}</li>)
                                      }
                                    </ul>
                                </>
                              }
                            </li>
                          ))
                        }
                      </ul>
                  </>
                }
              </li>
            ))
          }
        </ul>
    </>)
  }

