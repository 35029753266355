import userReducer from "./user";
import globalStatesReducer from "./globalStates";
import loggedInUser from "./auth";
import numberOfActionChallenges from "./numberOfActionChallenges";
import {previousRoute} from "./previousRoute";


const rootReducer = {
    user: userReducer,
    globalStates: globalStatesReducer,
    loggedInUser: loggedInUser,
    numberOfActionChallenges: numberOfActionChallenges,
    previousRoute: previousRoute
}


export default rootReducer;