import React, { useEffect, useState } from 'react';
import {
  FirstPage,
  SecondPage,
  ThirdPage,
  ForthPage
} from "./components";
import {useLocation} from "react-router-dom";
import { useSelector } from 'react-redux';
import {Main} from "../../layout";

const keys = ["_1", "_2", "_3", "_4", "_5", "_6", "_7", "_8", "_9","_10","_11"];

function Index() {

  const location = useLocation();
  const loggedInUser = useSelector(state => state.loggedInUser);
  const [selectedActivityes, setSelectedActivityes] = useState(()=> {
    return location.state? Object.keys(location.state).filter(key => location.state[key]).map(key=> {return {category: key,name: location.state[key]}}):[];
  });

  const [purpose,setPurpose] = useState({});
  const [goal,setGoal] = useState({});
  const [visivility, setVisivility] = useState({
    firstPage: true,
    secondPage: false,
    thirdPage: false,
    forthPage: false,
  });
  const [currentActivityIndex, setCurrentActivityIndex] = useState(0);
  const currentActivity = selectedActivityes[currentActivityIndex];
  const [activityesWithPurposeAndGoal, setActivityesWithPurposeAndGoal] = useState([]);
  const currentCategory = currentActivity?.category;
  const currentName = currentActivity?.name;

  useEffect(() => {
    if(loggedInUser?.activities){
       setSelectedActivityes(loggedInUser?.activities);
       const ca = loggedInUser?.activities.find(c => c.category === currentCategory);
       if(ca){
         const temp = {};
         const temp1 = {};
          ca.purpose?.forEach((p,i) => {
           temp[keys[i]] = p;
          });
          ca.goal?.forEach((g,i) => {
            temp1[keys[i]] = g;
          });
          // console.log(temp);
          // console.log(temp1);
          setPurpose(temp);
          setGoal(temp1);
        }
    }
  }, [loggedInUser?.activities,currentCategory]);
  
  

  return (
    <Main>
      {
        visivility.firstPage && <FirstPage currentCategory={currentCategory} currentName={currentName} setVisivility={setVisivility} purpose={purpose} setPurpose={setPurpose}/>
      }
      {
        visivility.secondPage && <SecondPage currentCategory={currentCategory} currentName={currentName} setVisivility={setVisivility} purpose={purpose}/>
      }
      {
        visivility.thirdPage && <ThirdPage purpose={purpose} currentCategory={currentCategory} currentName={currentName} setVisivility={setVisivility} goal={goal} setGoal={setGoal}/>
      }
      {
        visivility.forthPage && <ForthPage purpose={purpose} activityesWithPurposeAndGoal={activityesWithPurposeAndGoal} setActivityesWithPurposeAndGoal={setActivityesWithPurposeAndGoal} setPurpose={setPurpose} setGoal={setGoal} setCurrentActivityIndex={setCurrentActivityIndex} currentCategory={currentCategory} currentName={currentName} setVisivility={setVisivility} goal={goal} selectedActivityes={selectedActivityes}/>
      }
    </Main>
  )
}

export default Index;



