import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderOptions } from "../utils";
import { 
    Header, 
    Options,
    Wrapper 
} from "./index";
import { PrimaryButton} from "../../../components";
import { updateLoggedInUser } from "../../../Store/actions";
import {Main} from "../../../layout";
import { toast } from "react-toastify";
import { useScrollToTop } from "../../../hooks";


function PersonalImprovementObstacles({setScreen}){
    const tag = "Personal Barriers";
    const loggedInUser = useSelector(state => state.loggedInUser);
    const [options, setOptions] = useState(()=> {
        return [
            {
                order: null,
                option: null,
                isSelected: false,
                tag,
            },
            {
                order: null, 
                option: "Feeling Overwhelmed",
                isSelected: false,
                tag: "Emotional Barriers"
            },
            {
                order: null, 
                option: "Fear of Failure",
                isSelected: false,
                tag: "Emotional Barriers"
            },
            {
                order: null, 
                option: "Confidence Issues",
                isSelected: false,
                tag: "Emotional Barriers"
            },
            {
                order: null, 
                option: "Stress and Burnout",
                isSelected: false,
                tag: "Emotional Barriers"
            },
            {
                order: null, 
                option: "Imposter Syndrome",
                isSelected: false,
                tag: "Emotional Barriers"
            },
            {
                order: null, 
                option: "Limited Skillset",
                isSelected: false,
                tag: "Skillset Limitations"
            },
            {
                order: null, 
                option: "Lack of Clear Goals",
                isSelected: false,
                tag: "Skillset Limitations"
            },
            {
                order: null, 
                option: "Decision-Making Pressures",
                isSelected: false,
                tag: "Skillset Limitations"
            },
            {
                order: null, 
                option: "Procrastination",
                isSelected: false,
                tag: "Motivational Challenges"
            },
            {
                order: null, 
                option: "Lack of Direction",
                isSelected: false,
                tag: "Motivational Challenges"
            },
            {
                order: null, 
                option: "Seeking Motivation",
                isSelected: false,
                tag: "Motivational Challenges"
            },
            {
                order: null, 
                option: "Finding Focus",
                isSelected: false,
                tag: "Motivational Challenges"
            },
            {
                order: null, 
                option: "Balancing High-Stakes Responsibilities",
                isSelected: false,
                tag: "Leadership Challenges"
            },
            {
                order: null, 
                option: "Managing Large Teams",
                isSelected: false,
                tag: "Leadership Challenges"
            },
            {
                order: null, 
                option: "Lack of Support",
                isSelected: false,
                tag: "Leadership Challenges"
            },
            {
                order: null, 
                option: "Fostering Better Workplace Relationships",
                isSelected: false,
                tag: "Leadership Challenges"
            },
            {
                order: null, 
                option: "Enhancing Work-Life Balance",
                isSelected: false,
                tag: "Work-Life Balance"
            },
        ];
    });
    const dispatch = useDispatch();
    useScrollToTop();

    const handleBack = () => {
        setScreen(prev => {
            return {
                ...prev,
                personalImprovementGoals: true,
                personalImprovementObstacles: false
            }
        })
    }

    const handleClick = (item) => {
        setOptions(prev => {
            const selectedCount = prev.filter(it => it.isSelected);
            if(selectedCount.length === 3 && !item.isSelected){
                toast.error("You can't select more than three.")
                return prev;
            }
            return orderOptions(prev,item)
        })
    }

    const handleNext = async () => {
        const found = options.find(it => it.isSelected);

        if(!found){
            toast.error("Please select at least one.")
            return;
        }

        const activitieWithNoName = loggedInUser.activities.find(act => !act.name);
        // console.log(activitieWithNoName);
    
        if(activitieWithNoName){
            setScreen(prev => {
                return {
                    ...prev,
                    personalImprovementObstacles: false,
                    enterActivityTitle: true
                }
            });
            dispatch(updateLoggedInUser({isPersonalImprovementObstaclesSeen: true,personalImprovementObstacles: options.filter(it => it.isSelected).sort((a,b) => a.order-b.order).map(it => it.option)}));
            return;
        }
        
        if(!loggedInUser.organizationName){
            setScreen(prev => {
                return {
                    ...prev,
                    personalImprovementObstacles: false,
                    enterActivityTitle: false,
                    organisationName: true
                }
            });
            dispatch(updateLoggedInUser({isPersonalImprovementObstaclesSeen: true,isEnterActivityTitleSeen: true,personalImprovementObstacles: options.filter(it => it.isSelected).sort((a,b) => a.order-b.order).map(it => it.option)}));
            return;
        }
        
        if(!loggedInUser.isPreSurveyDone){
            setScreen(prev => {
                return {
                    ...prev,
                    personalImprovementObstacles: false,
                    enterActivityTitle: false,
                    organisationName: false,
                    baiseline: true
                }
            });
        }

        setScreen(prev => {
            return {
                ...prev,
                personalImprovementObstacles: false,
                enterActivityTitle: false,
                organisationName: false,
                baiseline: false,
                welcome: true,
            }
        });

        dispatch(updateLoggedInUser({isPersonalImprovementObstaclesSeen: true,isEnterActivityTitleSeen: true,personalImprovementObstacles: options.filter(it => it.isSelected).sort((a,b) => a.order-b.order).map(it => it.option)}));
    }

    useEffect(() => {
        if(loggedInUser.personalImprovementObstacles){
            setOptions((prev) => {
                return prev.map(op => {
                    return loggedInUser.personalImprovementObstacles.indexOf(op.option) === -1? op:{...op,isSelected: true,order: loggedInUser.personalImprovementObstacles.indexOf(op.option)+1};
                })
            });
            setOptions((prev) => {
                const userAddedGoals = loggedInUser.personalImprovementObstacles.map((obstacle,idx) => {return {option: obstacle,order:idx+1}}).filter(option => prev.findIndex(item => item.option === option.option) === -1).map(option => {return {option: option.option,isSelected: true,tag,order: option.order}});
                return [...userAddedGoals,...prev];
            });
        }
    }, [loggedInUser.personalImprovementObstacles]);

    return (<>
        <Main>
            <Wrapper>
                {/* <ProgressBarAndBackButton handleBack={handleBack} completedSteps={3}/> */}
                <Header handleBack={handleBack} title="Personalise"/>
                
                <div className="h-[69vh] overflow-auto">
                    <h1 className='text-2xl font-semibold text-secondry-color mb-2'>Identify Barriers to Your Peak Performance and Growth</h1>
                    <p className="text-secondry-color">Select the challenges that resonate most with you so we can tailor our support to help you overcome them</p>
                    <Options tag={tag} options={options} handleClick={handleClick} setOptions={setOptions}/>
                </div>

                <div className="mt-10">
                    <PrimaryButton style={{padding: "12px 0"}} onClick={handleNext}>
                        Continue to Overcome My Barriers
                    </PrimaryButton>
                </div>
            </Wrapper> 
        </Main> 
    </>)
}


export {PersonalImprovementObstacles};