import { useEffect, useRef } from "react";
import { Wrapper, Header } from "./index";
import { PrimaryButton } from "../../../components";
import { updateLoggedInUser } from "../../../Store/actions";
import { useDispatch } from "react-redux";
import {Main} from "../../../layout";
import { useScrollToTop } from "../../../hooks";




function Welcome({setScreen}){
    const videoEl = useRef(null);
    const dispatch = useDispatch();
    useScrollToTop();


    const team = [
        {
            name: "Empower Your Growth",
            description: "Access personalised development strategies tailored to your unique needs, putting your professional growth at your fingertips."
        },
        {
            name: "Track Progress",
            description: "Monitor your journey to peak performance and well-being with real-time insights and data-driven feedback."
        },
        {
            name: "Confidential Insights",
            description: "Keep your personal data private, secure, and confidential while leveraging powerful analytics to gain actionable insights."
        },
        {
            name: "Shape a Better Workplace",
            description: "Provide anonymised feedback to help create a positive culture and thriving work environment within your organisation."
        }
    ];


    const handleNext = async () => {
        dispatch(updateLoggedInUser({isWelcomeSeen: true}));

        setScreen(prev => {
            return {
                ...prev,
                welcome: false,
                finalProjection: true,
            }
        });
    }

    const handleBack = () => {
        setScreen(prev => {
            return {
                ...prev,
                organisationName: true,
                welcome: false
            }
        })
    }

    useEffect(() => {
        videoEl.current.volume = .4;
    }, []);


    return (
        <>
            <Main>
                <Wrapper>
                    {/* <ProgressBarAndBackButton handleBack={null} completedSteps={0}/> */}
                    <Header title="How XLRate works" handleBack={handleBack}/>

                    <div className="h-[72vh] md:h-[74vh] overflow-auto">
                        <h3 className="text-secondry-color text-md font-semibold mb-2 mt-6">How does XLRate empower you?</h3>

                        <video ref={videoEl} className="w-full rounded-lg mt-4" controls muted={false}>
                            <source src="https://res.cloudinary.com/dsnjr85ys/video/upload/v1702466202/XLRate_why_your_state_matters_dxjuct.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <p className="text-secondry-color my-6">With XLRate, you can effortlessly identify and enhance the psychological factors that are limiting your ability to achieve your full potential.</p>

                        <h3 className="text-secondry-color text-md font-semibold mt-4">How does XLRate work?</h3>

                        <video ref={videoEl} className="w-full rounded-lg mt-2" controls muted={false}>
                            <source src="https://res.cloudinary.com/dsnjr85ys/video/upload/v1702468141/XLrate_Platform_vk38at.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>

                        <ul className="flex flex-wrap list-disc ml-5 my-6">
                            {
                                team.map((item, idx) => (
                                    <li key={idx} className="w-full mb-1">
                                        <h3 className="text-secondry-color text-[16px] mb-0">
                                            <span className="font-bold">{item.name}:</span>
                                            <span className='font-normal pl-2'>{item.description}</span>
                                        </h3>
                                    </li>
                                ))
                            }
                        </ul>

                        <p className='text-secondry-color mt-4'>Ready to unlock your true potential? Start your science-backed journey now!</p>
                    </div>

                    <div className="mt-5 flex justify-center">
                        <PrimaryButton style={{padding: "12px 0"}} onClick={handleNext}>
                            Let's Go
                        </PrimaryButton>
                    </div>
                </Wrapper> 
            </Main>    
        </>
    )
}


export {Welcome};