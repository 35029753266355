import { SET_NUMBER_OF_ACTION_CHALLENGES } from "../../constants";



function numberOfActionChallenges(state = 0, action) {

    switch (action.type) {

      case SET_NUMBER_OF_ACTION_CHALLENGES:
        return action.payload;

      default:
        return state
    }
}

export default numberOfActionChallenges;