import React, { useEffect, useState } from "react";
import { MdAddBox } from "react-icons/md";
import {
  AddSurveyForm,
  Survey
} from "./components";
import { useDispatch } from "react-redux";
import {
  getSurveyTitleById,
  createSurveyTitle,
  updateSurveyTitle,
  getAllSurveyTitles,
} from "../../Store/actions/index.js";
import { toast } from "react-toastify";
import { Main } from "../../layout/index.js";


function Index() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ questions: [] });
  const [toggleUpdateForm, setToggleUpdateForm] = useState(false);
  const [toggleInsertForm, setToggleInsertForm] = useState(false);
  const [allSurveyTitles, setAllSurveyTitles] = useState([]);
  // _id to update resources
  const [_id, set_id] = useState("");

  const handleUpdate = async (id) => {
    set_id(id);
    const res = await getSurveyTitleById(id);

    setFormData((prev) => {
      return {
        ...res.surveyTitle,
      };
    });
    setToggleUpdateForm(true);
  };

  const handdleUpdateSubmit = async () => {
    const res = await updateSurveyTitle(_id, formData);
    if (res.success) {
      toast.success(res.message);

      const updatedSurveyTitles = allSurveyTitles.map((st) =>
        st._id === _id ? { ...st, ...res.surveyTitle } : st
      );
      setAllSurveyTitles(updatedSurveyTitles);
    } else {
      toast.error(res.message);
    }
  };

  const handdleAddSurveySubmit = async (e) => {
    const res = await createSurveyTitle(formData);

    if (res.success) {
      toast.success(res.message);
      const updatedSurveyTitles = [...allSurveyTitles, { ...res.surveyTitle }];
      setAllSurveyTitles(updatedSurveyTitles);
      setToggleInsertForm(false);
    } else {
      toast.error(res.message);
    }
  };

  useEffect(() => {
    (async () => {
      const res = await getAllSurveyTitles();
      if (res.success) {
        setAllSurveyTitles(res.surveyTitles);
      } else {
        toast.error(res.message);
      }
    })();
  }, [dispatch]);

  return (
    <>
      <Main>
        <h2 className="text-center py-10 text-2xl font-bold text-secondry-color">Survey Title</h2>
        <div className="pb-10">
          <div className="surveytitle-container">
            {allSurveyTitles.map((item, index) => {
                  return (
                    <Survey
                      surveyTitle={item}
                      key={index}
                      handleUpdate={handleUpdate}
                      setAllSurveyTitles={setAllSurveyTitles}
                    />
                  );
                })}
          </div>
        </div>
        

        {/* update Modal  */}
        <AddSurveyForm onSubmit={handdleUpdateSubmit} formData={formData} setFormData={setFormData} isOpen={toggleUpdateForm} setIsOpen={setToggleUpdateForm} />

        {/* insert Modal  */}
        <AddSurveyForm onSubmit={handdleAddSurveySubmit} formData={formData} setFormData={setFormData} isOpen={toggleInsertForm} setIsOpen={setToggleInsertForm} />

      </Main>
      {/* add question btn   */}
      <div className="fixed bottom-[20px] left-[20px] cursor-pointer" title="Add Survey Title">
          <MdAddBox onClick={()=>{
            setFormData({ questions: [] });
            setToggleInsertForm(true);
          }}
          size={50}
          />
      </div>
    </>
  );
}

export default Index;
