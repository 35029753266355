import React from 'react';

export function Table({children}){

  return (
    <div class="w-full flex flex-col max-h-full">
      <div class="w-full flex-grow overflow-auto">
        <table className="w-full table-auto relative text-sm text-left">
            {children}
        </table>
      </div>
    </div>
  )
}

export function Thead({children}) {


  return (
      <thead className="text-secondry-color font-medium border-b border-secondry-color/20">
          {children}
      </thead>
  )
}

export function Tbody({children}){

  return (
      <tbody className="text-secondry-color divide-y">{children}</tbody>
  )
}

export function Th({children}){
  return (
      <th className="sticky top-0 bg-primary-color py-3 pr-6 whitespace-nowrap">{children}</th>
  )
}

export function Td({children}){
  return ( <td className="pr-6 py-4 whitespace-nowrap">{children}</td> )
}

export function Tr({children}){
  return (<tr>{children}</tr>)
}



