import {fetchRequest} from "../../utils";
import { 
    SET_GROUPS, 
    UPDATE_GROUP, 
    DOMAIN_NAME
} from "../../constants";
import {toast} from "react-toastify";




export const getAllGroups = async () => {

    const res = await fetchRequest(`${DOMAIN_NAME}/group/all`,"GET",{});

    return res;
}

export const getGroupById = async (groupId) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/group/${groupId}`,"GET",{});

    return res;
}

export const getAvgOfLXScoreOfSec123 = async (userId) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/group/groups/avegoflxscoreofsec123/${userId}`,"GET",{});

    return res;
}

export const addUserToGroup = async (userId,groupId) => {

    // console.log(userId,groupId)
    const res = await fetchRequest(`${DOMAIN_NAME}/group/add/user/to/group/${groupId}`,"PUT",{userId});

    return res;
}

export const removeUserFromGroup = async (userId,groupId) => {

    // console.log(userId,groupId)
    const res = await fetchRequest(`${DOMAIN_NAME}/group/remove/${groupId}`,"PUT",{userId});

    return res;
}

export const setAllGroups = () => async (dispatch) =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/group/all`,"GET",{});

        if(res.success){
           dispatch({type: SET_GROUPS ,payload: res.groups});
        }
        else {
            toast.error(res.message)
        }
    } catch (error) {
        toast.error(error.message);
    }
}

export const getUniversalGroup = async () =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/group/get/universal/group`,"GET",{});
        return res;

    } catch (error) {
        toast.error(error.message);
    }
}

export const getNewUsersGroup = async () =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/group/get/new-users/group`,"GET",{});
        return res;

    } catch (error) {
        toast.error(error.message);
    }
}

export const addGroup = async (data) =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/group`,"POST", data);
        
        return res;
        
    } catch (error) {
        toast.error(error.message);
    }
}

export const updateGroupName = async (id,data) =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/group/${id}`,"PUT",data);

        return res;

    } catch (error) {
        toast.error(error.message);
    }
}

export const deleteGroup = async (id) => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/group/${id}`,"DELETE",{});

        return res;
        
    } catch (error) {
        toast.error(error.message);
    }
}

export const addOrRemoveSurveyTitleFromGroup = (groupId,data) => async (dispatch) =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/group/surveys/addremove/${groupId}`,"PUT",data);

        if(res.success){
            console.log(res);
            dispatch({type: UPDATE_GROUP ,payload: {_id: res.group._id,group:res.group}});
        }
        else {
            toast.error(res.message)
        }
    } catch (error) {
        toast.error(error.message);
    }
}

export const addOrRemoveGroupFromGroup = async (groupId,groups) =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/group/add/groupstogroups/${groupId}`,"PUT",groups);

        return res;
        
    } catch (error) {
        toast.error(error.message);
    }
}


