import {randomInRange} from "./index";
import { ADD_EVENT, CHOOSE_GOAL_AND_ACHIVEMENTS, CHOOSE_THINGS_TO_IMPROVE, COMPLETE_SURVEY, LOGIN_AFTER_FIRST_TIME, LOGIN_FIRST_TIME } from "../constants";


const rewardValues = {
    A: {min: 1,max: 15,multiplier: 1},
    B: {min: 16,max: 40,multiplier: 1.5},
    C: {min: 41,max: 70,multiplier: 1.2},
    D: {min: 71,max: 85,multiplier: 1.4},
    E: {min: 86,max: 97,multiplier: 1.8},
    F: {min: 98,max: 99,multiplier: 2.5},
    G: {min: 99,max: 100,multiplier: 5},
}


function getMultiplier(val){
    for (const key in rewardValues) {
      if(val >= rewardValues[key].min && val <= rewardValues[key].max){
        return {multiplier: rewardValues[key].multiplier,grade: key}
      }
    }
}

  
export const actionsAndTheirRewards = [
    {
        action: LOGIN_FIRST_TIME,
        rewardPoints: 50
    },
    {
        action: LOGIN_AFTER_FIRST_TIME,
        rewardPoints: 5
    },
    {
        action: CHOOSE_THINGS_TO_IMPROVE,
        rewardPoints: 10	//3 times only
    },
    {
        action: CHOOSE_GOAL_AND_ACHIVEMENTS,
        rewardPoints: 15	//3 times only
    },
    {
        action: COMPLETE_SURVEY,
        rewardPoints: 50
    },
    {
        action: ADD_EVENT,
        rewardPoints: 70
    },
];


function calculatePersonalImprovementPoints(myAction){
    const coreGrowthPointValue = actionsAndTheirRewards.find(item => item.action === myAction);  
    
    if(!coreGrowthPointValue){
      alert("Invalid Action!");
      return;
    }
    
    const {multiplier} = getMultiplier(randomInRange(1,100));
    const PIPoints = coreGrowthPointValue.rewardPoints*multiplier;

    return PIPoints;
}


export {calculatePersonalImprovementPoints};



				
// LX Score dashboard 1 (scale chart)		Hovers over the tool tip 1	20	Infinite
// LX Score dashboard 1 (XL scale chart)		Spend more than 60 sec tooltip 1	10	10 times only
// LX Score dashboard 1 (LX scale chart)		Hovers over the tool tip 2	20	Infinite
// LX Score dashboard 1 (LX scale chart)		Spend more than 60 sec tooltip 2	10	10 times only
// LX Score dashboard 1 (LX scale chart)		Clicks Industry benchmarks	30	Infinite
// LX Score dashboard 1 (LX scale chart)		Clicks Personal range	40	Infinite
// LX Score dashboard 1 (LX scale chart)		Clicks Explore your score 1st time	50	Once
// LX Score dashboard 1 (LX scale chart)		Clicks Explore your score (not 1st time)	40	Infinite
// LX Score dashboard 1 (LX scale chart)		Clicks 'Your LX journey'	50	Infinite
// LX Score dashboard 1 (LX scale chart)		Clicks 'Drivers/Constraints over time'	60	Infinite
				
// LX Score dashboard 2 'Explore your score'		Hover over 'Your Drivers' tooltips	50	Infinite
// LX Score dashboard 2 'Explore your score'		Spend more the 60secs  'your drivers'	60	Infinite
// LX Score dashboard 2 'Explore your score'		Clicks 'Your Constraints'	50	Infinite
// LX Score dashboard 2 'Explore your score'		Hover more the 60secs  'Constraints' tooltips	60	Infinite
				
				
				
// LX Score dashboard  'LX Journey' graph		Clicks 'tooltips'	50	Infinite
				
// LX Score dashboard LX drivers/constraints graph		Clicks 'tooltips'	50	Infinite

// XL History and Events graph		User Adds a event in the History 	70	Infinite
