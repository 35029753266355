import { DOMAIN_NAME } from "../../constants";
import {fetchRequest} from "../../utils";




export const getAllPrePostSurveys = async (query) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/pre-post-survey/all?${query}`,"GET",null);

    return res;
}

export const createPriPostSurvey = async (data) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/pre-post-survey`,"POST",data);

    return res;
}




















