import React from 'react'

function ShadowBox({children,className,style,title="",onClick=()=>{},id}) {
  return (
    <div id={id} onClick={onClick} style={style} title={title} className={`bg-white/20 shadow-md rounded-md p-5 ${className}`}>
        {children}
    </div>
  )
}

export {ShadowBox}