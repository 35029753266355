import React, { useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { toast } from 'react-toastify';
import {useNavigate} from "react-router-dom";
import { 
  getNewUsersGroup,
  addUserToGroup,
  onBoardUser,
  updateUserActivities,
  updateLoggedInUser
} from "../../../Store/actions";
import {Loading} from "../../../components";
import {
  FinalMessage,
  UserInfo,
  SurveySelection
} from "./index";

function UserSurveySelection({isCommingFromPrePostSurvey,showOnlyUpdateActPage}) {
  const navigator = useNavigate();
  const loggedInUser = useSelector(state => state.loggedInUser);
  const dispatch = useDispatch();
  const [activities, setActivities] = useState([
    {text: `My work`,selected: false,surveyName: "work"},
    {text: `My Relationship`,selected: false,surveyName: "relationship"},
    {text: `My Hobby`,selected: false,surveyName: "hobby"},
    {text: `My Studies`,selected: false,surveyName: "study"},
    {text: `My Sport`,selected: false,surveyName: "sport"},
    {text: `My Business`,selected: false,surveyName: "business"}
  ]);  
  const selectedCount =  activities.filter(ac => ac.selected).length;
  const [sections, setSections] = useState({
    surveySelection: !showOnlyUpdateActPage,
    userInfo: showOnlyUpdateActPage,
    goal: false,
    surveyFrequencyAndTime: false,
    finalMessage: false
  });
  const selectedActivities = activities.filter(act => act.selected);
  const [info, setInfo] = useState({
        study: "",
        relationship: "",
        hobby: "",
        sport: "",
        business: "",
        work: ""
  });
  const [loading,setLoading] = useState(false);
  // const [surveyTiming, setSurveyTiming] = useState({
  //   isSameForAll: true,
  //   work: "",
  //   study: "",
  //   relationship: "",
  //   hobby: "",
  //   sport: "",
  //   business: ""
  // });
  // const [goalOptions, setGoalOptions] = useState({
  //   work: [
  //     {text: "Financial stability and earning a livelihood",selected: false},
  //     {text: "Increase personal satisfaction and fulfillment",selected: false},
  //     {text: "Build a sense of accomplishment and pride",selected: false},
  //     {text: "Acquire new skills and knowledge",selected: false},
  //     {text: "Improve professional development and career advancement",selected: false},
  //     {text: "Improve sense of purpose and meaning",selected: false},
  //     {text: "Achieve personal and financial goals",selected: false},
  //     {text: "Build a professional network and connections",selected: false},
  //     {text: "Making a positive impact on society",selected: false},
  //     {text: "Gaining new skills, knowledge, and experiences",selected: false},
  //     {text: "Provide for oneself and loved ones",selected: false},
  //     {text: "Build a positive reputation and personal brand",selected: false},
  //     {text: "Fulfill personal goals and aspirations",selected: false},
  //   ],
  //   study: [
  //       {text: "Enhance self-awareness and personal growth",selected: false},
  //       {text: "Improve self-confidence and self-esteem",selected: false},
  //       {text: "Improve critical thinking and problem-solving skills",selected: false},
  //       {text: "Career advancement and better job opportunities",selected: false},
  //       {text: "Achieve academic success and earning better grades",selected: false},
  //       {text: "Achieve a sense of accomplishment and satisfaction",selected: false},
  //       {text: "Acquire new skills and knowledge",selected: false},
  //       {text: "Gain new perspectives and broaden horizons",selected: false},
  //       {text: "Develop more responsibility and accountability",selected: false},
  //       {text: "Improve memory and retention",selected: false},
  //       {text: "Improve decision-making and self-discipline",selected: false},
  //       {text: "Make a positive impact on society",selected: false},
  //       {text: "Pursue personal interests and passions",selected: false},
  //       {text: "Prepare for exams and assessments",selected: false},
  //       {text: "Build a network of professional and personal connections",selected: false},
  //       {text: "Increase self-discipline and motivation",selected: false},
  //       {text: "Achieve financial stability and independence",selected: false},
  //       {text: "Overcome personal challenges and limitations",selected: false},
  //       {text: "Improve adaptability and resilience",selected: false},
  //       {text: "Improve health and overall well-being",selected: false},
  //       {text: "Find a real sense of purpose and direction in life",selected: false},
  //   ],
  //   relationship: [
  //       {text: "Love and affection",selected: false},
  //       {text: "Trust and honesty",selected: false},
  //       {text: "Honesty",selected: false},
  //       {text: "Loyalty",selected: false},
  //       {text: "Empathy",selected: false},
  //       {text: "Support",selected: false},
  //       {text: "Communication and understanding",selected: false},
  //       {text: "Compassion",selected: false},
  //       {text: "Respect",selected: false},
  //       {text: "shared Growth and self-improvement",selected: false},
  //       {text: "Emotional support",selected: false},
  //       {text: "Shared experiences and adventures",selected: false},
  //       {text: "Support and encouragement",selected: false},
  //       {text: "Companionship and intimacy",selected: false},
  //       {text: "Shared values and goals",selected: false},
  //       {text: "Shared interests",selected: false},
  //       {text: "Equality",selected: false},
  //       {text: "Open-mindedness and flexibility",selected: false},
  //       {text: "Forgiveness",selected: false},
  //   ],
  //   hobby: [
  //       {text: "Relaxation and stress relief",selected: false},
  //       {text: "Personal fulfillment and enjoyment",selected: false},
  //       {text: "Improved creativity and self-expression",selected: false},
  //       {text: "Improved cognitive abilities",selected: false},
  //       {text: "Increased knowledge and skills in the hobby",selected: false},
  //       {text: "Improved social connections and friendships",selected: false},
  //       {text: "Sense of purpose and meaning",selected: false},
  //       {text: "Increased self-confidence and self-esteem",selected: false},
  //       {text: "Development of new talents and interests",selected: false},
  //       {text: "Better mental health and well-being",selected: false},
  //       {text: "Opportunities for social interaction and connection",selected: false},
  //       {text: "Development of new interests and passions",selected: false}
  //   ],
  //   sport: [
  //       {text: "Improving physical fitness and health",selected: false},
  //       {text: "Achieving personal bests and setting new goals",selected: false},
  //       {text: "Building self-confidence and self-esteem",selected: false},
  //       {text: "Developing teamwork and sportsmanship skills",selected: false},
  //       {text: "Gaining a sense of discipline and structure",selected: false},
  //       {text: "Learning new skills and techniques",selected: false},
  //       {text: "Overcoming challenges and pushing personal limits",selected: false},
  //       {text: "Finding a positive outlet for stress and emotions",selected: false},
  //       {text: "Better mental health and reduced stress",selected: false},
  //       {text: "Increased endurance and strength",selected: false},
  //       {text: "Weight loss and improved body composition",selected: false},
  //       {text: "Improved coordination and balance",selected: false},
  //       {text: "Better sleep patterns",selected: false},
  //       {text: "Increased focus and concentration",selected: false},
  //       {text: "Personal satisfaction and a sense of accomplishment",selected: false},
  //   ],
  //   business: [
  //       {text: "Financial stability and independence",selected: false},
  //       {text: "Career fulfillment and personal satisfaction",selected: false},
  //       {text: "Making a positive impact on society",selected: false},
  //       {text: "Building a legacy and leaving a lasting impression",selected: false},
  //       {text: "Providing a secure future for oneself and loved ones",selected: false},
  //       {text: "Innovation and creating new solutions",selected: false},
  //       {text: "Being in control of one's own time and schedule",selected: false},
  //       {text: "Offering unique and valuable products or services",selected: false},
  //       {text: "Building a positive reputation and personal brand",selected: false},
  //       {text: "Pursuing personal interests and passions",selected: false},
  //       {text: "Achieving personal and professional growth",selected: false},
  //       {text: "Improving work-life balance",selected: false},
  //       {text: "Providing for oneself and loved ones",selected: false},
  //       {text: "Creating job opportunities for others",selected: false},
  //       {text: "Leaving a legacy and creating a positive impact for future generations",selected: false},
  //       {text: "Improve effective leadership and decision making",selected: false},
  //       {text: "Improve empathy and compassion for customers and employees",selected: false},
  //   ],
  // });
  // const [loading, setLoading] = useState(false);



  const handleSubmit = async ()=> {
    // setLoading(true);
    const surveyNames = selectedActivities.map(s => s.surveyName);
    const activities = [];
    const tempActivities = {};

    surveyNames.forEach(sn => {
      const activity = loggedInUser.activities.find(act => act.category === sn);

      activities.push({
        category: sn,
        name: info[sn],
        goal: activity?.goal || [],
        purpose: activity?.purpose || []
      });
      tempActivities[sn] = info[sn];
    })
  
    // const res = await getUniversalGroup();
    const res = await getNewUsersGroup();
    // console.log(data);
    const res1 =  await updateUserActivities(loggedInUser._id,activities);
    const temp = loggedInUser.activities.map(act => {
      const activitie = activities.find(act1 => act.category === act1.category);
      return {...act,...activitie};
    })
    dispatch(updateLoggedInUser({activities: temp}));

    if(res1.success && res1.allReadyOnBoarded){
      toast.success("Preference Updated Successfully");
      // navigator(`/purpose-and-goal`,{state: tempActivities});
      navigator(`/my-dashboard`,{state: tempActivities});
      return;
    }
  
    // console.log(res1);

    if(res1.success){

      let res2 = await addUserToGroup(res1.user._id,res.group._id);

      // console.log(res2);

      if(res2.success){
        const res3 = await onBoardUser(loggedInUser._id);

        // console.log(res3);
        if(res3.success){
          navigator(res3.link,{state: {isCommingFromPrePostSurvey}});
        }
        else {
          setLoading(false);
        }
      }
      else{
        // console.log("aa");
        setLoading(false);
      }

    }
    else {
      // console.log("aa");
      setLoading(false);
      toast.error(res1.message);
    }

  }

 
 
  return (
    <>
        {
          loading? <><Loading loadingText='Please wait we are saving your preferences...'/></>:<>

        {
            sections.surveySelection && <SurveySelection 
            activities={activities} 
            selectedCount={selectedCount}
            setActivities={setActivities}
            setSections={setSections}
            isCommingFromPrePostSurvey={isCommingFromPrePostSurvey}
            />
        }
        {
            sections.userInfo && <UserInfo
            selectedActivities={selectedActivities}
            setInfo={setInfo}
            info={info}
            setSections={setSections}
            isCommingFromPrePostSurvey={isCommingFromPrePostSurvey}
            handleSubmit={handleSubmit}
            setActivities={setActivities}
            />
        }
        {
            sections.finalMessage && <FinalMessage
            handleSubmit={handleSubmit}
            />
        }
        {
            // sections.goal && <Goals
            // setSections={setSections}
            // selectedActivities={selectedActivities}
            // goalOptions={goalOptions}
            // setGoalOptions={setGoalOptions}
            // info={info}
            // handleSubmit={handleSubmit}
            // />
        }
        {
            // sections.surveyFrequencyAndTime && <SurveyFrequencyAndTime
            // setSections={setSections}
            // selectedActivities={selectedActivities}
            // surveyTiming={surveyTiming}
            // setSurveyTiming={setSurveyTiming}
            // />
        }
        {
            // sections.surveyFrequencyAndTime && <SurveyFrequencyAndTime
            // setSections={setSections}
            // selectedActivities={selectedActivities}
            // surveyTiming={surveyTiming}
            // setSurveyTiming={setSurveyTiming}
            // />
        }
          </>
        }
    </>
  )
}

export default UserSurveySelection;









