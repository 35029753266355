import React, { useEffect, useState } from "react";
import { 
  useLocation, 
  useNavigate, useParams } from "react-router-dom";
import { 
  getSurveyTitleByUserIdAndSurveyId,
  createSurvey,
  setPersonalImprovementPoints,
  updateLoggedInUser,
  getUniversalSurveyTitle,
  getUserNotifications
 } from "../../Store/actions";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { 
  SET_SURVEY_USER,
  COMPLETE_SURVEY,
 } from "../../constants";
import {PrimaryButton, SecondryButton,SurveyUI} from "../../components";
import {
  PurposeAndGoalStatement,
  Logo,
  // FirstSurveyScreen
} from "./components";
import {Main} from "../../layout";
import { firstLetterUpperCase } from "../../utils";
import { useScrollToTop } from "../../hooks";



function Index() {
  const location = useLocation();
  const isCommingFromPrePostSurvey = location?.state?.isCommingFromPrePostSurvey;
  const urlParams = new URLSearchParams(window.location.search);
  const surveyName = urlParams.get("surveyName");
  const title = urlParams.get("title");
  const surveyLinkId = urlParams.get("surveyLinkId");
  const newSurvey = urlParams.get("new");
  const isPreviousQuestions = urlParams.get("isPreviousQuestions");
  const isNonCogniPulse = urlParams.get("isNonCogniPulse");
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { userId, surveyId } = useParams();
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [user, setUser] = useState(null);
  const [isDisplayLogo, setIsDisplayLogo] = useState(true);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const loggedInUser = useSelector(state => state.loggedInUser);
  // const [firstSurveyPreScreenSeen, setFirstSurveyPreScreenSeen] = useState(()=> (loggedInUser.isCreatedByAdmin && !loggedInUser.hasCompletedFirstSurvey)? false:true);
  const isGoalAndPurpose = (loggedInUser.activities.find(act => act.category === surveyName)?.goal.length && loggedInUser.activities.find(act => act.category === surveyName))? true:false;
  const [purposeAndGoalStatementSeen, setpurposeAndGoalStatementSeen] = useState(()=> isGoalAndPurpose? false:true);
  const [surveyQuestionInstruction, setSurveyQuestionInstruction] = useState(null);
  const [inThePastMinutes, setInThePastMinutes] = useState(null);
  const [pastTimeCompleted, setPastTimeCompleted] = useState(false);
  const [surveyTitle, setSurveyTitle] = useState(null);
  const [numberOfNotifications, setNumberOfNotifications] = useState(null);
  const [numberOfCompletedSurveys, setNumberOfCompletedSurveys] = useState(null);
  const [numberOfCompletedImpactSurveys, setNumberOfCompletedImpactSurveys] = useState(null);
  useScrollToTop();

  
  
  const handleSubmit = async (lastAns,lastFactor,starttime) => {
        setIsSubmiting(true);
        const preparFinalSurveyAnswers = () => {
            const myAnswers = [...answers,{
              starttime,
              endtime: new Date().getTime(),
              rating: lastAns,
              XLFactor: lastFactor
            }];

            let data = {};

            myAnswers.forEach((answer) => {
              let temp = {
                starttime: answer.starttime,
                endtime: answer.endtime,
                rating: answer.rating,
              };
              data[answer.questiontype || answer.XLFactor] = temp;
            });

            if(!isNonCogniPulse && (!data.Fun || !data.Purpose || !data.Growth || !data.Emotives || !data.Utility || !data.Apathy)){
              console.log(data);
              return null;
            }
            if(isNonCogniPulse && !inThePastMinutes){
              console.log(data);
              return null;
            }

            data.isconsentformseen = true;
            data.isbriefingformseen = true;
            data.userId = userId;
            data.surveyTitleId = surveyId;
            data.link = window.location.href;
            data.surveyName = surveyName;
            data.isNewSurvey = newSurvey;
            data.surveyPeriod = inThePastMinutes;
            if(surveyLinkId){
              data.surveyLinkId = surveyLinkId;
            }

            if (window.location.hostname === "localhost") {
              data.link = window.location.href.replace(
                "localhost:3000",
                "localhost:4000"
              );
            }

            return data;
        };

        // let universalSurveyTitleId = null;
        
        // if(isNonCogniPulse === 'true'){
        //   const universalSurveyTitleRes = await getUniversalSurveyTitle();
        //   if(universalSurveyTitleRes.success){
        //     universalSurveyTitleId = universalSurveyTitleRes.surveytitle._id;
        //   }
        // }
      
        const data = preparFinalSurveyAnswers();
       
        if(!data){
            setIsSubmiting(false);
            console.log("Fun Purpose Growth Emotives Utility Apathy are required!");
            toast.error("Something went wrong!");
            return;
        }
        
        const res = await createSurvey(data);
        
        if (res.success) {
           if(!user.hasCompletedFirstSurvey){
              dispatch(updateLoggedInUser({hasCompletedFirstSurvey: true}));
           }
          
            setTimeout(() => {
              dispatch(setPersonalImprovementPoints(userId,COMPLETE_SURVEY));
            }, 7000);

            
            if(!loggedInUser.isPostSurveyDone && loggedInUser.postSurveyDate && new Date(loggedInUser.postSurveyDate) < new Date()){
              const url = `/pre-post-survey/${loggedInUser._id}/${loggedInUser.postSurveyId}`;
              navigator(url,{state: {isPostSurvey: true}});
              return;
            }

            const isCogniPulse = isNonCogniPulse === "true"? false:true;

            navigator(`/my-dashboard`, { state: {
              category: title,
              surveyId,
              surveyName,
              isNonCogniPulse: isCogniPulse? false:true,
              loading: isCogniPulse,
              numberOfNotifications,
              numberOfCompletedSurveys: isCogniPulse? numberOfCompletedSurveys+1:numberOfCompletedSurveys,
              numberOfCompletedImpactSurveys: isCogniPulse? numberOfCompletedImpactSurveys:numberOfCompletedImpactSurveys+1,
              isCogniPuseChanged: isCogniPulse? true:false,
              isImPuseChanged: isCogniPulse? false:true,
            }});
            return;
        } 
        else {
          toast.error(res.message);
        }

        setIsSubmiting(false);
  }

  useEffect(() => {
    (async () => {
      const res = await getSurveyTitleByUserIdAndSurveyId(userId, surveyId,isPreviousQuestions);
      // console.log(res);
      if (res.success) {
        dispatch({ type: SET_SURVEY_USER, payload: res.user });
        const url = window.location.href;
        const isCompleted = res.user.surveysinfo.find((si) => {
            let link1 = si.link.trim().split("/survey")[1];
            link1 = link1?.replaceAll("%20"," ");
            let link2 = url.trim().split("/survey")[1];
            link2 = link2?.replaceAll("%20"," ");
            if(si.iscompleted === true && link1 === link2){
              return si;
            }
            else{
              return null;
            }
          }
        );


        if (isCompleted) {
          navigator(`/my-dashboard`, { state: surveyId });
        }

        let shuffled = res.surveyTitle.questions.map((a) => ({sort: Math.random(), value: a}))
                                                .sort((a, b) => a.sort - b.sort)
                                                .map((a) => a.value);
                                                
        shuffled = shuffled.map(ques => {
          let question = `${ques.question}`;
          question = question?.replace("[insert survey subject]", surveyName);
          question = question?.replace("[insert user input name/title]", title);
          question = question?.replace("study", "Studies");
          return {...ques,question};
        })                                             
        setQuestions(shuffled);
        console.log(shuffled);
        setUser(res.user);
        if(res.surveyTitle.surveyquestioninstruction.isvisible){
          setSurveyQuestionInstruction(res.surveyTitle.surveyquestioninstruction.instruction);
        }
        setSurveyTitle(res.surveyTitle);

        setNumberOfCompletedSurveys(res.surveysDoneToday);
        setNumberOfCompletedImpactSurveys(res.numberOfCompletedImpactSurvey);
      }
    })();
  }, [userId, surveyId, navigator, dispatch,surveyName,title,isPreviousQuestions]);

  useEffect(() => {
    (async ()=>{
      const res = await getUserNotifications();
      if(res.success){
          const numberOfNotifications = res.notifications
          .reduce((a,item) => {
            return a = [...a,...item.days]
           },[])
          .filter(item => item.day === new Date().getDay())
          .reduce((a,item) => {
            return a = [...a,...item.times]
          },[]).length;

          setNumberOfNotifications(numberOfNotifications);
      }
    })()
  }, []);

  if(isDisplayLogo){
    return <Logo setIsDisplayLogo={setIsDisplayLogo}/>
  }

  if(!purposeAndGoalStatementSeen && !isNonCogniPulse){
    return <PurposeAndGoalStatement 
            currentCategory={surveyName}
            currentName={title}
            user={user} 
            isCommingFromPrePostSurvey={isCommingFromPrePostSurvey}
            setpurposeAndGoalStatementSeen={setpurposeAndGoalStatementSeen}
            />
  }

  if(isNonCogniPulse && !pastTimeCompleted){
    return <NonCogniPulseTime userId={userId} surveyName={surveyName} title={title} surveyTitle={surveyTitle} setPastTimeCompleted={setPastTimeCompleted} inThePastMinutes={inThePastMinutes} setInThePastMinutes={setInThePastMinutes}/>
  }
 

  return (
    <>
      <Main>
          <div className="h-[20px]"/>
          <p className="text-center mb-4 text-secondry-color font-bold select-none">{!inThePastMinutes? `CogniPulse`:`${surveyTitle.title}`}</p>
          {
            inThePastMinutes && <h6 className="text-center mb-6 text-secondry-color font-bold">In the past {inThePastMinutes} minutes</h6>
          }

          <SurveyUI handleSubmit={handleSubmit} surveyQuestionInstruction={surveyQuestionInstruction} isSubmiting={isSubmiting} questions={questions} answers={answers} setAnswers={setAnswers}/> 
      </Main>
    </>
  );
}

export default Index;



function NonCogniPulseTime({userId,surveyName,title,setPastTimeCompleted,inThePastMinutes,setInThePastMinutes}){
  const navigator = useNavigate();
  const [value, setValue] = useState(null);
  const array = [
    {
      text: "5 Minutes",
      minute: 5
    },
    {
      text: "10 Minutes",
      minute: 10
    },
    {
      text: "20 Minutes",
      minute: 20
    },
    {
      text: "30 Minutes",
      minute: 30
    },
    {
      text: "40 Minutes",
      minute: 40
    },
    {
      text: "50 Minutes",
      minute: 50
    },
    {
      text: "1 hour",
      minute: 60
    },
  ];
  

  const [times] = useState(array);


  const handleChange = (e) => {
    setValue(e.target.value);
    setInThePastMinutes(Number(e.target.value));
  }

  const handleSkip = async () => {
      const universalSurveyTitleRes = await getUniversalSurveyTitle();
      if(universalSurveyTitleRes.success){
        const universalSurveyTitleId = universalSurveyTitleRes.surveyTitle._id;
        navigator(`/`);
        navigator(`/survey/${userId}/${universalSurveyTitleId}/${new Date().getTime()}?surveyName=${surveyName}&title=${title}&new=true`);
        setInThePastMinutes(null);;
      }
      else {
        toast.error("Something Went Wrong!");
      }
  }

  return (
    <Main>

      <h1 className="pt-10 md:text-center text-[24px] md:text-[30px] font-bold text-primary-button-color">Capture your creative and productive impact on your {firstLetterUpperCase(title)} {firstLetterUpperCase(surveyName)}</h1>

      <h3 className="md:text-center pt-2 mb-8 md:mb-14 text-secondry-color text-[18px]">Select the time you were working on this activity (up to this moment)</h3>

      <div className="max-w-3xl mx-auto flex flex-wrap gap-2 md:justify-center mt-4">
        {
          times.map((item,idx) => (
            <button onClick={()=>{setInThePastMinutes(item.minute);setValue(null)}} className={`border border-secondry-color/20 shadow-md rounded p-2 cursor-pointer ${(inThePastMinutes === item.minute && !value)? "bg-accent-color text-white font-bold":""}`} title="click to select/deselect" key={idx}>{item.text}</button>
          ))
        }
      </div>
      <div className="flex flex-col w-full md:w-[300px] mx-auto mt-8">
          <label htmlFor="inThePastMinutes" className="text-secondry-color font-bold text-center">Enter Custom Time (in Minutes)</label>
          <input 
            value={value === null?  "":value} 
            onChange={handleChange} 
            id="inThePastMinutes" 
            placeholder="Enter time in minutes, e.g., 25mins"  
            type="number" 
            className="p-2 rounded-md bg-secondry-color/10"
          />
      </div>

      <div className="flex justify-center mt-12 gap-5 w-[500px] max-w-full mx-auto">
          <PrimaryButton style={{padding: "12px 0"}} onClick={()=>handleSkip()}>
            Skip
          </PrimaryButton>
          <SecondryButton style={{padding: "12px 0"}} onClick={()=>setPastTimeCompleted(true)} disabled={inThePastMinutes?false:true}>
            Next
          </SecondryButton>
      </div>
    </Main>
  )
}




