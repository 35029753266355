import moment from "moment";
import {
  OPQCalculator
} from "./index";

function groupDatesByWeek(allSurveysWithXLScores,dateAccessor,XLScoreAccessor) {
    const dates = allSurveysWithXLScores.map(xl => {return {...xl,createdAt: xl[dateAccessor]}});
    let lastWeekStart = moment(dates[dates.length-1][dateAccessor]).startOf('week').format('DD-MM-YYYY');
    let lastDate = moment(dates[dates.length-1][dateAccessor]).format('DD-MM-YYYY');
  
    const groupedDates = dates.reduce((acc, xl,index) => {
      let startOfWeek = moment(xl[dateAccessor]).endOf('week').subtract(1,"day").valueOf();
  
      if(lastWeekStart === startOfWeek){
        startOfWeek = lastDate;
      }
  
      if (!acc[startOfWeek]) {
        acc[startOfWeek] = [];
      }
  
      acc[startOfWeek].push({createdAt: xl[dateAccessor],XLScore: xl[XLScoreAccessor]});
  
      return acc;
    }, {});
  
    return groupedDates;
};

function allSurveysWithXLScoresToHistoricalSPQ(allSurveysWithXLScores,dateAccessor,XLScoreAccessor) {

    dateAccessor = dateAccessor || "createdAt";
    XLScoreAccessor = XLScoreAccessor || "XLScore";

    const datesByWeek = groupDatesByWeek(allSurveysWithXLScores,dateAccessor,XLScoreAccessor);
    const result = [];
  
    for (const key in datesByWeek) {
      if(datesByWeek[key].length > 1){
        const OPQ = OPQCalculator(datesByWeek[key]);
        result.push({OPQ: isNaN(OPQ)? 0:OPQ, date: Number(key)});
      }
    }
  
    if(result.length === 0){
      return [];
    }

    result.sort((a,b) => a.date-b.date);
  
    result.unshift({OPQ: result[0].OPQ, date: new Date(allSurveysWithXLScores[0][dateAccessor]).getTime()});
  
    return result;
}

export {allSurveysWithXLScoresToHistoricalSPQ};