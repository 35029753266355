import { toast } from "react-toastify";
import { DOMAIN_NAME } from "../../constants";
import {fetchRequest} from "../../utils";



export const getAllTagsOfUser = async (userId) =>{
    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/tag/user/${userId}`,"GET",{});

        return res;
    } catch (error) {
        toast.error(error.message);
    }
}


export const getTagById = async (tagId) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/tag/${tagId}`,"GET",{});

    return res;
}


export const createTag = async (data) => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/tag`,"POST",data);

        return res;

    } catch (error) {
        toast.error(error.message);
    }
}


export const updateTag = async (data,tagId) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/tag/${tagId}`,"PUT",data);

    return res;
}


export const deleteTag = async (tagId) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/tag/${tagId}`,"DELETE",{});

    return res;
}



