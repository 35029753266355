import React,{useEffect,useState} from "react";
import { toast } from "react-toastify";
import {
  includeExcludeSurveys
} from "../../../Store/actions";
import {questions} from "../Index";
import { Td, Tr } from "../../../components";


function CompletedSurvey({ survey, index,group }) {


  const [isExcluded, setIsExcluded] = useState(false);

  const onIsExcludedChange = async (id)=>{
    const res = await includeExcludeSurveys(id,{ isexcluded: !isExcluded})
    
    if(res.success){
      toast.success(res.message);
      setIsExcluded(!isExcluded);
    }
  }


  const calcullateTotalDuration = (survey)=> {
    let totalTime = 0;

    for (const ans of survey.answers) {
        totalTime += (ans.endtime - ans.starttime); 
    }
 
    return parseFloat((totalTime/1000)/60).toFixed(2) + " Mins";
  }

  const mapRange =  (value, inMin, inMax, outMin, outMax)=> {
    const ans = ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    return ans.toFixed(2)
  }

  useEffect(() => {
    if(survey.isexcluded){
      setIsExcluded(true)
    }
    else{
      setIsExcluded(false)
    }
  }, [setIsExcluded,survey.isexcluded]);




  return (

    <Tr>
      <Td className="pl-4">{index + 1}</Td>

      <Td>{survey?.user?.forename}{` `}{survey?.user?.surname}</Td>

      <Td className="text-muted">{survey?.user?.email}</Td>

      <Td className="text-muted">org</Td>

      <Td className="text-muted">dept</Td>
 
      <Td>
        <select
          className="form-control category-select"
          id="exampleFormControlSelect1"
        >
          <option>{group?.groupname}</option>
        </select>
      </Td>
    
      <Td className="text-muted">{survey.surveyName ? `${survey?.surveytitle?.title} ${survey.surveyName}`:survey?.surveytitle?.title}</Td>

      <Td className="text-muted">{new Date(survey?.createdAt).toLocaleString()}</Td>
 
      <Td className="text-muted">{calcullateTotalDuration(survey)}</Td>

      {
        questions.map(num => {
          const ans = survey.answers.find(an => Number(an.XLFactor) === num);
          if(!ans){
            return <Td key={num}>NA</Td>
          }
          return <Td key={ans.XLFactor}>
            <span className="text-muted">
              {mapRange(ans.rating,1,7,0,100)}
            </span>
          </Td>
        })
      }
   
      <Td className="text-muted"><input type='checkbox'onChange={()=>onIsExcludedChange(survey?._id)} checked={isExcluded}  className='exclude-btn' value={isExcluded}/></Td>
    </Tr>
  );
}

export default CompletedSurvey;
