import { DOMAIN_NAME } from "../../constants";
import {fetchRequest} from "../../utils";



export const getAllEventsOfUser = async (userId) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/event/all/${userId}`,"GET",{});

    return res;
}

export const getAllEvents = async () => {

    const res = await fetchRequest(`${DOMAIN_NAME}/event/all`,"GET",{});

    return res;
}


export const getEventById = async (id) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/event/one/${id}`,"GET",{});

    return res;
}


export const createEvent = async (data) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/event/create`,"POST",data);

    return res;
}


export const updateEvent = async (data,id) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/event/${id}`,"PUT",data);

    return res;
}


export const deleteEvent = async (id) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/event/${id}`,"DELETE",{});

    return res;
}



// management 
export const getAllManagementEventsOfUser = async (userId) => {
    const res = await fetchRequest(`${DOMAIN_NAME}/event/management/${userId}`,"GET");
    return res;
}


export const getTodaysEvents = async () => {
    const res = await fetchRequest(`${DOMAIN_NAME}/event/todays`,"GET");
    return res;
}