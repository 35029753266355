import moment from "moment";
// import {capitalized} from "./index";

function formatGoal(arr){

    if(!arr){
        return "Not Set";
    }
    if(arr.length === 0){
        return "Not Set";
    }

    const newArry = arr.map((item,i) => i !== 5? item:moment(item).format("DD-MM-YYYY"));

    return newArry.join(" ");
};


export {formatGoal};