import React from 'react';
import { Main } from "../layout";
import { PrimaryButton } from '../components';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function PrivacyPolicy() {
    const previousRoute = useSelector(state=> state.globalStates.previousRoute);
    const navigation = useNavigate();

    const handleBack = () => {
        console.log(previousRoute);
        navigation(previousRoute);
    }

  return (
    <Main>
            <div className='w-[700px] max-w-full px-4 mx-auto py-10'>
                <h1 class="text-xl font-bold mb-2">Privacy Policy</h1>

                <p className='mb-6'>Effective Date: 18/5/2024</p>

                <h2 class="text-xl font-bold mb-2">Introduction</h2>

                <p class="mb-4">Welcome to XLRate, a personal growth platform designed for visionaries and high-performance professionals. This Privacy Notice explains how we collect, use, disclose, and safeguard your information when you use our platform. Your privacy is important to us, and we are committed to protecting your personal data</p>

                <h2 class="text-xl font-bold mb-2">Information We Collect</h2>

                <ul class="list-disc list-inside mb-4 ml-6">
                    <li className='mb-3'><span className='font-bold'>Personal Data</span> We collect information that you voluntarily provide to us, including your name, email address, and organization name. Additionally, we collect psychometric data and text input about your psychological state to analyze and provide intuitive visual metrics</li>
                    <li className='mb-3'><span className='font-bold'>Usage Data</span> We collect information about your interactions with our platform, such as the pages you visit, the time and date of your visits, and the features you use. This data helps us understand how you use the platform and improve our services</li>
                    <li className='mb-3'><span className='font-bold'>Device Data</span> We collect information about the device you use to access our platform, including your IP address, browser type, operating system, and device identifiers</li>
                    <li><span className='font-bold'>Cookies and Tracking Technologies</span> We use cookies and similar tracking technologies to track activity on our platform and hold certain information. For more details, please refer to our [Cookie Policy]</li>
                </ul>

                <h2 class="text-xl font-bold mb-2">How We Use Your Information</h2>

                <p class="mb-4">
                    We use the information we collect for various purposes, including:
                </p>

                <ul class="list-disc list-inside mb-4 ml-6">
                    <li className='mb-3'><span className='font-bold'>Providing and Improving Services</span> To provide, operate, and maintain our platform, and to improve and personalize your experience</li>
                    <li className='mb-3'><span className='font-bold'>Analytics </span> To analyze usage and performance of our platform, and to develop new features and services</li>
                    <li className='mb-3'><span className='font-bold'>Communications </span> To communicate with you about your account, updates, and other information related to our services</li>
                    <li className='mb-3'><span className='font-bold'>Security </span> To detect, prevent, and address technical issues and to safeguard your information</li>
                    <li className='mb-3'><span className='font-bold'>Automated Decision-Making </span> Automated Decision-Making: To provide personalized recommendations and insights based on automated data analysis. You have the right to object to automated decision-making and request human intervention</li>
                </ul>

                <h2 class="text-xl font-bold mb-2">Legal Basis for Processingn</h2>

                <p class="mb-4">
                    We process your personal data based on the following legal grounds:
                </p>

                <ul class="list-disc list-inside mb-4 ml-6">
                    <li className='mb-3'><span className='font-bold'> Consent</span> You have given explicit consent for the processing of your personal data for one or more specific purposes</li>
                    <li className='mb-3'><span className='font-bold'>Contract </span> Processing is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into a contract</li>
                    <li className='mb-3'><span className='font-bold'>Legal Obligations </span> Processing is necessary for compliance with a legal obligation to which we are subject</li>
                    <li className='mb-3'><span className='font-bold'>Legitimate Interests</span> Processing is necessary for the purposes of the legitimate interests pursued by us or a third party, except where such interests are overridden by your fundamental rights and freedoms</li>
                </ul>

                <h2 class="text-xl font-bold mb-2">Data Retention</h2>
                <p className='mb-6'>We retain your personal data for as long as your account is active or as needed to provide you with services. We will retain and use your data to comply with our legal obligations, resolve disputes, and enforce our agreements</p>
               
                <h2 class="text-xl font-bold mb-2">Data Security</h2>
                <p className='mb-6'>We take the security of your personal data seriously and implement appropriate technical and organizational measures to protect it. These measures include encryption, secure data transfers, and restricted access controls. However, no method of transmission over the Internet or method of electronic storage is 100% secure</p>
                
                <h2 class="text-xl font-bold mb-2">Your Data Protection Rights</h2>
                <p className='mb-6'>You have the following rights regarding your personal data</p>
                <ul class="list-disc list-inside mb-4 ml-6">
                    <li className='mb-3'><span className='font-bold'> Access</span> You have the right to request copies of your personal data</li>
                    <li className='mb-3'><span className='font-bold'> Rectification</span> You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete</li>
                    <li className='mb-3'><span className='font-bold'> Erasure</span> You have the right to request that we erase your personal data, under certain conditions</li>
                    <li className='mb-3'><span className='font-bold'> Restrict Processing</span> You have the right to request that we restrict the processing of your personal data, under certain conditions</li>
                    <li className='mb-3'><span className='font-bold'> Data Portability</span> You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions</li>
                    <li className='mb-3'><span className='font-bold'> Withdraw Consent</span> You have the right to withdraw your consent at any time, without affecting the lawfulness of processing based on consent before its withdrawal</li>
                </ul>
                <p className='mb-6'>To exercise any of these rights, please contact us at DPO@xlrate.10x.systems. We will respond to your request within one month</p>
                
                <h2 class="text-xl font-bold mb-2">Third-Party Access</h2>
                <p className='mb-6'>We do not share your personal data with any third parties without your explicit consent, except as required by law or to protect our rights</p>


                <h2 class="text-xl font-bold mb-2"> International Data Transfers</h2>
                <p className='mb-6'>If we transfer your personal data to countries outside the European Economic Area (EEA), we will ensure that appropriate safeguards are in place to protect your data. This includes using standard contractual clauses approved by the European Commission and ensuring the third party complies with GDPR standards</p>


                <h2 class="text-xl font-bold mb-2"> Age Restrictions</h2>
                <p className='mb-6'>You must be at least 16 years old to use XLRate. If you are under 16, you must have obtained parental consent for the use of your personal data. We may request proof of parental consent for users under 16</p>


                <h2 class="text-xl font-bold mb-2">Changes to This Privacy Notice</h2>
                <p className='mb-6'>We may update this Privacy Notice from time to time. We will notify you of any changes by posting the new Privacy Notice on this page and updating the "Effective Date" at the top. You are advised to review this Privacy Notice periodically for any changes. Changes to this Privacy Notice are effective when they are posted on this page</p>
                
                <h2 class="text-xl font-bold mb-2">Complaints</h2>
                <p className='mb-6'>If you believe your data protection rights have been violated, you have the right to lodge a complaint with a supervisory authority. In the UK, this is the Information Commissioner's Office (ICO)</p>


                <h2 class="text-xl font-bold mb-2"> Contact Us</h2>
                <p className='mb-2'>If you have any questions about this Privacy Notice or our data practices, please contact us at </p>
                <p className='mb-2'>Data Protection Officer (DPO)</p>
                <p className='mb-6'>Email: DPO@xlrate.10x.systems</p>
                <p className='mb-2'>By using XLRate, you acknowledge that you have read and understood this Privacy Notice and agree to our collection, use, and processing of your personal data as described</p>

                <PrimaryButton onClick={handleBack} style={{marginTop: "40px"}}>Back</PrimaryButton>
            </div>
    </Main>
  )
}

export {PrivacyPolicy};