import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
    Header,
    Wrapper 
} from "./index";
import { PrimaryButton } from "../../../components";
import { 
    updateLoggedInUser,
} from "../../../Store/actions";
import { Main } from "../../../layout";
import { useScrollToTop } from "../../../hooks";



function EnterActivityTitle({setScreen}){

    const loggedInUser = useSelector(state => state.loggedInUser);
    const [activities, setActivities] = useState(loggedInUser.activities);
    const dispatch = useDispatch();
    useScrollToTop();
    const placeHolder = "Enter activity name";

    const handleBack = () => {
        setScreen(prev => {
            return {
                ...prev,
                enterActivityTitle: false,
                personalImprovementObstacles: true
            }
        })
    }
    
    const handleChange = (e) => {
        const category = e.target.name;
        setActivities(prev => {
            return prev.map(act => {
                if(act.category === category){
                    return {
                        ...act,
                        name: e.target.value
                    }
                }
                return act;
            })
        });
    }

    const giveInfo = (act,idx) => {
            switch (act.category) {
                case "work":
                    return <Field key={idx} preText={"My"} postText={"Work"} value={act.name} name={act.category} onChange={handleChange} placeHolder={placeHolder} />

                case "relationship":
                    return <Field key={idx} preText={"My relationship with"} value={act.name} name={act.category} onChange={handleChange} placeHolder={placeHolder} postText={""}/>
   
                case "hobby":
                    return <Field key={idx} preText={"My"} value={act.name} name={act.category} onChange={handleChange} placeHolder={placeHolder} postText={"Hobby"}/>

                case "study":
                    return <Field key={idx} preText={"My"} value={act.name} name={act.category} onChange={handleChange} placeHolder={placeHolder} postText={"Study"}/>

                case "sport":
                    return <Field key={idx} preText={"My"} value={act.name} name={act.category} onChange={handleChange} placeHolder={placeHolder} postText={"Sport"}/>

                case "business":
                    return <Field key={idx} preText={"My"} value={act.name} name={act.category} onChange={handleChange} placeHolder={placeHolder} postText={"Business"}/>

                default:
                    break;
            }
    }

    const handleNext = async () => {
        

        if(loggedInUser.organisationName){
            setScreen(prev => {
                return {
                    ...prev,
                    enterActivityTitle: false,
                    organisationName: false,
                    baiseline: true
                }
            });
            dispatch(updateLoggedInUser({isEnterActivityTitleSeen: true,activities}));
        }
        else {
            setScreen(prev => {
                return {
                    ...prev,
                    enterActivityTitle: false,
                    organisationName: true
                }
            }); 
            dispatch(updateLoggedInUser({isEnterActivityTitleSeen: true,activities}));
        }

    }


    return (<>
         <Main>
            <Wrapper>
                {/* <ProgressBarAndBackButton handleBack={handleBack} completedSteps={5}/> */}
                <Header handleBack={handleBack} title="Personalise"/>
                <div className="max-h-[69vh] overflow-auto">
                    <p className='pb-10 text-xl text-secondry-color font-bold'>Enter the specific titles or names of the things you want track and improve.</p>
                
                    {/* activities */}
                    <div className='flex flex-col gap-10  mb-10'>
                        {
                            activities.map((act,idx) => {
                                return giveInfo(act,idx) 
                            })
                        }
                    </div>
                </div>
                {/* next btn  */}
                <div className="">
                    <PrimaryButton style={{padding: "12px 0"}} onClick={handleNext}>
                        {"Next"}
                    </PrimaryButton>
                </div>
            </Wrapper>
         </Main>
    </>)
}


export {EnterActivityTitle};





function Field({preText,postText,value,name,onChange,placeHolder}){

    return (<>
        <div className="text-secondry-color flex justify-start items-center gap-3">
            <span className="font-bold">{preText}</span> 
            <input className="flex-1 bg-secondry-color/20 rounded-md px-4 py-2" type="text" value={value} name={name} onChange={onChange} placeholder={placeHolder}/> 
            <span className="font-bold">{postText}</span>
        </div>
    </>)
}

