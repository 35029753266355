import {USER_LOGIN,USER_RESET} from "../../constants";

const defaultState = {
  login: false,
  details: null
}

function user(state = defaultState, action) {

    switch (action.type) {

      case USER_LOGIN:
        return {
          ...state,
          login: true,
          ...action.payload
        }
      case USER_RESET:
        return {
          ...state,
          login: false,
          details: null,
        }
 
      default:
        return state
    }
  }


  export default user