import { Link } from "react-router-dom";
import { tempImg } from "../../../assets";
import {FullView} from "./index";




function PeakStateActionChallenges({clickedOn,user,currentActivity,setIsOpen}) {
    const ACTIVITY = user.activities.find(act => {return act.category === currentActivity? act:null});

  
    return (
        <>
        <FullView setIsOpen={setIsOpen} title={`My ${ACTIVITY?.name} ${ACTIVITY?.category}`}>
            <div style={{display: ``}} id="peakStateActionChallenges" className="flex flex-col justify-center">
                <h2 className="text-2xl md:text-6xl md:text-center font-bold text-primary-button-color my-10">Actions to enter {clickedOn}</h2>
                        
                <div className="relative w-full min-h-[250px]  mx-auto md:my-10 border-[3px] rounded-md border-accent-color">
                            <div className="w-full h-full flex justify-center items-center blur-sm">
                                <img src={tempImg} alt="temp" className="w-full h-full"/>
                            </div>
                            <div className="absolute top-0 left-0 w-full h-full z-[10]">
                                <div className="w-full h-full flex justify-center items-center">
                                        <div className="p-2 md:p-8 bg-white/60">
                                            <h2 className="text-sm md:text-lg font-bold text-center text-accent-color leading-6 md:leading-12"> 
                                                Additional data is required to unlock your bespoke action map to this Flow state. 
                                                We will notify you when your unique Cognitive Blueprint for Peak Performance is ready to generate these insights. 
                                                Remember to set your 
                                                <Link to="/manage/notifications" className="text-primary-button-color mx-2 underline">CogniPulse notifications</Link> to your schedule. 
                                                Each pulse brings you closer to unlocking your personalised insights to peak performance states.
                                            </h2>
                                        </div>
                                </div>
                            </div>
                </div>
            </div>
        </FullView>
      </>
    )
}
  

export { PeakStateActionChallenges };
  
  