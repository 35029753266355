function percentageInRange(start, end, value) {
    return ((value - start) / (end - start)) * 100;
}


export {percentageInRange};


function valueAtPercentage(start, end, percentage) {
    return start + ((end - start) * (percentage / 100));
}

export {valueAtPercentage}