import { Factors ,InfoIconWithToolTip} from "../../../components";
import { ActionChallenges } from "./ActionChallenges";
import { XLFactorsTooltipText } from "./constants";
import {FullView} from "./index";




function InstantInsights({secVis,higestDiffrance,currentActionChallengesWithSteps,setSecVis,isAnimation,user,currentActivity,factorsMinMax,percentageOfChangeOfFactors,scoreOfAllfactors,setIsOpen}) {
    const ACTIVITY = user.activities.find(act => {return act.category === currentActivity? act:null});
  
    return (
      <FullView setIsOpen={setIsOpen} title={`My ${ACTIVITY?.name} ${ACTIVITY?.category}`}>
            <h2 className="text-center text-secondry-color font-bold text-xl md:text-2xl my-10">Your Cognitive Potential: Instant Insights & Actions</h2>
            <div className="w-full flex flex-col gap-8 md:flex-row md:jsutify-center md:gap-14 pb-10">
                <div className="w-full md:w-[50%] flex justify-center md:justify-end">
                                <div className="w-[315px]">
                                    <h4 className="title flex justify-center gap-2 items-center font-bold mb-2 text-secondry-color" ><span>XL factors</span><InfoIconWithToolTip content={XLFactorsTooltipText}/></h4>
                                    <Factors 
                                        Fun={scoreOfAllfactors?.Fun}
                                        Purpose={scoreOfAllfactors?.Purpose}
                                        Growth={scoreOfAllfactors?.Growth}
                                        Emotives={Math.abs(scoreOfAllfactors?.Emotives)}
                                        Utility={Math.abs(scoreOfAllfactors?.Utility)}
                                        Apathy={Math.abs(scoreOfAllfactors?.Apathy)}
                                        factorsMinMax={factorsMinMax}
                                        percentageOfChangeOfFactors={percentageOfChangeOfFactors}
                                        border={true}
                                        mixPanelEvents={""}
                                        conrainerId="introSelector10"
                                        driversId="introSelector11"
                                        constrainersId="introSelector12"
                                    />
                                </div>
                </div>
                <div className="w-full md:w-[50%] flex justify-center md:justify-start md:items-start">
                                <div className="w-[315px] md:mt-[70px]">
                                    {
                                        <ActionChallenges 
                                            higestDiffrance={higestDiffrance} 
                                            currentActionChallengesWithSteps={currentActionChallengesWithSteps} 
                                            user={user}
                                            setSecVis={setSecVis}
                                            isACAnimation={isAnimation}
                                        />
                                    } 
                                </div>
                </div>
            </div>
      </FullView>
    )
}
  

export { InstantInsights }
  
  