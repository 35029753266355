import {
    SET_NUMBER_OF_ACTION_CHALLENGES
} from "../../constants";




export const setNumberOfActionChallenges = (numberOfActionChallenges) => async (dispatch) => {

    try {
          dispatch({type: SET_NUMBER_OF_ACTION_CHALLENGES ,payload: numberOfActionChallenges});

    } catch (error) {
    }
}