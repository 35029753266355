function CenterContent({children}){


    return (<>
        <div className='w-full h-[calc(100vh-64px)] flex justify-center items-center'>
            <div className='w-full p-6 rounded-lg shadow-lg  md:mt-0 sm:max-w-md sm:p-8'>
                {children}
            </div>
        </div>
    </>)
}


export {CenterContent}; 