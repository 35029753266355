import confettiRiseSource from './confettiRise.mp3';
import dashboardXLScoreAnimationPoweringUpSource from './dashboardXLScoreAnimationPoweringUp.mp3';
// import cogniPulseSource from './cogniPulse.mp3';
import cogniPulseSource1 from './cogniPulse1.mp3';
import cogniPulseSource2 from './cogniPulse2.mp3';
import cogniPulseSource3 from './cogniPulse3.mp3';


export const confettiRise = new Audio(confettiRiseSource);
export const dashboardXLScoreAnimationPoweringUp = new Audio(dashboardXLScoreAnimationPoweringUpSource);
// export const cogniPulse = new Audio(cogniPulseSource);
export const cogniPulse1 = new Audio(cogniPulseSource1);
export const cogniPulse2 = new Audio(cogniPulseSource2);
export const cogniPulse3 = new Audio(cogniPulseSource3);