import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    // AllowNotifications, 
    EnterActivityTitle, 
    FinalProjection, 
    Intro, 
    PersonalImprovementGoals, 
    PersonalImprovementObstacles, 
    Welcome,
    SetOrganisationName,
    SetYourBaseline
} from "./components";


function Index() {
    const loggedInUser = useSelector(state => state.loggedInUser);
    // const activitieWithNoName = loggedInUser.activities.find(act => !act.name);
    const [screensVisibility,setScreensVisibility] = useState({
        intro: !loggedInUser.isIntroSeen,
        personalImprovementGoals: !loggedInUser.isPersonalImprovementGoalsSeen,
        personalImprovementObstacles: !loggedInUser.isPersonalImprovementObstaclesSeen,
        enterActivityTitle: !loggedInUser.isEnterActivityTitleSeen,
        organisationName: loggedInUser.organizationName? false:true,
        welcome: !loggedInUser.isWelcomeSeen,
        baiseline: !loggedInUser.isPreSurveyDone,
        finalProjection: !loggedInUser.isFinalProjectionSeen,
    });



    if(screensVisibility.intro){
        return <Intro setScreen={setScreensVisibility}/>;
    }

    if(screensVisibility.personalImprovementGoals){
        return <PersonalImprovementGoals setScreen={setScreensVisibility}/>
    }

    if(screensVisibility.personalImprovementObstacles){
        return <PersonalImprovementObstacles setScreen={setScreensVisibility}/>
    }

    // if(isShowAllowNotificationsPage){
    //     return <AllowNotifications setIsShow={setIsShowAllowNotificationsPage}/>;
    // }

    if(screensVisibility.enterActivityTitle){
        return <EnterActivityTitle setScreen={setScreensVisibility}/>
    }

    if(screensVisibility.organisationName){
        return <SetOrganisationName setScreen={setScreensVisibility}/>;
    }
    
    if(screensVisibility.baiseline){
        return <SetYourBaseline setScreen={setScreensVisibility}/>;
    }

    if(screensVisibility.welcome){
        return <Welcome setScreen={setScreensVisibility}/>
    }

    return <FinalProjection setScreen={setScreensVisibility}/>
};


export default Index;


 








