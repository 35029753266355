export const USER_LOGIN = "USER_LOGIN";
export const USER_RESET = "USER_RESET";
export const SET_USERS = "SET_USERS";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const ASSIGN_REMOVE_USER_FROM_GROUP = "ASSIGN_REMOVE_USER_FROM_GROUP";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_ACTIVITIES = "UPDATE_ACTIVITIES";
export const UPDATE_LOGIN_USER = "UPDATE_LOGIN_USER";


// SET_NUMBER_OF_ACTION_CHALLENGES
export const SET_NUMBER_OF_ACTION_CHALLENGES = "SET_NUMBER_OF_ACTION_CHALLENGES";

// SURVEY 
export const SET_SURVEYS = "SET_SURVEYS";


// GROUP 
export const SET_GROUPS = "SET_GROUPS";
export const ADD_GROUP = "ADD_GROUP";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const DELETE_GROUP = "DELETE_GROUP";
export const SET_COMPLETED_SUVRYES = "SET_COMPLETED_SUVRYES";


// SURVEY TITLE 
export const SET_SURVEY_TITLES = "SET_SURVEY_TITLES";
export const ADD_SURVEY_TITLES = "ADD_SURVEY_TITLES";
export const UPDATE_SURVEY_TITLES = "UPDATE_SURVEY_TITLES";
export const DELETE_SURVEY_TITLES = "DELETE_SURVEY_TITLES";




// GLOBAL STATES 
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const SET_IS_UPDATE = "TOGGLE_IS_UPDATE";
export const SET_SURVEY_USER = "SET_SURVEY_USER";
export const RESET_SET_SURVEY_USER = "RESET_SET_SURVEY_USER";
export const SET_LOADING = "SET_LOADING";
export const RESET_LOADING = "RESET_LOADING";
export const SET_BADGE = "SET_BADGE_SHOW";
export const SET_PERSOANL_IMPROVEMENT_POINTS = "SET_PERSOANL_IMPROVEMENT_POINTS";
export const SET_PERSOANL_IMPROVEMENT_LEVEL = "SET_PERSOANL_IMPROVEMENT_LEVEL";
export const SET_IMPROVEMENT_STREAK = "SET_IMPROVEMENT_STREAK";
export const TOGGLE_SHOW_USER_NAMES = "TOGGLE_SHOW_USER_NAMES";
export const SET_SURVEY_NAME = "SET_SURVEY_NAME";
export const UPDATE_IMPROVEMENT_STREAK = "UPDATE_IMPROVEMENT_STREAK";
export const SET_MENU_OPEN = "SET_MENU_OPEN";
export const SET_CHALLENGE = "SET_CHALLENGE";
export const SET_PREVIOUS_ROUTE = "SET_PREVIOUS_ROUTE";




// SCHEDULED TIMES 
export const GET_SCHEDULE_TIMES = "GET_SCHEDULE_TIMES";
export const ADD_SCHEDULE_TIME = "ADD_SCHEDULE_TIME";
export const UPDATE_SCHEDULE_TIME = "UPDATE_SCHEDULE_TIME";
export const DELETE_SCHEDULE_TIME = "DELETE_SCHEDULE_TIME";


// TAGS 
export const SET_TAGS = "SET_TAGS";
export const CREATE_TAG = "CREATE_TAG";
export const UPDATE_TAG = "UPDATE_TAG";
export const DELETE_TAG = "DELETE_TAG";



