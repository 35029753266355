import React from 'react';
import {formatPurpose} from "../../../utils/index";
import { PrimaryButton, SecondryButton } from '../../../components';
import { CenterContent } from '../../../layout';



function SecondPage({purpose,setVisivility,currentCategory,currentName}) {
  


  
  return (
    <>
      <CenterContent>
        <h3 className='text-center font-bold text-secondry-color'>My purpose for my {currentName} {currentCategory}</h3>
        <p className='text-center mt-5 text-secondry-color'>{formatPurpose(purpose)}</p>
        <div className="flex justify-center mt-5 gap-10">
          <PrimaryButton style={{padding: "12px 0"}} onClick={()=>{setVisivility({firstPage:true,secondPage: false})}} >Back</PrimaryButton>
          <SecondryButton style={{padding: "12px 0"}} onClick={()=>{setVisivility({secondPage: false,thirdPage:true})}} >Next</SecondryButton>
        </div>
      </CenterContent>
    </>
  )
}

export default SecondPage;