import React, { useState } from 'react';
import {FactorBar} from "./FactorBar";
// import InfoIcon from '../InfoIcon/InfoIcon';
import {FACTOR_COLORS} from "../../constants";
// import {ReactToolTip} from "../index";
import { InfoIconWithToolTip, PrimaryButton } from "../index";


const driversInfo = "These factors drive the performance of the activity being measured, they range from 0 to 100 and higher scores indicate higher performance potential."
const constrainersInfo = "These factors constrain the performance of the activity being measured, even though they may not be directly connected to it. They range from 0 to -100, and lower scores indicate lower performance potential.";


const factors = {
  drivers: [
    {
      factor: "Fun",
      bg: FACTOR_COLORS["FUN"],
      toolTip: `This indicates how much you are driven by the activity* itself (i.e. when you do in an activity simply because you enjoy it)`
    },
    {
      factor: "Purpose",
      bg: FACTOR_COLORS["PURPOSE"],
      toolTip: `This indicates how much you are driven by the value and impact of  activity* whether you enjoy it or not!`
    },
    {
      factor: "Growth",
      bg: FACTOR_COLORS["GROWTH"],
      toolTip: `This indicates how much you are driven by the self-development value of the activity*`
    }
  ],
  constrainers: [
    {
      factor: "Emotives",
      bg: FACTOR_COLORS["EMOTIVES"],
      toolTip: `This indicates how much you do the activity* because of emotional pressure/s (i.e. guilt, peer pressure, fear, etc.) not because you enjoy or value the activity itself.`
    },
    {
      factor: "Utility",
      bg: FACTOR_COLORS["UTILITY"],
      toolTip: `This indicates how much you do the activity* to gain a reward, or avoid a punishment.`
    },
    {
      factor: "Apathy",
      bg: FACTOR_COLORS["APATHY"],
      toolTip: `A high score here suggests you do not have a strong reason why you're doing the activity*  and tends to result in feelings of suppression, detachment and apathy about the activity.`
    }
  ]
} 



function Factors({Fun,Purpose,Growth,Emotives,Utility,Apathy,factorsMinMax,percentageOfChangeOfFactors}) {
  const [showRange,setShowRange] = useState(false);
  const [showChange,setShowChange] = useState(false);
  
  return (
    <>
        <div className="flex flex-col gap-2 justify-center items-streach w-[100%]">
          <div className="flex justify-around mt-4">
            <PrimaryButton onClick={()=> setShowRange(p=>!p)} style={{fontSize: "12px",width: "100px",padding: "0px 0px"}}>{showRange? "Hide":"Show"} Range</PrimaryButton>
            <PrimaryButton onClick={()=> setShowChange(p=>!p)} style={{fontSize: "12px",width: "100px",padding: "0px 0px"}}>{showChange? "Hide":"Show"} Change</PrimaryButton>
          </div>
          <div className="flex flex-col gap-2">
            <Drivers showChange={showChange} percentageOfChangeOfFactors={percentageOfChangeOfFactors} factorsMinMax={factorsMinMax} Fun={Fun} Purpose={Purpose} Growth={Growth} showRange={showRange}/>
            <Constrainers showChange={showChange} percentageOfChangeOfFactors={percentageOfChangeOfFactors} factorsMinMax={factorsMinMax} Emotives={Emotives} Utility={Utility} Apathy={Apathy} showRange={showRange}/>
          </div>
        </div>
    </>
  )
}

export default Factors;


function Drivers({Fun,Purpose,Growth,showRange,factorsMinMax,percentageOfChangeOfFactors,showChange}){
  const facs = [Fun,Purpose,Growth];
  const margin = (factor) => {
    return factor.factor === "Fun"? "12px":factor.factor === "Purpose"? "7px":"";
  }

  return <>
          <div className="flex flex-col items-center border-4 border-secondry-color rounded p-2">
            <p className='relative z-[1] text-center mb-0 font-bold pb-2 flex justify-center items-center gap-2 text-accent-color'>
              <span>Drivers</span> 
              <InfoIconWithToolTip content={driversInfo}/>
            </p>
            <div className="flex justify-around w-full">
              {
                  factors.drivers.map((factor,idx) => {
                    const change = percentageOfChangeOfFactors?  percentageOfChangeOfFactors[factor.factor]:0;
                    return <div id={`introSelector${factor.factor}Drivers`} style={{marginLeft: margin(factor)}} key={idx} className="flex flex-col items-center">
                            <div className="flex justify-center gap-9 w-full">
                                <div key={factor.toolTip} className='text-xs flex items-center gap-1 text-secondry-color relative z-[1]'>
                                  <span>{factor.factor}</span> 
                                  <InfoIconWithToolTip content={factor.toolTip}/>
                                </div>
                            </div>
                            <div className='w-[100%] flex justify-center gap-[60px] p-2'>
                              <Factor showChange={showChange} showRange={showRange} toolTip={factor.toolTip} key={factor.factor} factor={factor.factor} value={facs[idx]} bg={factor.bg} isConstrainers={false} min={factorsMinMax.min[factor.factor]} max={factorsMinMax.max[factor.factor]} change={change}/>
                            </div>
                          </div>
                  })
              }
            </div>
          </div>
  </>
}


function Constrainers({Emotives,Utility,Apathy,showRange,factorsMinMax,percentageOfChangeOfFactors,showChange}) {

  const facs = [Emotives,Utility,Apathy];

  return <>
          <div  className="flex flex-col items-center border-4 border-secondry-color rounded p-2">
            
            <div className="flex justify-around w-full">
              {
                  factors.constrainers.map((factor,idx) => {
                    const change = percentageOfChangeOfFactors?  percentageOfChangeOfFactors[factor.factor]:0;
                    return <div id={`introSelector${factor.factor}Constrainers`} key={idx} className="flex flex-col items-center">
                            <div className='w-[100%] flex justify-center gap-[60px] p-2'>
                              <Factor showChange={showChange} showRange={showRange} toolTip={factor.toolTip} key={factor.factor} factor={factor.factor} value={facs[idx]} bg={factor.bg} isConstrainers={true} min={factorsMinMax.min[factor.factor]} max={factorsMinMax.max[factor.factor]} change={change}/>
                            </div>
                            <div className="flex justify-center gap-9 w-full">
                                <div key={factor.toolTip} className='relative z-[1] text-xs flex items-center gap-1 text-secondry-color'>
                                  <span>{factor.factor}</span> 
                                  <InfoIconWithToolTip content={factor.toolTip}/>
                                </div>
                            </div>
                          </div>
                  })
              }
            </div>
            
            <p className='text-center mb-0 font-bold pt-2 flex justify-center items-center gap-2 text-accent-color'><span>Constrainers</span> <InfoIconWithToolTip content={constrainersInfo}/></p>
          </div>
  </>
}


function Factor({value,bg,isConstrainers,showRange,min,max,change,showChange}){


  return <>
    <div className="flex flex-col items-center justify-center gap-2">
        {/* {
          !isConstrainers && <div className="flex  items-center gap-1 text-xs justify-center">
                                  <span>{factor}</span> 
                                  <span id={factor} data-tip={toolTip}>
                                    <InfoIcon/>
                                  </span>
                              </div>
        } */}
        
        <div className="h-[134px]">
          <FactorBar
            value={value}
            isReverse={isConstrainers}
            bg={bg}
            showRange={showRange}
            min={min}
            max={max}
            percentageOfchange={change}
            showChange={showChange}
          />
        </div>

        {/* {
          isConstrainers && <div className="flex items-center gap-1 text-xs justify-center">
                                  <span>{factor}</span> 
                                  <span id={factor} data-tip={infoTexts[0]}>
                                    <InfoIcon/>
                                  </span>
                              </div>
        } */}
    </div>
  </>
}



