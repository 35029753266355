import React from 'react';
import './index.css';
import {App} from './App';
import { Provider } from 'react-redux';
import store from "./Store/store";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./IntroJsCustomCSS.css";
import { createRoot } from 'react-dom/client'

const container = document.getElementById('root')!
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <ToastContainer position='bottom-center' autoClose={2000}/>
    </Provider>
  </React.StrictMode>
);
