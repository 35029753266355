import {
       SET_BADGE, 
       SET_IMPROVEMENT_STREAK, 
       SET_IS_UPDATE, 
       SET_MENU_OPEN, 
       SET_PERSOANL_IMPROVEMENT_POINTS, 
       TOGGLE_MODAL,
       DOMAIN_NAME
} from "../../constants";
import {fetchRequest} from "../../utils";
import {calculatePersonalImprovementPoints} from "../../utils";
import { SET_CHALLENGE, SET_PREVIOUS_ROUTE } from "../../constants/actions";



export const toggleModal = () => async (dispatch) =>{
       dispatch({type: TOGGLE_MODAL ,payload: ""});
}

export const setIsUpdate = (bool) => async (dispatch) =>{
       dispatch({type: SET_IS_UPDATE ,payload: bool});
}

export const setPersonalImprovementPoints = (userId,action) => async (dispatch) => {

       const personalImprovementPoints = calculatePersonalImprovementPoints(action);

       const res = await fetchRequest(`${DOMAIN_NAME}/user/growthpoints/${userId}`,"PUT",{personalImprovementPoints});

       console.log(res,action);

       if(res.success){
          dispatch({type: SET_PERSOANL_IMPROVEMENT_POINTS ,payload: res.personalImprovementPoints});
       }
       
}


export const setImprovementStreak = (IStreak) => async (dispatch) => {
       dispatch({type: SET_IMPROVEMENT_STREAK ,payload: IStreak});
}


export const setBadge = (text) => async (dispatch) => {
       
     dispatch({type: SET_BADGE ,payload: text});
     
     setTimeout(()=>{
            dispatch({type: SET_BADGE ,payload: ""});
     },3000);
}


export const setIsMenuOpen = (bool) => async (dispatch) => {
       // console.log(bool);
       dispatch({type: SET_MENU_OPEN ,payload: bool});

}


export const setChallenge = (bool) => async (dispatch) => {
       // console.log(bool);
       dispatch({type: SET_CHALLENGE ,payload: bool});

}

export const setPreviousRoute = (route) => async (dispatch) => {
       // console.log(bool);
       dispatch({type: SET_PREVIOUS_ROUTE ,payload: route});
}






