import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {getNewUsersGroup} from "../../../Store/actions";

function SurveySelection({activities,setActivities,selectedCount,setSections,isCommingFromPrePostSurvey}){

    const loggedInUser = useSelector(state => state.loggedInUser);
    const isWorkingProfessional = loggedInUser?.profession === "working professional"?true:false;
    const isStudent = loggedInUser?.profession === "student"?true:false;
    const [isFromNewUserGroup, setIsFromNewUserGroup] = useState();
    

    const handleSelect = (index)=> {  
      const act =  activities.find((a,i)=> i === index);

      if((isFromNewUserGroup || isCommingFromPrePostSurvey) && act.surveyName !== "work"){
        toast.error(`During the trial phase, you can't add personal activities to track.`);
        return;
      }
     
      if(act.selected || selectedCount < 2){
          setActivities(prev => {
              return prev.map((act,i) => {
                if(act.surveyName === "study" && isStudent){
                    return act
                }
                if(act.surveyName === "work" && isWorkingProfessional){
                    return act;
                }
                if(i === index){
                   return {...act,selected: !act.selected}
                }
                else {
                   return act
                }
              });
          })
      }
      else {
          toast.error("You only can select two")
      }
      
    }


    useEffect(()=>{
        setActivities(prev => {
            return prev.map((act,i) => {
              if(act.surveyName === "study" && isStudent){
                  return {...act,selected: true}
              }
              if(act.surveyName === "work" && isWorkingProfessional){
                  return {...act,selected: true};
              }

              const found = loggedInUser.activities?.find(item => item.category === act.surveyName);
              if(found){
                return {...act,selected: true};
              }
              return act;
            });
        })
    },[isStudent,isWorkingProfessional,setActivities,loggedInUser.activities]);


    const handleActNext = () => {
      setSections(prev =>{
          return{
              ...prev,
              surveySelection:false,
              userInfo: true
          }
      })
    }  

    useEffect(() => {
       (async ()=> {
        const res = await getNewUsersGroup();
        if(res.success){
            const isInNewUserGroup = res.group?.users.find(id => id === loggedInUser?._id);

            if(isInNewUserGroup){
                setIsFromNewUserGroup(true);
            }
        }
       })()
    }, [loggedInUser]);

  return (<>
      <h1 className='text-center pt-10'>XLrate</h1>
      <p className='text-center'>Choose a personal activity you want to track and improve (i.e. different from the pre-selected one)</p>
      {
        isCommingFromPrePostSurvey && <p className="pb-10 text-center">(NB. You will only have access to the pre-selected activity for the trial)</p>
      }
      
      {/* activities */}
      <div className='flex justify-center gap-10 flex-wrap'>
          {
              activities.map((act,i) => {
                  return <button className={`py-2 px-5 text-white ${act.selected? "bg-blue-500":"bg-blue-500/50"} rounded-xl`} onClick={()=>handleSelect(i)} key={i}>{act.text}</button>
              })
          }
      </div>
      {/* next btn  */}
      <div className='flex justify-center mt-20'>
          {
              selectedCount > 0? <>
              <button onClick={handleActNext} className={`text-white bg-green-600 py-2 px-5 rounded-xl`}>Next</button>
              </>:<>
              <button className={`text-white bg-green-600/40 py-2 px-5 rounded-xl`}>Next</button>
              </>
          }
      </div>
  
  </>)
}


export default SurveySelection;