import React from "react";
import ReactLoading from 'react-loading';


const Loader = ({loadingText = "Loading..."}) => {
  return (
    <>
    <div className="flex flex-col justify-center items-center w-[100%] h-[100%] absolute top-0 left-0 ">
      <ReactLoading type="bars" color="#000" height="50px" width="30px" />
      <h2 className="text-center">{loadingText}</h2>
    </div>
    </>
  );
};

export default Loader;