import React,{useState,useEffect} from 'react';

import {
  createEvent,
  updateEvent,
  deleteEvent,
  createTag,
  getAllTagsOfUser,
  setPersonalImprovementPoints,
  getUniversalSurveyTitle,
  deleteTag
} from "../../Store/actions";
import { toast } from 'react-toastify';
import {formetDateToFormInputType} from "../../utils";
import { useSelector,useDispatch } from 'react-redux';
import { ADD_EVENT } from '../../constants';
import {PrimaryButton, SecondryButton} from "../index.js";
import { InfoIconWithToolTip } from "../index.js";
import { BsPlus } from "react-icons/bs";
import {PopupContainer} from "../../layout";
import moment from 'moment';

function EventModal({setIsPhySelfieChanged,cancelBtnText,isManagemaneEvent,surveyTitle,data,eventId,isUpdate,setEvents,isOpen,setIsOpen,userId,currentSurveyName}) {
        const dispatch = useDispatch();
        const loggedInUser = useSelector(state => state.loggedInUser);
        const surveyName = useSelector(state => state.globalStates.surveyName);
        const [universalSurveyTitle, setUniversalSurveyTitle] = useState(null);
        const surveyUser = useSelector(state => state.globalStates.surveyUser);
        const user = loggedInUser || surveyUser;
        const [isUserTagsLoaded,setIsUserTagsLoaded] = useState(false);


        const [tags, setTags] = useState(
          [
            {tag: "Relationships",selected: false},
            {tag: "Professional",selected: false},
            {tag: "Teams Meeting",selected: false},
            {tag: "Personal",selected: false},
            {tag: "WFH",selected: false},
          ]
        );
        const [newTag, setNewTag] = useState('');
        const [eventData, setEventData] = useState({
          date: "",
          tags: [],
          description: "",
          surveytitle: "",
          surveyName: ""
        });
        const [selectedEmojies, setSelectedEmojies] = useState([]); 
        
        const emojies = [
          {emoji: "😀", name: "Happy"},
          {emoji: "😔", name: "Pensive"},
          {emoji: "😳", name: "Embarrassed"},
          {emoji: "😫", name: "Overwhelmed"},
          {emoji: "😟", name: "Unhappy"},
          {emoji: "😎", name: "In the Zone"},
          {emoji: "😊", name: "Positive"},
          {emoji: "🥰", name: "Loved"},
          {emoji: "😧", name: "Worried"},
          {emoji: "😠", name: "Angry"},
          {emoji: "🤬", name: "Very angry"},
          {emoji: "​​😕", name: "Uncertain"},
          {emoji: "​😲​", name: "Surprised"},
          {emoji: "😔​​", name: "Sad"},
          {emoji: "🥱", name: "Tired"},
          {emoji: "😎", name: "Cool"},
          {emoji: "😐", name: "Ok-ish"},
          {emoji: "🥳", name: "Excited"},
          {emoji: "💪", name: "Motivated"},
          {emoji: "😫", name: "Stressed"},
          {emoji: "🥺", name: "Lonely"},
          {emoji: "🙏", name: "Grateful"},
          {emoji: "😲", name: "Surprised"},
          {emoji: "😤", name: "Frustrated"},
          {emoji: "😰", name: "Anxious"},
          {emoji: "🧐", name: "Deep Thought"},
          {emoji: "🌊", name: "In Flow"}
        ]

        const handdleTagSelect = (tag)=>{
            setTags(prev => {
                return prev.map(t => t.tag === tag? {...t,selected: !t.selected}:t);
            })
        
        }

        const handdleTagChange = (e)=> {
            e.preventDefault();
            setNewTag(e.target.value);
        }

        const handdleEventInputChange = (e)=> {
            if(e.target.id === "description"){
              // const len = e.target.value.split("\n").length;
              // console.log(len);
              // const height = 29*len;
              // e.target.style.height = `${height < 64? 64:height}px`; 
              e.target.style.height = 'auto';
              e.target.style.height = `${e.target.scrollHeight}px`;
              const textarea = e.target;
              textarea.scrollTop = textarea.scrollHeight;
            }
            // console.log(e.target)
            setEventData((prev)=>{
                return {
                    ...prev,
                    [e.target.name]: e.target.value
                }
            })
        }

        const handdleAddTag = async (e)=> {
          e.preventDefault();
          const res = await createTag({user: user._id,tag: newTag });
          if(res.success){
            setTags(prev => {
              return [...prev,{tag: newTag,selected: false,_id: res.tag._id}]
            });
            setNewTag("")
          }
          else {
            toast.error("someting went wrong");
          }
        }

        const handdleAddEventSubmit = async (e) =>{
            e.preventDefault();
          
            if(!universalSurveyTitle){
              return;
            }
            const t = tags.filter(tag => tag.selected);
            const selectedTags = t.map(t => t.tag);
            const data = {
              ...eventData,
              surveytitle: isManagemaneEvent? surveyTitle:universalSurveyTitle?._id,
              tags: selectedTags,
              user: loggedInUser._id || userId,
              isManagementEvent: isManagemaneEvent? true:false,
              surveyName: surveyName || user?.surveyPreference?.surveys[0],
              feelings: selectedEmojies,
              date: eventData.date || moment().format("YYYYY-MM-DD")
            }
            

            if(!data.date){
              toast.error("Date is required");
              setIsOpen(true);
              return;
            }

            if(isUpdate){
              const res = await updateEvent(data,eventId);
              // console.log(res);
              if(res.success){
                // toast.success(res.message);
                setEvents(prev => {
                  return prev.map(ev => ev._id === eventId? res.event:ev)
                })
              }
              setIsOpen(false);
              return;
            }

            if(!data.surveytitle){
              delete data.surveytitle
              // toast.error("Survey Title is Required");
              // setIsOpen(true);
              // return;
            }

            // data
            const res = await createEvent(data);
            console.log(res);

            if(res.success){
              // toast.success(res.message);
              if(setEvents){
                setEvents(prev => {
                  return [...prev,res.event]
                })
              }
              
              setTimeout(() => {
                dispatch(setPersonalImprovementPoints(userId,ADD_EVENT));
              }, 3000);
              
              setIsOpen(false);

              if(setIsPhySelfieChanged){
                setIsPhySelfieChanged(true);
              }
            }
            else{
              toast.error(res.message);
            }
            
        } 

        const handdleDeleteEvent = async (e) => {
          e.preventDefault();
          const res = await deleteEvent(eventId);
          if(res.success){
            setIsOpen(false);
            setEvents(prev => {
              return prev.filter(ev => ev._id !== eventId);
            })
          }
          toast.success(res.message);
        }

        const handleEmojiSelect = (emojiName) => {
          setSelectedEmojies(prev => {
            const emoji =  prev.find(emoji => emoji === emojiName);
            if(emoji){
              return prev.filter(em => em !== emoji);
            }
            return [...prev,emojiName];
          })
        }

        const handleDeleteTag = async (tagId) => {
          const isSure = window.confirm("Are You Sure!");

          if(!isSure){
            return;
          }

          const res = await deleteTag(tagId);
          if(res.success){
            toast.success("Tag Deleted Successfully!");
            setTags(prev => {
              return prev.filter(tag => tag._id !== tagId);
            })
          }
          else {
            toast.error(res.message);
          }
        }

        useEffect(() => {
          if(isUpdate){
            console.log(data);
            setEventData({
              ...data,
              date: moment(data.date).format("YYYY-MM-DDTHH:mm")
            });

            setTags(prev => {
              const temp = prev.map(t => data.tags.indexOf(t.tag) !== -1? {...t,selected: true}:{...t,selected: false});
              const customTags = data.tags.map(t => {return {tag:t,selected:true}});
              const tempTags = {};
              const finalTags = [];
              [...temp,...customTags].forEach(t => {
                if(!tempTags[t.tag]){
                  tempTags[t.tag] = t.tag;
                  finalTags.push(t);
                }
              })
              return finalTags;
            })
          }
          if(!isUpdate){
            setEventData({
              date: "",
              tags: [],
              description: ""
            });
          
            setTags(prev => {
              return prev.map(t => {return{...t,selected: false}});
            })
          }
        }, [isUpdate,data]);

        useEffect(() => {
          setEventData(prev => {
            return {
              ...prev,
              date: formetDateToFormInputType(new Date().toISOString()) 
            }
          })
        }, []);

        useEffect(() => {
          (async ()=> {
            if(!isUserTagsLoaded){
              const res = await getAllTagsOfUser(userId);
              if(res.success){
                const tags = res.tags.map(tag => {return {...tag,selected: false}});
                setTags(prev => {
                  const uniqueTags = [];
                  const allTags = [...prev,...tags].filter(item => {
                      if(uniqueTags.indexOf(item.tag) === -1){
                          uniqueTags.push(item.tag);
                          return item;
                      }

                      return undefined;
                  });
                  return allTags;
                });
                setIsUserTagsLoaded(true);
              }
              else {
                toast.error("Failed to load your tags.");
              }
            }
          })()
        }, [dispatch,userId,isUserTagsLoaded]);
        
        useEffect(() => {
          (async ()=> {
            const res = await getUniversalSurveyTitle();
            if(res.success){
              setUniversalSurveyTitle(res.surveyTitle);
            }
          })()
        }, []);

        if(!isOpen){
          return null;
        }

        return (
          <PopupContainer>
              <form className=''>
                  <div className='flex justify-end'>
                    <CrossSVG onClick={()=>{setIsOpen(false)}}/>
                  </div>

                  <div className={`flex items-center ${isUpdate? "justify-between":"justify-center"} mb-4`}>
                      <h4 className='mb-0 flex justify-center items-center gap-2 text-secondry-color font-bold'>Snap a quick ‘Psy-Selfie’ before it's lost. <InfoIconWithToolTip content="It's like a psychological selfie. It will offer powerful and long-lasting insights that will guide you in boosting your mindset and personal growth. Take an instant snapshot now to preserve them before they vanish."/></h4>
                      {
                        isUpdate && <div className='flex justify-start'>
                        <PrimaryButton onClick={handdleDeleteEvent}  className="bg-red-600 shadow-none uppercase font-bold text-white px-3 py-2 py-1" >Delete</PrimaryButton>
                      </div>
                      }
                  </div>

                  <h6 className='font-bold text-gray-600 flex gap-2 items-center text-secondry-color mb-2'>How are you feeling right now? <InfoIconWithToolTip key="this" content="Select any emojis that reflect your current emotion. Your choice here helps to pinpoint your emotional state, providing a clearer picture of your mindset at this moment."/></h6>
                  
                  <div className="flex mb-6 gap-2 overflow-x-auto border border-secondry-color p-2 rounded-lg">
                      {
                        emojies.map((item,idx) => (
                          <>
                            {
                              selectedEmojies.indexOf(item.name) !== -1 &&  <div onClick={()=>handleEmojiSelect(item.name)} className="transition-all p-2 bg-accent-color rounded-md flex flex-col cursor-pointer select-none justify-center items-center pb-2" key={idx}>
                                                                              <span className='text-[30px] transition-all'>{item.emoji}</span>
                                                                              <span className={`text-white text-xs text-center text-nowrap transition-all`}>{item.name}</span>
                                                                            </div>
                            }
                            {
                              selectedEmojies.indexOf(item.name) === -1 &&  <div onClick={()=>handleEmojiSelect(item.name)} className="flex flex-col border px-1 rounded cursor-pointer select-none justify-center items-center pb-2" key={idx}>
                                                                              <span className='text-[40px]'>{item.emoji}</span>
                                                                              <span className={`"text-gray-400 text-sm text-center text-nowrap`}>{item.name}</span>
                                                                            </div>
                            }
                          </>
                        ))
                      }
                  </div>

                  <p className='mb-1 text-[16px] text-secondry-color font-bold items-center mb-4'><span className='mr-2'>Select or add your own tag to quickly capture what’s on your mind right now</span> <InfoIconWithToolTip content="Choose tags relevant to your current thoughts or create your own. Tagging makes it easier to identify patterns in what affects your well-being and performance over time. These moments are like dreams, easily forgotten if not immediately noted."/></p>
                  
                  <div className="tags-box flex items-center flex-wrap mb-3 gap-2">
                      {
                          tags.map((tag,i) => {
                              return <div className={`border border-secondry-color/20 cursor-pointer rounded-md flex  select-none ${tag.selected? "bg-secondry-color text-white":"text-gray-600"}`} key={i}>
                              <p onClick={(()=>handdleTagSelect(tag.tag))} className="border-r px-2 py-1 font-bold rounded-md text capitalize text-xs mb-0">{tag.tag}</p>
                              {
                                tag._id && <div onClick={()=>handleDeleteTag(tag._id)} className='flex items-center self-stretch bg-secondry-color/20 rounded-tr-md rounded-br-md'>
                                              <BsPlus title='Delete Tag' className='text-white rotate-45' />
                                           </div>
                              }
                            </div>
                          })
                      }
                  </div>

                  <div className="tags-input mb-6 flex gap-2 items-center">
                    <div className="form-group flex-1">
                      <input value={newTag} onChange={handdleTagChange} type="text" className="bg-secondry-color/20 w-full rounded-md px-2 py-2" id="tag-input" placeholder="Type your own tag.."/>
                    </div>
                    <div>
                      <PrimaryButton title="Add Tag" disabled={!newTag} onClick={handdleAddTag}>
                        + Add
                      </PrimaryButton>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className='mb-1 text-secondry-color font-bold flex gap-2 items-center'>Add some detail? <InfoIconWithToolTip content="The more detail you add, the deeper the insight you can gain into your emotional triggers and the influences on your daily well-being & performance. Again like dreams, these details are easily forgotten if not immediately noted."/></label>
                    <textarea onChange={handdleEventInputChange} value={eventData.description} name="description" id='description' className="bg-secondry-color/20 p-2 rounded-md w-full" placeholder="Enter details to truly capture the what you are experiencing."/>
                  </div>

                  <div className="mb-3">
                    <label className='mb-1 text-secondry-color font-bold flex gap-2 items-center'>When did this happened <InfoIconWithToolTip content="Today's date and time are auto-filled, but you can change them to when your experience actually happened, ensuring significant insights are captured, no matter when they occurred."/></label>
                    <input onChange={handdleEventInputChange} value={eventData.date || moment().format("YYYYY-MM-DD")} name="date" type="datetime-local" className="bg-secondry-color/20 w-full px-2 text-secondry-color rounded-md" id="date"/>
                  </div>

                  <div className="mt-10 flex justify-between gap-3">
                    <SecondryButton style={{padding: "12px 0"}} disabled={!universalSurveyTitle} onClick={handdleAddEventSubmit}>Capture</SecondryButton>
                  </div>
                  <div className="mt-10 flex justify-between gap-3 h-[64px]"/>
              </form>
          </PopupContainer>
        )
}

export {EventModal};



function CrossSVG({onClick}){

  return <>
  <svg title="Cancel" onClick={onClick} className='w-[15px] h-[15px] cursor-pointer' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 30 30">
      <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"></path>
  </svg>
  </>
}


