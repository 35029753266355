import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer className="mx-auto max-w-7xl p-4 bg-primary-color border-t border-secondry-color/20 shadow-2xl flex flex-col md:flex-row items-center justify-center md:justify-between">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © 2024 <a onClick={(e)=> e.preventDefault()} href="/" className="hover:underline" target="_blank">XLRate™</a>. All Rights Reserved.
        </span>
        <ul className="flex flex-wrap items-center gap-3 mt-2 sm:mt-0">
            <li>
                <Link to="/privacy-policy" className="text-sm text-gray-500 underline">Privacy Policy</Link>
            </li>
            <li>
                <Link to="/cookie-policy" className="text-sm text-gray-500 underline">Cookie Policy</Link>
            </li>
        </ul>
    </footer>
  )
}

export {Footer}