import React, { useState } from 'react';
import {updateLoggedInUser} from "../../../Store/actions";
import {useDispatch, useSelector} from "react-redux";
import {Loading, PrimaryButton, SecondryButton} from '../../../components';
import { useNavigate} from 'react-router-dom';
import {objToArr} from "./index";
import { formatGoal,formatPurpose } from '../../../utils';
import { CenterContent } from '../../../layout';


function ForthPage({activityesWithPurposeAndGoal,purpose,setActivityesWithPurposeAndGoal,setPurpose,setGoal,selectedActivityes,setCurrentActivityIndex,goal,currentName,currentCategory,setVisivility}) {
  
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const loggedInUser = useSelector(state => state.loggedInUser);
  const [loading] = useState(false);

  const handleContinue = async () => {
    
    setActivityesWithPurposeAndGoal(prev => {
      return [...prev,{
        category: currentCategory,
        name: currentName,
        purpose: objToArr(purpose),
        goal: objToArr(goal)
      }];
    })

    if(selectedActivityes[selectedActivityes.length-1].category === currentCategory){
      const activities = [...activityesWithPurposeAndGoal];
      
      if(activities.length !== selectedActivityes.length){
        activities.push({
          category: currentCategory,
          name: currentName,
          purpose: objToArr(purpose),
          goal: objToArr(goal)
        })
      }
      sendLink(activities);
      return;
    }
    else {
      setPurpose({});
      setGoal({});
      setCurrentActivityIndex(prev => prev+1);
      setVisivility({
        firstPage: true,
        secondPage: false,
        thirdPage: false,
        forthPage: false,
      })
    }
  }

  const handleBack = () => {
    setVisivility({thirdPage: true,forthPage: false,})
  }

  const sendLink = async (activities) => {

      dispatch(updateLoggedInUser(activities));


      const today = new Date();
            let redirectTo = undefined;
            const unCompletedSurveys = loggedInUser.surveysinfo.filter(survey => !survey.iscompleted);
            unCompletedSurveys.forEach(survey => {
                const link = survey.link.split("?")[0];
                const date = new Date(Number(link.split("/")[link.split("/").length-1]));
                if(today.getDate() === date.getDate() && today.getMonth() === date.getMonth() && today.getFullYear() === date.getFullYear()){
                    const arr = survey.link.split("/");
                    redirectTo = `/${arr.slice(3).join("/")}`
                }
            });
      
      if(redirectTo){
        navigator(redirectTo);
      }
      else {
        navigator(`/my-dashboard`);
      }      
  }

  return (
    <>
      <CenterContent>
          {
            loading && <Loading/>
          }
          {
            !loading && <>
              <h3 className='text-center font-bold text-secondry-color'>My purpose for my {currentName} {currentCategory}</h3>
              <p className='bg-secondry-color/20 text-secondry-color rounded-lg p-4 text-center mt-3'>
                {formatPurpose(purpose)}
              </p>
              <h3 className='text-center font-bold text-secondry-color mt-10'>My current 90 day goal for  {currentName} {currentCategory}</h3>
              <p className='bg-secondry-color/20 text-secondry-color rounded-lg p-4 text-center mt-3'>
                {formatGoal(objToArr(goal))}
              </p>
              <div className="flex justify-center mt-12 gap-2">
                <PrimaryButton style={{padding: "12px 0"}} onClick={handleBack}>Back</PrimaryButton>
                <SecondryButton style={{padding: "12px 0"}} onClick={handleContinue}>Next</SecondryButton>
              </div>
            </>
          }
      </CenterContent>
    </>
  )
}

export default ForthPage;