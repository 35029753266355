import {FACTOR_COLORS} from "../../../constants";
import {SecondryButton,InfoIconWithToolTip} from "../../../components";

const infoTexts = {
  Fun: `This indicates how much you are driven by the activity* itself (i.e. when you do in an activity simply because you enjoy it)`,
  Purpose: `This indicates how much you are driven by the value and impact of  activity* whether you enjoy it or not!`,
  Growth: `This indicates how much you are driven by the self-development value of the activity*`,
  Emotives: `This indicates how much you do the activity* because of emotional pressure/s (i.e. guilt, peer pressure, fear, etc.) not because you enjoy or value the activity itself.`,
  Utility: `This indicates how much you do the activity* to gain a reward, or avoid a punishment.`,
  Apathy: `A high score here suggests you do not have a strong reason why you're doing the activity*  and tends to result in feelings of suppression, detachment and apathy about the activity.`
}

const actionChallangeInfo = `These are some simple things you can action to improve the XL factor that will give you the greatest impact in terms of accessing the mental states for peak performance you want so improve & make your goals happen.`;


function ActionChallenge({currentActionChallanges,higestDiffrance,forTeam,mixPanelEvent,handleActionChallengeClick}) {

  const isDriver = (higestDiffrance.factor === "Fun" || higestDiffrance.factor === "Purpose" || higestDiffrance.factor === "Growth")? true:false;
  const color = FACTOR_COLORS[higestDiffrance.factor.toUpperCase()];

 
  


  return (
    <>
    <div onClick={mixPanelEvent} onMouseEnter={mixPanelEvent} className="pl-0 max-h-full">
            <h4 className="text-sm font-bold text-secondry-color">{forTeam? "Leadership Action Challenge":"Your Action Challenge"}</h4>
            <div className="action-challanges text-xs">

              <p className="text-secondry-color">
                It has been calculated that 
                {isDriver? <span className='font-bold mx-1'>increasing</span>:<span className='font-bold mx-1'>decreasing</span>} 
                <span className='rounded ml-1 px-2 text-white' style={{backgroundColor: `${color}`}}>{higestDiffrance.factor}</span> 
                <InfoIconWithToolTip className="mx-1" content={infoTexts[higestDiffrance.factor]}/>
                has the potential to create the greatest uplift {forTeam? "in the teams":"in your"} overall wellbeing and performance.
              </p>
             
              
              <p className='text-secondry-color mb-2'>
                Choose one of the actions below and see what impact it will have on {forTeam? "the team":"on your"} XL score:
                <InfoIconWithToolTip content={actionChallangeInfo}/>
                {/* <span id='action-info' className='mx-1 hover:cursor-pointer' data-tip={actionChallangeInfo}><InfoIcon/></span>  */}
              </p>

              
              {/* chalanges  */}
                <div className='flex gap-2 flex-wrap max-h-[350px] overflow-auto'>
                  {
                    currentActionChallanges.map((c,i)=> {
                      return <SecondryButton 
                      style={{padding: "3px 3px",fontSize: "12px"}}
                      key={i} 
                      onClick={()=>handleActionChallengeClick(c)}
                      >{c.actionChallenge}</SecondryButton>
                    })
                  }
                </div>
            </div>
    </div>
    </>
    
  )
}

export {ActionChallenge};