import React, { useEffect,useRef,useState } from "react";
import {
  getUserDashboardData,
  getUserById,
  updateLoggedInUser,
  getAllSurveyTitles,
  getUniversalSurveyTitle,
  getTodaysEvents
 } from "../../Store/actions";
import {
  useLocation, 
  useNavigate
} from "react-router-dom";
import {
  EventModal,
  Loading,
  StreakModal
} from "../../components";
import {
  FormSkeleton,
  YourInsightsFullView,
  Tabs,
  InstantInsights,
  PeakStateActionChallenges,
  MobileView,
  DesktopView
} from "./components";
import { Main } from "../../layout";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CogniPulsePhySelPhySelfieImpulseProgressModal } from "../../components/Modals";



function Index() {
  const location = useLocation();
  const [showAddEvent, setShowAddEvent] = useState(true);
  const [showProgress, setShowProgress] = useState(()=> location.state?.loading);
  const [currentActivity,setCurrentActivity] = useState(location.state?.surveyName || "");
  const [numberOfCompletedPhycheSelfies, setNumberOfCompletedPhycheSelfies] = useState(null);
  const user = useSelector(state => state.loggedInUser);
  const [isStreakModalOpen,setStreakModalOpen] = useState(true);
  const [isPhySelfieChanged, setIsPhySelfieChanged] = useState(false);

  useEffect(() => {
    (async ()=> {
      if(location.state?.loading){
        const res = await getTodaysEvents();
        if(res.success){
          // console.log(res);
          setNumberOfCompletedPhycheSelfies(res.numberOfCompletedPhycheSelfies);
        }
      }
    })()
  }, [showAddEvent,location.state?.loading]);

  

  if(!user && !location.state?.loading){
    return <div><Loading/></div>
  }

  if(!user && location.state?.loading){
    return <FormSkeleton/>
  }
  
  // if(showAddEvent && location.state?.loading){
  if(showAddEvent && location.state?.loading){
    return <EventModal
            isOpen={showAddEvent}
            setIsOpen={setShowAddEvent}
            userId={user._id}
            cancelBtnText="Skip"
            isManagemaneEvent={false}
            isUpdate={null}
            data={null}
            setEvents={null}
            eventId={null}
            surveyTitle={null}
            setNumberOfCompletedPhycheSelfies={setNumberOfCompletedPhycheSelfies}
            setIsPhySelfieChanged={setIsPhySelfieChanged}
          />
  }

  if(isStreakModalOpen && location.state?.loading){
    return <StreakModal userId={user._id} isOpen={isStreakModalOpen} setIsOpen={setStreakModalOpen} handleOk={()=>setStreakModalOpen(false)}/>
  }

  
  if(showProgress){
    const max = graterNumber(location.state?.numberOfCompletedSurveys,location.state?.numberOfNotifications,numberOfCompletedPhycheSelfies,location.state?.numberOfCompletedImpactSurveys);
    return <CogniPulsePhySelPhySelfieImpulseProgressModal 
                numberOfNotifications={max || 1}
                numberOfCompletedSurveys={location.state?.numberOfCompletedSurveys}
                numberOfCompletedImpactPulse={location.state?.isNonCogniPulse? location.state?.numberOfCompletedImpactSurveys:location.state?.numberOfCompletedImpactSurveys}
                numberOfCompletedPhycheSelfies={numberOfCompletedPhycheSelfies}
                isCogniPuseChanged={location.state?.isCogniPuseChanged}
                isPhySelfieChanged={isPhySelfieChanged}
                isImPuseChanged={location.state?.isImPuseChanged}
                setShowProgress={setShowProgress}
            />
  }

  if(user && user.canAccessDashboard === false){
    return <div className="h-[93vh] flex justify-center items-center">
      <p>Thank you for tracking you state. Your data will be available soon!</p>
    </div>
  }

  return <><NewDashboard
          user={user} 
          currentActivity={currentActivity}
          setCurrentActivity={setCurrentActivity}
          />
        </>
}

export default Index;

function NewDashboard({user,currentActivity,setCurrentActivity}){
  const location = useLocation();
  const navigator = useNavigate();
  const XLScoreContainer = useRef(null);
  const [deshboardData,setDeshboardData] = useState(null);
  const [isLoading,setIsLoading] = useState(true);
  const [isAnimation,setIsAnimation] = useState(()=> location.state?.loading? true:false);
  const [secVis,setSecVis] = useState({
    actionChallenges: false,
    streamGraph: false
  });
  const [isYourInsightsFullViewOn,setIsYourInsightsFullViewOn] = useState(false);
  const [currentTourStep,setCurrentTourStep] = useState(null);
  const currentActivityName = user.activities.find(ac => ac.category === (currentActivity || deshboardData?.currentActivity))?.name;
  const dispatch = useDispatch();
  const loggedInUser = useSelector(state => state.loggedInUser);
  const [isSurveyLoading, setIsSurveyLoading] = useState(false);
  const [isShowOnboarding, setIsShowOnboarding] = useState(()=> loggedInUser.isDashboardTutorialFinished === false? true:false);
  const [clickedOn,setClickedOn] = useState("");
  const [isInstantInsightsOpen,setIsInstantInsightsOpen] = useState(false);
  const [isPeakStateActionChallengesOpen,setIsPeakStateActionChallengesOpen] = useState(false);
  const isMobileScreen = useIsMobile();

  

  const handleFetchUnRegularSurvey = async (e,isImpulse) => {
    e.preventDefault();
    setIsSurveyLoading(true);
    const resUser = await getUserById(user._id);
    const res = await getAllSurveyTitles();
    const universalRes =  await getUniversalSurveyTitle();
    
    
    if(resUser.success && res.success){
      const selectedSurvey = res.surveyTitles.find(st => resUser.user?.unRegularSurveys.indexOf(st._id) !== -1);
      setIsSurveyLoading(false);
      const cat = loggedInUser.activities.find(item => item.name === currentActivityName);


      if(selectedSurvey && isImpulse){
        navigator(`/survey/${loggedInUser._id}/${selectedSurvey._id}/${new Date().getTime()}?new=true&isNonCogniPulse=true&surveyName=${cat.category}&title=${cat.name}`);
      }
      else {
        navigator(`/survey/${loggedInUser._id}/${universalRes.surveyTitle._id}/${new Date().getTime()}?surveyName=${cat.category}&title=${cat.name}&new=true`);
      }
    }
    else {
      setIsSurveyLoading(false);
      toast.error("Something went wrong!");
    }
  }

  useEffect(() => { 
    (async () => {
      const query = `user=${user._id}&surveyName=${currentActivity}&isEventsAndActionChallenges=true`;

      const res = await getUserDashboardData(query);
      console.log(query);

      if(res.success){
        setDeshboardData({...res});
        dispatch(updateLoggedInUser({hasSeenDashboardFirstTime: true}));
      }
      setIsLoading(false);
    })();
  }, [user?._id,currentActivity,dispatch]);

  useEffect(() => {
    if(location.state?.payload?.isShowDashboard){
      setIsShowOnboarding(true);
    }
  }, [location.state?.payload?.isShowDashboard]);


  if(isLoading || !deshboardData){
    return <Main><Loading/></Main>
  }

  const {
    XLScore,
    scoreOfAllfactors,
    percentageOfChangeOfFactors,
    historiclData,
    XLChange,
    higestDiffrance,
    factorsMinMax,
    currentActionChallengesWithSteps,
  } = deshboardData;

  
  
  if(isYourInsightsFullViewOn){
    return <YourInsightsFullView 
            deshboardData={deshboardData} 
            user={user} 
            currentActivity={currentActivity || deshboardData?.currentActivity} 
            XLChange={XLChange} XLScore={XLScore} 
            historiclData={historiclData} 
            factorsMinMax={factorsMinMax} 
            percentageOfChangeOfFactors={percentageOfChangeOfFactors}
            setIsOpen={setIsYourInsightsFullViewOn}
          />
  }

  if(isInstantInsightsOpen){
    return <InstantInsights 
              secVis={secVis} 
              higestDiffrance={higestDiffrance} 
              currentActionChallengesWithSteps={currentActionChallengesWithSteps} 
              setSecVis={setSecVis} 
              isAnimation={isAnimation} 
              user={user} 
              currentActivity={currentActivity || deshboardData?.currentActivity} 
              factorsMinMax={factorsMinMax} 
              percentageOfChangeOfFactors={percentageOfChangeOfFactors} 
              scoreOfAllfactors={scoreOfAllfactors}
              setIsOpen={setIsInstantInsightsOpen}
            />
  }

  if(isPeakStateActionChallengesOpen){
    return <PeakStateActionChallenges 
              clickedOn={clickedOn} 
              user={user} 
              currentActivity={currentActivity || deshboardData?.currentActivity} 
              setIsOpen={setIsPeakStateActionChallengesOpen} 
           />
  }

  
  return (
    <>
        <Main>
        
            <Tabs user={user} deshboardData={deshboardData} currentActivity={currentActivity} setCurrentActivity={setCurrentActivity}/>
           
            {
              !isMobileScreen && <>
                    <DesktopView 
                      setCurrentTourStep={setCurrentTourStep}
                      setIsPeakStateActionChallengesOpen={setIsPeakStateActionChallengesOpen}
                      setClickedOn={setClickedOn}
                      XLChange={XLChange}
                      XLScore={XLScore}
                      currentActivity={currentActivity}
                      setSecVis={setSecVis}
                      isAnimation={isAnimation}
                      isYourInsightsFullViewOn={isYourInsightsFullViewOn}
                      handleFetchUnRegularSurvey={handleFetchUnRegularSurvey}
                      isSurveyLoading={isSurveyLoading}
                      XLScoreContainer={XLScoreContainer}
                      isShowOnboarding={isShowOnboarding}
                      setIsShowOnboarding={setIsShowOnboarding}
                      currentTourStep={currentTourStep}
                      historiclData={historiclData}
                      setIsInstantInsightsOpen={setIsInstantInsightsOpen}
                      setIsYourInsightsFullViewOn={setIsYourInsightsFullViewOn}
                      currentActionChallengesWithSteps={currentActionChallengesWithSteps}
                      higestDiffrance={higestDiffrance}
                      secVis={secVis} 
                      user={user}
                      factorsMinMax={factorsMinMax} 
                      percentageOfChangeOfFactors={percentageOfChangeOfFactors} 
                      scoreOfAllfactors={scoreOfAllfactors}
                      setIsOpen={setIsInstantInsightsOpen}
                      setIsAnimation={setIsAnimation}
                      deshboardData={deshboardData}
                      currentActivityName={currentActivityName}
                    />
              </>
            }
            {
              isMobileScreen && <>
                  <MobileView
                    setCurrentTourStep={setCurrentTourStep}
                    setIsPeakStateActionChallengesOpen={setIsPeakStateActionChallengesOpen}
                    setClickedOn={setClickedOn}
                    XLChange={XLChange}
                    XLScore={XLScore}
                    currentActivity={currentActivity}
                    setSecVis={setSecVis}
                    isAnimation={isAnimation}
                    isYourInsightsFullViewOn={isYourInsightsFullViewOn}
                    handleFetchUnRegularSurvey={handleFetchUnRegularSurvey}
                    isSurveyLoading={isSurveyLoading}
                    XLScoreContainer={XLScoreContainer}
                    isShowOnboarding={isShowOnboarding}
                    setIsShowOnboarding={setIsShowOnboarding}
                    currentTourStep={currentTourStep}
                    historiclData={historiclData}
                    setIsInstantInsightsOpen={setIsInstantInsightsOpen}
                    setIsYourInsightsFullViewOn={setIsYourInsightsFullViewOn}
                    currentActionChallengesWithSteps={currentActionChallengesWithSteps}
                    higestDiffrance={higestDiffrance}
                    secVis={secVis} 
                    user={user}
                    factorsMinMax={factorsMinMax} 
                    percentageOfChangeOfFactors={percentageOfChangeOfFactors} 
                    scoreOfAllfactors={scoreOfAllfactors}
                    setIsOpen={setIsInstantInsightsOpen}
                    setIsAnimation={setIsAnimation}
                    currentActivityName={currentActivityName}
                  />
              </>
            }
        </Main>
    </>
  )
}

function graterNumber(...rest){
  return  rest.filter(v => typeof v === 'number').sort().reverse()[0];
}

function useIsMobile(){
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500? true:false);

  // const getDeviceType = () => {
  //   const ua = navigator.userAgent;
  //   if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
  //     return "tablet";
  //   }
  //   if (
  //     /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
  //       ua
  //     )
  //   ) {
  //     return "mobile";
  //   }
  //   return "desktop";
  // };

  useEffect(() => {
    // const device = getDeviceType();

    // if(device === "desktop"){
      // window.addEventListener("resize", ()=> {
      //   if(window.innerWidth <= 500){
      //     setIsMobile(true);
      //   }
      //   else {
      //     setIsMobile(false);
      //   }
      // })
    // }
    
    // if(device === "mobile" || device === "tablet"){
      window.addEventListener("orientationchange",(e) => {
        if(e.target.screen.orientation.type === "portrait-primary"){
          setIsMobile(true);
        }
        else {
          setIsMobile(false);
        }
      })
    // }

    return ()=> {
      // window.removeEventListener("resize",() => {
      //   setIsMobile(false);
      // })
      window.removeEventListener("orientationchange",() => {
        setIsMobile(false);
      });
    }
  }, []);

  return isMobile
}






