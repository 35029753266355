import {standardDeviation} from "./index";

function OPQCalculator(selectedRanges,accessor){
    accessor = accessor || "XLScore";
    
    const scores = selectedRanges.map(item => item[accessor]);
    
    const sd = standardDeviation(scores);

    if(isNaN(sd)){
        return "Not Enough Data";
    }
    
    return sd;
}

export { OPQCalculator };