

function PopupContainer({children}){

    return (<>
        <div className='fixed top-[64px] left-0 w-full h-[calc(100vh-64px)] overflow-y-auto z-[501] bg-black/80 p-2 px:mx-0'>
            <div className='bg-primary-color p-4 md:p-8  md:my-10 md:mt-[70px] w-[500px] max-w-full mx-auto rounded-lg transform'>
                {children}
            </div>
        </div>
    </>)
};


export {PopupContainer};