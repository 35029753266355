import { CrossSVG } from "../../../components";
import {Main} from "../../../layout";




function FullView({setIsOpen,title,children}) {

  
    return (
      <Main>
        <div className="flex flex-col items-center">
                <div className="w-full border-b border-secondry-color/20 flex justify-between w-full p-2 mb-1">
                    <div className="flex items-center justify-between gap-2 w-[80%]">
                        <span className="text-secondry-color font-bold">{title}</span>
                    </div>
                    <div onClick={()=>setIsOpen(false)} className="w-[35px] h-[35px] flex justify-center items-center border border-secondry-color/20 active:border-secondry-color/60 p-[5px] rounded-md">
                        <CrossSVG color={"text-red-200"}/> 
                    </div>
                </div>
                {children}
        </div>
      </Main>
    )
}
  

export { FullView };
  
  