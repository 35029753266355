import React from 'react';



function Index() {
  // const containerRef = useRef(null);
  return (
      <div className='h-[90vh] w-[100vw] m-0 p-0 flex justify-center items-center'>
      </div>
  );  
}

export default Index;











