import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {createActionChallenge, getActionChallengeOfTeam, getActionChallengeOfUser, setNumberOfActionChallenges} from "../../Store/actions";
import moment  from 'moment';
import { useNavigate } from 'react-router-dom';
import {Main} from "../../layout";
import {Thead, Table, Tbody, Td, Th, Tr, PrimaryButton, SecondryButton, ActionChallengeModal} from "../../components";
import { deleteActionChallenge } from '../../Store/actions';
import { toast } from 'react-toastify';




function Index() {
    const loggedInUser = useSelector(state=> state.loggedInUser);
    const previousRoute = useSelector(state=> state.globalStates.previousRoute);
    const [actionChallenges, setActionChallenges] = useState(null);
    const [showActionChallengePopup, setShowActionChallengePopup] = useState(false);
    const [selectedActionChalenge, setSelectedActionChalenge] = useState(null);
    const navigator = useNavigate();
    const [showCompeted, setShowCompleted] = useState(false);
    
    const handleBack = () => {
        navigator(previousRoute);
    }

    const handleShowHideCompletedActionChallenges = () => {
        setShowCompleted(prev => !prev);
    }

    useEffect(() => {
        (async ()=> {
            if(!loggedInUser){
                return;
            }
            const res = await getActionChallengeOfUser({userId: loggedInUser._id});
            const res1 = await getActionChallengeOfTeam({userId: loggedInUser._id});
            if(res.success){
                const completedOnes = res.actionChallenges.filter(ac => ac.isCompleted);
                const unCompletedOnes = res.actionChallenges.filter(ac => !ac.isCompleted);
                setActionChallenges([...unCompletedOnes,...completedOnes]);
            }
            if(res1.success){
                const completedOnes = res1.actionChallenges.filter(ac => ac.isCompleted);
                const unCompletedOnes = res1.actionChallenges.filter(ac => !ac.isCompleted);
                setActionChallenges(p => [...p,...unCompletedOnes,...completedOnes])
            }
        })()
    }, [loggedInUser,showActionChallengePopup]);

  return (
        <Main>    
            <div className="flex items-center justify-between md:flex py-6 border-b border-secondry-color/20">
                <div className="max-w-lg">
                    <h3 className="text-secondry-color text-xl mb-0 font-bold sm:text-2xl">
                        All Action-Challenges
                    </h3>
                </div>
                <div className="max-w-lg">
                    <PrimaryButton style={{padding: "12px 20px"}} onClick={handleBack}>
                        Back
                    </PrimaryButton>
                </div>
            </div>
            <div className='pt-4 w-[300px]'>
                <PrimaryButton onClick={handleShowHideCompletedActionChallenges}>{showCompeted? "Hide":"Show"} Completed Action Challenges</PrimaryButton>
            </div>
            {
                actionChallenges &&  <MyTable showCompeted={showCompeted} setActionChallenges={setActionChallenges} setShowActionChallengePopup={setShowActionChallengePopup} setSelectedActionChalenge={setSelectedActionChalenge} actionChallenges={actionChallenges}/>
            }    
            <ActionChallengeModal userId={loggedInUser._id} factor={selectedActionChalenge?.factor} currentActionChallenge={selectedActionChalenge} show={showActionChallengePopup} setShow={setShowActionChallengePopup}/>
        </Main>
  )
}

export default Index;



function MyTable({showCompeted,setActionChallenges,actionChallenges,setSelectedActionChalenge,setShowActionChallengePopup}){
    const dispatch = useDispatch();

    const handleView = (actionChallenge) => {
        setShowActionChallengePopup(true);
        setSelectedActionChalenge(actionChallenge);
    }

    const handleDeleteActionChallenhe = async (item) => {
        const isSure = window.confirm("Are You Sure!");
        if(!isSure){
            return;
        }

        const actionChallengeId = item._id;
        const res = await deleteActionChallenge({actionChallengeId});
        // console.log(res);
        if(res.success){
            toast.success("Deleted Successfutty!");
            setActionChallenges(prev => {
                const rem = prev.filter(ac => ac._id !== actionChallengeId);
                dispatch(setNumberOfActionChallenges(rem.length));
                return rem;
            });
        }
        else {
            toast.error(res.message);
        }
    }

    const handleReDo = async (ac) => {
        const actionChallenge = {
            factor: ac.factor,
            impact: "immediate" ,
            subFactor: ac.subFactor,
            actionChallenge: ac.actionChallenge,
            steps: ac.steps.map(step => {return {step: step.step}}),
            for: ac.for,
            user: ac.user 
        };

        const res = await createActionChallenge(actionChallenge);
        
        if(res.success){
            toast.success("You can re-do this task");
            setActionChallenges(prev => [res.actionChallenge,...prev]);
        }
        else {
            toast.error("Something went wrong!");
        }
    }

    const tableHeaders = ["Name","For","Accepted Date","Status","Action"];


    return (
            <div className="py-12 relative h-[calc(100vh-201px)] overflow-auto">
                <Table>
                    <Thead>
                        <Tr>
                            {
                              tableHeaders.map(h => <Th key={h} className="py-3 pr-6">{h}</Th>)  
                            }
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            actionChallenges.filter(ac => showCompeted?  true:!ac.isCompleted).map((item, idx) => (
                                <Tr key={idx}>
                                    <Td>{item.actionChallenge}</Td>
                                    <Td>{item.for}</Td>
                                    <Td>{moment(item.createdAt).format("DD/MM/YYYY")}</Td>
                                    <Td>
                                        <span className={`px-3 py-2 rounded-full font-semibold text-xs ${item.isCompleted? "text-secondry-button-color bg-secondry-button-color/10" : "text-primary-button-color bg-primary-button-color/10"}`}>
                                            {item.isCompleted? "Completed":"In Progress"}
                                        </span>
                                    </Td>
                                    <Td>
                                        <div className='flex gap-3 items-center'>
                                            <div>
                                                <DeleteButton onClick={()=>handleDeleteActionChallenhe(item)}>
                                                    Delete
                                                </DeleteButton>
                                            </div>
                                            <div>
                                                <SecondryButton onClick={()=>handleView(item)}>
                                                    View
                                                </SecondryButton>
                                            </div>
                                            <button onClick={()=>handleReDo(item)} title='Re-Do' className='cursor-pointer flex jsutify-center w-[32px] h-[32px] justify-center items-center border border-secondry-color/20 rounded-full hover:bg-secondry-color/10 active:border-[2px] active:border-secondry-color/40'>
                                                <img className='w-[20px] h-[20px]' alt='' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABpUlEQVR4nO2YzS4EQRSFz2ZsWBFewc8aK8Qa7yKxMJ6ECE9AWMrM3NsIlh0Zc+/4Cy8xLEVLd0hkZKZ7+m+qor6ktpVzbtWpulWAw+Fw/D+89hqshvUDrFVYC2sQDdJDHOkIrDXAkYkb1JtTsNYAR+MVrHOw2EAAkg5INmCtAY6GJeHubSD4zsUBfL9SvJCGzIJ0BywM0kewvseKSzzkGme3k8UIZ10E6Xl+YrXXSrxERcqN8Mxm2QfJZ+Hi+Xe4W+vZxdP9BEiuShPO3eGWzfTiw0CxXgxJfJDdQLhthiWesm6hKLAl7nnOO8Ssl8OpvuRwjIbuky3zMbi9jHpzNGFRSrrIKLykYis1eLhKayVYOLbyqeYtq5ljeY5Z6iWz22mSt74Gku75bkp70JB0+hq40LFU85b2pGR56mvAa6+km7esvp9jQsx6Yva3Cms1wZm9BWOptWaSXWR6CpLV1KEulCxdqBGQLKRu5oyBdM9uA75fSfUGNoqajg/cWhuHHz0tdxNnwlgarXmQePYa+MG7mwbLNkgbYH340/w5HA6Hw4Fi+QJQaihUhFtWdAAAAABJRU5ErkJggg=="/>
                                            </button>
                                        </div>
                                    </Td>
                                </Tr>
                            ))
                        }
                    </Tbody>
                </Table>
            </div>
    )
}


function DeleteButton({children,onClick=()=>{},style={},disabled=false,id=""}){

    return (
        <button id={id} onClick={onClick} disabled={disabled} style={style} className={`bg-red-100 text-red-600 w-full px-2 text-xs md:text-[14px] md:px-4 py-2 rounded-md  hover:shadow hover:shadow-red-600 focus:bg-red-600/70 disabled:bg-red-600/60 disabled:text-primary-color disabled:hover:shadow-none disabled:cursor-not-allowed`}>
            {
                children && children
            }
            {
                !children && "Primary Button"
            }
        </button>
    )
}

