import PrivateRoutes from "./PrivateRoutes";
import ManagerRoutes from "./ManagerRoutes";
import AdminRoutes from "./AdminRoutes";


const components = {
    PrivateRoutes,
    ManagerRoutes,
    AdminRoutes
}

export default components