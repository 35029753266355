import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import {getOutStandingSurveys,getAllSurveyLinks} from "../../Store/actions";
import { toast } from 'react-toastify';
import {Loading} from "../../components";
import moment from "moment";
import {Link,useLocation} from "react-router-dom";

function Index() {
    const location = useLocation();
    const loggedInUser = useSelector(state => state.loggedInUser);
    const [outStandingSurveys, setOutStandingSurveys] = useState(null);
    const userId = location?.state?.userId;


    useEffect(() => {
        (async ()=> {
            if(loggedInUser){
                const res = await getOutStandingSurveys(userId || loggedInUser._id);
                if(res.success){
                  setOutStandingSurveys(res.outStandingSurveys);
                }
                else {
                  setOutStandingSurveys(null);
                  toast.error("server error!");
                }
            }
        })()    
    }, [loggedInUser,userId]);


    const handleShowAllSurvey = async () => {
        const res = await getAllSurveyLinks(userId || loggedInUser._id);


        console.log(res.surveysinfo);

        if(res.success){
          setOutStandingSurveys(res.surveysinfo);
        }
        else {
          setOutStandingSurveys(null);
          toast.error("server error!");
        }
    }


  return (
    <div className='max-w-[1200px] mx-auto pt-10'>
      <div className="flex gap-5">
        <button>Outstanding Only</button>
        <button onClick={handleShowAllSurvey}>All</button>
      </div>
      {
        !outStandingSurveys && <Loading/>
      }
      {
         outStandingSurveys && <>{
          outStandingSurveys?.length === 0? <div className='text-center text-2xl'>No Outstanding survey found</div>:<>
         <div className="flex flex-col gap-2">
         {
           outStandingSurveys.map(item => {


             return <div className="bg-white p-2 flex justify-between items-center" key={item.link}>
                      <div className="">Date: {moment(Number(item.link.split("/")[6].split('?')[0])).format("MMMM Do YYYY, h:mm:ss a")}</div>
                      {
                        !item.iscompleted && <div className=""><Link to={`/survey${item.link.split("/survey")[1]}`}>Click here to complete</Link></div>
                      }
                      {
                        item.iscompleted? <div className="bg-green-600 w-4 h-4 rounded-full"></div>: <div className='bg-red-600 w-4 h-4 rounded-full'></div>
                      }
                    </div>
            })
          }
          </div>
         </>
         }</>
      }
    </div>
  )
}

export default Index;