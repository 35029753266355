import { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SET_PREVIOUS_ROUTE } from '../../constants';

function usePreviousLocationSetter() {
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if(location.pathname !== "/" && location.pathname !== "/action-challenges" && !location.pathname.includes("/login")){
            dispatch({type:SET_PREVIOUS_ROUTE,payload: `${location.pathname}${location.search}`});
        }
    }, [dispatch,location.pathname,location.search]);
}

export {usePreviousLocationSetter};