import { SET_PREVIOUS_ROUTE } from "../../constants";



function previousRoute(state = "", action) {

    switch (action.type) {

      case SET_PREVIOUS_ROUTE:
        return action.payload;

      default:
        return state;
        
    }
}

export {previousRoute};