import { useEffect, useRef, useState } from 'react';
import moment from 'moment'
import { getBrainColor, ScoreInBrainWithLabels } from './ScoreInBrainWithLabels'
import { allSurveysWithXLScoresToHistoricalSPQ, mapper__100_100_to_0_100 } from '../../../utils'
import { cogniPulse, imPulse, tempImg } from '../../../assets';
import { BackButton, EventModal, Factors, InfoIconWithToolTip, PrimaryButton, SecondryButton, ShadowBox } from '../../../components';
import { ActionChallenges } from './ActionChallenges'
import { StreamGraph } from './StreamGraph'
import { QUADRANTS } from '../../../constants'
import { useSelector } from 'react-redux'
import { getCurrentStateData } from '../utils'
import { Chart as ChartJS } from 'chart.js';
import { Link } from 'react-router-dom';
import { useScrollToTop } from '../../../hooks';
import { PopupContainer } from '../../../layout';
import ReactLoading from 'react-loading';

function DesktopView({currentActivityName,deshboardData,user,higestDiffrance,currentActionChallengesWithSteps,scoreOfAllfactors,factorsMinMax,percentageOfChangeOfFactors,setCurrentTourStep,setIsPeakStateActionChallengesOpen,setClickedOn,XLChange,XLScore,currentActivity,setSecVis,isAnimation,isYourInsightsFullViewOn,handleFetchUnRegularSurvey,isSurveyLoading,XLScoreContainer,isShowOnboarding,setIsShowOnboarding,currentTourStep,historiclData,setIsInstantInsightsOpen,setIsYourInsightsFullViewOn}) {
  const brainColor = getBrainColor(mapper__100_100_to_0_100(XLScore));
  const state = getCurrentStateData(XLScore,brainColor);
  const labels = QUADRANTS.find(qd => qd.title === state.currentFlow.quadrant).labels;
  const loggedInUser = useSelector(state => state.loggedInUser);
  const allSurveysWithXLScores = historiclData.map(item => {return {createdAt: item.year,XLScore: item.XLScore}});
  const historicalOPQ = allSurveysWithXLScoresToHistoricalSPQ(allSurveysWithXLScores);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [selectedObstacle, setSelectedObstacle] = useState("");
  const [showCoachingScreen, setShowCoachingScreen] = useState(false);

  


  return <>
        <div className="w-full  pb-10">
          <div className='flex gap-4 justify-center flex-wrap'>
            <ShadowBox className={`max-[900px]:w-full min-[900px]:w-[380px] flex flex-col`}>
                <div className={`w-full h-[50px] mb-4 flex justify-center items-center text-2xl font-bold text-secondry-color`}>{moment(historiclData[historiclData.length-1]?.year).format("DD-MM-YYYY")}</div>
                <div ref={XLScoreContainer} className={` w-full h-[200px] flex-1`}>
                    {
                      !isYourInsightsFullViewOn && <ScoreInBrainWithLabels  setIsAnimationEnds={()=>{}} currentTourStep={currentTourStep} isBrainAnimate={isAnimation}  containerRef={XLScoreContainer} XLScore={mapper__100_100_to_0_100(XLScore)} XLChange={XLChange}/>
                    }
                </div>
                <div className={`mt-4 flex flex-wrap justify-between items-center gap-4 mb-2 mx-2`}>
                    <div className={`rounded-md border-secondry-color/10 flex-1 flex flex-col items-center p-2 gap-2`} title='cogniPulse'>
                      <img onClick={(e)=> handleFetchUnRegularSurvey(e,false)}  src={cogniPulse} alt='cogniPulse' className='h-[50px] cursor-pointer'/>
                      <span onClick={(e)=> handleFetchUnRegularSurvey(e,false)}  className='cursor-pointer'>CogniPulse</span>
                    </div>
                    <div className={`rounded-md border-secondry-color/10 flex-1 flex flex-col items-center p-2 gap-2`} title='impactPulse' id="impact">
                      <img onClick={(e)=> handleFetchUnRegularSurvey(e,true)}  id="impact" src={imPulse} alt='imPulse' className='h-[50px] cursor-pointer'/>
                      <span onClick={(e)=> handleFetchUnRegularSurvey(e,true)}   id="impact"  className='cursor-pointer'>ImPulse</span>
                    </div>
                  <div className='w-full'>
                    <SecondryButton onClick={()=> {setIsEventModalOpen(true)}}>Add Psy-Selfie</SecondryButton>
                  </div>
                </div>
            </ShadowBox>
            <ShadowBox className={`min-w-[368px] flex-1`}>
                  <h3 className="text-md font-bold text-secondry-color">{state.greetMessage} {loggedInUser.forename},</h3>

                  {
                    Number(XLChange) !== 0 && <p className="text-xs text-secondry-color mt-3">Your XLRate score changed by <span style={{backgroundColor: brainColor}} className={`text-black px-1 rounded-md`}>{Number(XLChange) > 0? "+":""}{XLChange}%</span></p>
                  }

                  <p className="text-secondry-color mt-2 text-xs">You are currently in a <span style={{backgroundColor: brainColor}} className={`text-black px-1 rounded-md`}>{state.currentFlow?.quadrantLevel} {state.currentFlow?.quadrant}</span> state.</p>

                  <p className="text-secondry-color mt-2 text-xs">{state.currentFlow.quadrantLevel1}</p>
                  {
                      labels.map((obj,idx) => <SecondryButton key={idx} style={{marginTop: "5px",padding: "3px 3px",backgroundColor: brainColor,color: "#1f1f1f",fontSize: "12px"}}><span className='mr-1'>{obj.text}</span><InfoIconWithToolTip id="asfd" content={obj.tooltip}/></SecondryButton>)
                  }

                  {/* if user click below link they will see detail about specfic state they are in  */}
                  {/* <p onClick={()=> setIsShowStateInfoScreen(true)} className="text-secondry-color underline mt-3">Click for more information about current state</p> */}
                  <p className="text-secondry-color mt-2 text-xs">{state.feedbackMessage}</p>
                  <p className="text-secondry-color mt-2 mb-1 text-xs">Let's start unlocking that now! So you can progress towards {loggedInUser.personalImprovementGoals[0]}</p>

                  <h3 className="text-md font-bold text-secondry-color mt-4 mb-2">What is the main thing that is holding you back right now?</h3>
                  <div className='flex gap-2 mb-4'>
                    {
                      loggedInUser?.personalImprovementObstacles?.map((obs,idx) => (
                        <button onClick={() => setSelectedObstacle(obs)}  className={`border border-secondry-color/20 shadow-md rounded p-2 cursor-pointer ${(selectedObstacle === obs)? "bg-accent-color text-white font-bold":""}`} title="click to select/deselect" key={idx}>{obs}</button>
                      ))
                    }
                  </div>
                  <SecondryButton onClick={()=> setShowCoachingScreen(true)} >Instantly Unlock Your Potential with Data-Driven Personalised Coaching Execises</SecondryButton>
            </ShadowBox>
            <ShadowBox className={`max-[900px]:w-full`}>
                  <div className='w-[280px] mx-auto'>
                      <h4 className="title flex justify-center gap-2 items-center font-bold mb-2 text-secondry-color" >Your Current XL factors</h4>
                      <p className='text-xs text-secondry-color'>Our system has analysed your current factors to identify critical areas for improvement. These specially designed Coaching actions will transform these factors and unlock your full potential.</p>
                      <p className='text-xs text-secondry-color mb-3 mt-3'>Take Coaching Action Now! Choose a simple action to boost your experience and enhance your XL.</p>

                      <Factors 
                        Fun={scoreOfAllfactors?.Fun}
                        Purpose={scoreOfAllfactors?.Purpose}
                        Growth={scoreOfAllfactors?.Growth}
                        Emotives={Math.abs(scoreOfAllfactors?.Emotives)}
                        Utility={Math.abs(scoreOfAllfactors?.Utility)}
                        Apathy={Math.abs(scoreOfAllfactors?.Apathy)}
                        factorsMinMax={factorsMinMax}
                        percentageOfChangeOfFactors={percentageOfChangeOfFactors}
                        border={true}
                        mixPanelEvents={""}
                        conrainerId="introSelector10"
                        driversId="introSelector11"
                        constrainersId="introSelector12"
                      />
                    </div>
                    
            </ShadowBox>
          </div>
          <div className='flex gap-4 mt-4 max-[1000px]:flex-col'>
             <ShadowBox className={`relative h-[419px] flex-1 relative`}>
                <p className='text-secondry-color font-bold border-b border-secondry-color/20 pb-1'>Your Cognitive Rhythms & Factors</p>
                <div className='h-[350px]'>
                  <StreamGraph hideLabels={true} data={deshboardData}/>
                </div>
                <div className='group absolute top-0 left-0 w-full h-full rounded-md'>
                  <div className='w-0 transition-w duration-150 ease-in-out group-hover:w-full h-full flex justify-center bg-black/20 rounded-md items-center overflow-hidden'>
                    <PrimaryButton onClick={()=>setIsYourInsightsFullViewOn(true)} style={{width: "100px"}}>Expand</PrimaryButton>
                  </div>
                </div>
             </ShadowBox>
             <ShadowBox className={`relative h-[419px] flex-1`}>
                <p className='text-secondry-color font-bold border-b border-secondry-color/20 pb-1'>Your Personal Growth</p>
                <div className='h-[350px]'>
                  <PersonalGrowthGraph historicalOPQ={historicalOPQ}/>
                </div>
             </ShadowBox>
             {/* <ShadowBox className={`relative flex-1`}>
                  <ActionChallenges 
                      higestDiffrance={higestDiffrance} 
                      currentActionChallengesWithSteps={currentActionChallengesWithSteps} 
                      user={user}
                      setSecVis={setSecVis}
                      isACAnimation={isAnimation}
                      currentActivityName={currentActivityName}
                    />
             </ShadowBox> */}
          </div>
        </div>

        <EventModal
          isOpen={isEventModalOpen}
          setIsOpen={setIsEventModalOpen}
          userId={loggedInUser._id}
          isUpdate={false}
          data={{}}
          setEvents={()=>{}}
          eventId={""}
          cancelBtnText="Cancel"
          isManagemaneEvent={false}
          surveyTitle={null}
        />
        {
          showCoachingScreen && <CoachingScreen setShowCoachingScreen={setShowCoachingScreen} higestDiffrance={higestDiffrance} currentActionChallengesWithSteps={currentActionChallengesWithSteps} user={user} currentActivityName={currentActivityName}/>
        }
  </> 
}

export {DesktopView}


function PersonalGrowthGraph({historicalOPQ}){
  const chartRef = useRef(null);
  

  useEffect(() => {
    const arr = [];

    historicalOPQ.forEach((item,idx) => {
        const prevSum = historicalOPQ.filter((it,id) => id < idx).reduce((a,i) => a+i.OPQ,0);
        if(idx === 0){
          arr.push({compountedValue: item.OPQ, date: item.date})
        }
        else {
          arr.push({compountedValue: item.OPQ+prevSum,date: item.date});
        }
    });

    const myData = {
            labels: arr.map(item => new Date(item.date)),
            datasets: [
                {
                  label: "Personal Growth (Compounded) Graph",
                  data: arr.map(item => item.compountedValue),
                  backgroundColor: '#0a8001',
                  pointRadius: 0,
                  borderColor: "#0a8001",
                  clip: {left: 0, top: 0, right: 0, bottom: 0}
                }
            ]
    };

    const c = new ChartJS(chartRef.current,{
            type: "line",
            data: myData,
            options: {
                scales: {
                   x: {
                      type: 'timeseries',
                      ticks: {
                          callback: function(value, index, ticks){
                              // if(index === 0){
                              //     return "Today"
                              // }
                              return this.getLabelForValue(value).split(",")[0]
                          },
                          color: "black"
                      },
                      grid: {
                        display: false
                      },
                      border: {
                        color: "black"
                      },
                   },
                   y: {
                      // max: 100,
                      // min: 0,
                      ticks: {
                          callback: function(value,index,ticks){
                              return `${value} %`;
                          },
                          color: "black"
                      },
                      title: {
                                                  display: false,
                                                  text: "Personal Growth (Compounded)",
                                                  font: {
                                                      family: 'sans-serif',
                                                      size: 18,
                                                      style: 'normal',
                                                      lineHeight: 1.2
                                                  },
                      },
                      grid: {
                        display: false
                      },
                      border: {
                        color: "black"
                      }
                   }
                },
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    legend: {
                        position: "top",
                        align: "center",
                        display: false,
                        labels: {
                            font: {
                                size: 11
                            },
                            boxWidth: 11
                        }
                    },
                    labels: {
                        color: "black",
                        font: {
                          family: 'sans-serif',
                          size: 10,
                          weight: "normal"
                        },
                    }
                },
            }
    })

    return () => {
      c.destroy();
    }

  }, [historicalOPQ]);

  return <canvas ref={chartRef}/>
}


function CoachingScreen({higestDiffrance,currentActionChallengesWithSteps,user,currentActivityName,setShowCoachingScreen}){
  useScrollToTop();  
  const [isLoading, setIsLoading] = useState(true);
  const afterComplete = () => {
    setShowCoachingScreen(false);
  }

  useEffect(() => {
    const time = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => {
      clearTimeout(time);
    };
  }, []);
  


  return (<>
        <PopupContainer>
          <div className="bg-primary-color">
            {
              isLoading && <div className='flex flex-col items-center justify-center'>
                <ReactLoading type="bars" color="#000" height="70px" width="50px" />
                <h1 className='text-xl font-bold text-center'>Calculating.. your Personalised Coaching Exercises</h1>
              </div>
            }
            {
              !isLoading && <>
              <div className='w-full max-h-full px-2 pb-6 overflow-auto'>
                <div className='mb-2 mt-2'>
                    <BackButton onClick={()=> {setShowCoachingScreen(false)}}/>
                </div>

                {
                        <ActionChallenges 
                            afterComplete={afterComplete}
                            higestDiffrance={higestDiffrance} 
                            currentActionChallengesWithSteps={currentActionChallengesWithSteps} 
                            user={user}
                            currentActivityName={currentActivityName}
                            setIsOpen={setShowCoachingScreen}
                            newTitle={true}
                        />
                }

              <p className='text-secondry-color text-center text-sm mt-8 mb-2'>Get Ready for Your Personalised Action Map!</p>
                <div className="mb-4 relative w-full mx-auto md:mb-10 border-[3px] rounded-md border-accent-color">
                              <div className="w-full h-full flex justify-center items-center blur-sm">
                                  <img src={tempImg} alt="temp" className="w-full h-full"/>
                              </div>
                              <div className="absolute top-0 left-0 w-full h-full z-[10]">
                                  <div className="w-full h-full flex justify-center items-center">
                                          <div className="p-2 md:p-8 bg-white/60">
                                              <h2 className="text-xs  font-bold text-center text-accent-color leading-6 md:leading-12"> 
                                                  We're preparing your unique Cognitive Blueprint for Peak Performance. 
                                                  Stay tuned for a notification when your insights are ready. 
                                                  Set your <Link to="/manage/notifications" className="text-primary-button-color mx-2 underline">CogniPulse notifications</Link> to fit your schedule, and each pulse will bring you closer to unlocking your peak performance state.
                                              </h2>
                                          </div>
                                  </div>
                              </div>
                </div>
              </div>
              </>
            }
            
          </div>
        </PopupContainer>
  </>)
}





