import { DOMAIN_NAME } from "../../constants";
import {fetchRequest} from "../../utils";





export const createSurvey = async (data) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/survey`,"POST",data);

    return res;
}


export const getGrowthPointsById = async (userId) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/user/growthpoints/${userId}`,"GET",{});

    return res;
}


export const getAllCompletedSurveys = async (query) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/survey/all?${query}`,"GET",{});

    return res;
}





export const includeExcludeSurveys = async (id,data) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/survey/exclude/${id}`,"PUT",data);

    return res;
}


export const getCompletedSurveysByUserIds = async (users) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/survey/users`,"GET",{users});

    return res;
}




export const getCompletedSurveysByUserId = async (userId) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/survey/user/${userId}`,"GET",{});

    return res;
}


export const getLatestCompletedSurveysByUserId = async (userId,surveyTitleId) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/survey/latest/user/${userId}/?surveyTitle=${surveyTitleId}`,"GET",{});

    return res;
}



export const getManagementData = async (users,createdAt,surveyTitle) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/survey/management/sruveys?users=${users}&groupCreationDate=${createdAt}&surveyTitle=${surveyTitle}`,"GET",{});

    return res;
}



export const getUserDashboardData = async (query) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/survey/user-dashboard-data?${query}`,"GET",{});

    return res;
}


export const getManagementDashboardData = async (userIds,surveytitle,surveyName) => {


    let query = `user=${JSON.stringify(userIds)}`;
    if(surveytitle){
        query += `&surveytitle=${surveytitle}`;
    }
    if(surveyName){
        query += `&surveyName=${surveyName}`;
    }
    

    const res = await fetchRequest(`${DOMAIN_NAME}/survey/managament-dashboard-data?${query}`,"GET",{});

    return res;
}



















