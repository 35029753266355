import React,{useEffect,useRef,useState} from 'react';
// import { useLocation } from 'react-router-dom';
import { 
  getAllGroups,
  getManagementDashboardData
} from "../../Store/actions";
import { toast } from 'react-toastify';
import {
  Factors,
  Loading,
  PrimaryButton,
  InfoIconWithToolTip,
  EventModal,
  ActionChallengeModal,
  ShadowBox,
  SecondryButton
} from "../../components";
import { useSelector } from 'react-redux';
import { FACTOR_COLORS, QUADRANTS, XL_FACTORS } from '../../constants';
import moment from "moment";
import { 
  mapper__100_100_to_0_100,
  // allSurveysWithXLScoresToHistoricalSPQ,
  OPQCalculator
} from '../../utils';

import {
  ActionChallenge,
  Users,
  HeatMap,
  useLeadershipText
} from "./components";
import { Chart as ChartJS} from "chart.js/auto";
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Main } from '../../layout';
// import 'react-dropdown-tree-select/dist/styles.css';
// import { CircularProgressbarWithChildren,buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {useScrollToTop} from "../../hooks";
import { getBrainColor } from '../UserDashboard/components/ScoreInBrainWithLabels';
import { getCurrentStateData } from '../UserDashboard/utils';

ChartJS.register(zoomPlugin);
ChartJS.register(annotationPlugin);


function Index() {
    // const location = useLocation();
    // const dispatch = useDispatch();
    const surveyName = useSelector(state => state.globalStates.surveyName);
    const loggedInUser = useSelector(state => state.loggedInUser);
    const [group, setGroup] = useState(null);
    const [userSurveyNames] = useState(null);
    const [leaderShipfeedback, leaderShipStyle,setIndex] = useLeadershipText();
    // const [dataFetched, setDataFetched] = useState(false);
    const [onlyOneUserHaveDone, setOnlyOneUserHaveDone] = useState(false);
    const [allUserNames,setAllUsersName] = useState(null);
    const [groups, setGroups] = useState(null);
    const [data, setData] = useState(null);
    const [index, setIIndex] = useState(0);
    const [selectedGroup, setSelectedGroup] = useState("");
    useScrollToTop();
 

    
    useEffect(() => {
      if(group?.histogramData?.length <= 1){
        setOnlyOneUserHaveDone(false);   
      }
    }, [group]);

    useEffect(() => {
      (async ()=> {
          if(data){
            if(data.users?.length > 0){
              const res = await getManagementDashboardData(data.users,'',undefined);

              if(res.success){
                setAllUsersName(()=> {
                  return [...data.usersDetails].map(u => `${u.forename} ${u.surname}`);
                })
                setGroup({...res,groupname: data.groupname,users:data.users,surveyTitle: data.userSurveys,factorsMinMax: res.factorsMinMax});
                // if(dataFetched){
                //   dispatch({type: SET_SURVEY_NAME,payload: location?.state?.selectedSurveyNames || ""});
                //   setDataFetched(true);
                // }
                // if(!userSurveyNames){
                //   setUserSurveyNames(location.state.userSurveys);
                // }
                setIIndex(res.allData.length-1);
              }
              else {
                toast.error(res.message);
              }
            }
            else {
              setData(null);
              toast.error("The selected group has no users.");
            }
          }
      })()
    }, [data]);

    useEffect(() => {
        if(group?.currentXLScore){
          setIndex(()=>{
            if(group.currentXLScore <= -1 && group.currentXLScore >= -100){
              return 0
            }
            if(group.currentXLScore <= 15 && group.currentXLScore >= 0){
              return 1
            }
            if(group.currentXLScore <= 25 && group.currentXLScore > 15){
              return 2
            }
            if(group.currentXLScore <= 100 && group.currentXLScore > 25){
              return 3
            }
          })
        }
    }, [group?.currentXLScore,setIndex]);

    if(!data){
      return <><Main>
               <Groups selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} setData={setData} groups={groups} setGroups={setGroups}/>
               <div className='min-h-[50vh] flex justify-center items-center'>
                  <p className='text-secondry-color'>Please select a group to view the leadership dashboard.</p>
               </div>
               {/* <div className='flex flex-col gap-1 w-[240px] items-center'>
                  <div className='border-1'>
                    <h3 className='text-center text-secondry-color font-bold'>Drivers</h3>                
                    <div className='flex gap-2'>
                      <Factor isDriver={true} color={FACTOR_COLORS.FUN} XLScore={50} XLChange={-10} label="Fun" XLRange={{min: 10, max: 40}}/>
                      <Factor isDriver={true} color={FACTOR_COLORS.PURPOSE} XLScore={70} XLChange={-10} label="Purpose" XLRange={{min: 60, max: 70}}/>
                      <Factor isDriver={true} color={FACTOR_COLORS.GROWTH} XLScore={90} XLChange={10} label="Growth" XLRange={{min: 70, max: 90}}/>
                    </div>
                  </div>

                  <div>
                    <div className='flex gap-2'>
                      <Factor color={FACTOR_COLORS.EMOTIVES} XLScore={30} XLChange={10} label="Emotives" XLRange={{min: 10, max: 40}}/>
                      <Factor color={FACTOR_COLORS.UTILITY} XLScore={40} XLChange={-10} label="Utility" XLRange={{min: 60, max: 70}}/>
                      <Factor color={FACTOR_COLORS.APATHY} XLScore={70} XLChange={-10} label="Apathy" XLRange={{min: 70, max: 90}}/>
                    </div>
                    <h3 className='text-center text-secondry-color font-bold'>Constrainers</h3>
                  </div>
               </div> */}
             </Main>
             </>
    }

    if(!group || !loggedInUser){
      return <Main>
               <Groups selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} setData={setData} groups={groups} setGroups={setGroups}/>
               <Loading/>
             </Main>
    }


    return <>
      <Main>
        <Groups selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} setData={setData} groups={groups} setGroups={setGroups}/>
        <Dashboard users={data?.users} index={index} setIndex={setIIndex} allData={group.allData} allSurveysWithXLScores={group.allSurveysWithXLScores}  loggedInUser={loggedInUser} factorsMinMax={group.factorsMinMax} onlyOneUserHaveDone={onlyOneUserHaveDone} groupname={data?.groupname}  userSurveyNames={userSurveyNames} surveyName={surveyName} historicalData={group.historicalData} myEvents={group.events} myActionChalenges={group.actionChallenges} allUserNames={allUserNames} leaderShipStyle={leaderShipStyle} leaderShipfeedback={leaderShipfeedback}/>
      </Main>
    </>
}

export default Index;


function Dashboard({users,index,setIndex,myEvents,myActionChalenges,allSurveysWithXLScores,allData,factorsMinMax,loggedInUser,groupname,onlyOneUserHaveDone,userSurveyNames,surveyName,allUserNames,historicalData}){
  
  const group = allData[index];
  // group.currentXLScore = -10;
  // const [dateOfTheCurrentXLScore, setDateOfTheCurrentXLScore] = useState(group.dateOfTheCurrentXLScore);
  const [brushSelection, setBrushSelection] = useState(null);
  const SELECTED_RANGES = brushSelection? allData.map((item,index) => {return {...item,index}}).filter(item => moment(item.dateOfTheCurrentXLScore).isBetween(moment(brushSelection.range[0]),moment(brushSelection.range[1]))):allData;
  const SUM_OF_XL_SCORES = SELECTED_RANGES.reduce((a,b)=> a + b.currentXLScore,0)/SELECTED_RANGES.length;
  const MOST_FREQUENT_FACTORS = useFrequencyOfFactorsCalculator(SELECTED_RANGES);
  const allSelectedSurveysWithXLScores = allSurveysWithXLScores.filter(survey => moment(survey.createdAt).isBetween(moment(SELECTED_RANGES[0]?.dateOfTheCurrentXLScore), moment(SELECTED_RANGES[SELECTED_RANGES.length-1]?.dateOfTheCurrentXLScore), null, '[]'))
  const OPQ = OPQCalculator(allSelectedSurveysWithXLScores,"XLScore");
  // const historicalOPQ = allSurveysWithXLScoresToHistoricalSPQ(allSurveysWithXLScores);
  const currentSurveys = allSurveysWithXLScores.filter(item => moment(item?.allSurveysWithXLScores).format("DD-MM-YYYY") === moment(group?.allSurveysWithXLScores).format("DD-MM-YYYY"))
  const currentOPQ = OPQCalculator(currentSurveys,"XLScore");
  const NEXT_DISABLED = index === null || index === allData.length-1;
  const PREV_DISABLED = index === 0;
  const historicalDataWithStreak = useCalculateStreakOfUser(allData,group,index);
  const [showActionChallengePopup, setShowActionChallengePopup] = useState(false);
  const [currentActionChallenge, setCurrentActionChallenge] = useState(null);
  const toltipText = `(Caution: this is a Beta feature!) A indicator of performance states that strike the balance between innovation and deliverable solution. Higher scores suggest that performance is more dynamic, possibly reflecting varying levels of innovation, creativity, or adaptability in approaching the problem. Early data indicates a strong correlation between high scores and performance output (i.e. productive value) Note:  and higher scores may also imply inconsistency in performance, which could either be a sign of creative experimentation or lack of a steady approach). Lower scores indicate a more tactical, steady approach to performance with less variability in creativity or innovation. Initial data indicates lower scores have a strong correlation with performance outcomes witt less productive value.`;
  const [isOpenAddEventsModal, setIsOpenAddEventsModal] = useState(false);
  const [isUpdateEvent, setIsUpdateEvent] = useState(false);
  const [eventData, setEventData] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [events, setEvents] = useState(myEvents);
  
  const [streamGraphChartData, setstreamGraphChartData] = useState(()=> {
      return {
                labels: historicalData.map(item => new Date(item.year)),
                datasets: [
                    {
                      type: "scatter",
                      label: "Events",
                      data: myEvents?.map(ev => {return {x: new Date(ev.date),y:-85,description: ev.description,tags: ev.tags}}),
                      borderColor: "#db03fc",
                      backgroundColor: "#db03fc",
                      stack: 'combined',
                    },
                    {
                      type: "scatter",
                      label: "Action Challenges",
                      data: myActionChalenges?.map(ac => {return {x: new Date(ac.createdAt),y:-95,steps: ac.steps,actionChallenge: ac.actionChallenge}}),
                      borderColor: "#4F46E5",
                      backgroundColor: "#4F46E5",
                      stack: 'combined',
                    }
                ]
            }
  });
  const [chart, setChart] = useState(null);
  useEventsChangeEffect({events,setstreamGraphChartData});
  useHistoricalDataChangeEffect({historicalData,setstreamGraphChartData});
  const brainColor = getBrainColor(mapper__100_100_to_0_100(group.currentXLScore));
  const state = getCurrentStateData(group.currentXLScore,brainColor);
  const labels = QUADRANTS.find(qd => qd.title === state.currentFlow.quadrant).labels;


  const handleKeyDown = (e) => {
      e.preventDefault();
      if(e.key === "ArrowLeft" && !PREV_DISABLED){
        handlePrev();
      }
      if(e.key === "ArrowRight" && !NEXT_DISABLED){
        handleNext();
      }
  }

  const handlePrev = () => {
      setIndex(prev => {
        if(prev === 0){
            return 0;
        }
        return prev-1;
      });
      

      // if(allData[index-2].dateOfTheCurrentXLScore && chart.getZoomLevel() !== 1){
      //     const startDate = new Date(allData[index-2].dateOfTheCurrentXLScore).getTime();
      //     const diff = moment(allData[index-1].dateOfTheCurrentXLScore).diff(moment(allData[index-2].dateOfTheCurrentXLScore),"days");
      //     const endDate =  new Date(chart.scales.x.max).setDate(new Date(chart.scales.x.max).getDate()-diff);
      //     chart.zoomScale('x', {min: startDate, max: endDate}, 'none');
      // }

      const newDate = new Date(allData[index-1].dateOfTheCurrentXLScore);
      chart.options.plugins.annotation.annotations.line1.xMin = newDate;
      chart.options.plugins.annotation.annotations.line1.xMax = newDate;
      chart.update();

      const selection = {
          range: [new Date(chart.scales.x.min),new Date(chart.scales.x.max)]
      };
      setBrushSelection(selection);
  }

  const handleNext = () => {
    setIndex(prev => {
        if(prev === allData.length-1){
            return allData.length-1;
        }
        return prev+1;
    })
 
    // if(allData[index+2].dateOfTheCurrentXLScore && chart.getZoomLevel() !== 1){
    //     const startDate = new Date(chart.scales.x.min).setDate(new Date(chart.scales.x.min).getDate()+1);
    //     const endDate = new Date(allData[index+2].dateOfTheCurrentXLScore).getTime();
    //     chart.zoomScale('x', {min: startDate, max: endDate}, 'none');
    // }
    
    const newDate = new Date(allData[index+1].dateOfTheCurrentXLScore);
    chart.options.plugins.annotation.annotations.line1.xMin = newDate;
    chart.options.plugins.annotation.annotations.line1.xMax = newDate;
    chart.update();

    const selection = {
        range: [new Date(chart.scales.x.min),new Date(chart.scales.x.max)]
    };
    setBrushSelection(selection);
  }


  const handleActionChallengeClick = (ac) => {
    setCurrentActionChallenge(ac);
    setShowActionChallengePopup(true);
  }


  return (<>
    <>
       {
        group &&  <>        

        <div tabIndex={0} onKeyUp={handleKeyDown} className='relative mt-2 flex flex-col md:min-h-[1050px] gap-4 lg:flex-row'>
                      {/* left side */}
                      <div className='flex flex-col w-[100%] lg:w-[25%] gap-4'>
                        {/* XL score box  */}
                        <ShadowBox>
                              <div className='flex mb-4 gap-2'>
                                <Users allUserNames={allUserNames} condition={moment(group?.dateOfTheCurrentXLScore).format("DD-MM-YYYY") !== moment().format("DD-MM-YYYY") && loggedInUser.role !== "admin"}/>
                              </div>
                              
                              {/* <CircularProgressbarWithChildren 
                              styles={buildStyles({
                                pathColor: brainColor,
                                strokeLinecap: "butt",
                                rotation: 0.25,
                                })} value={mapper__100_100_to_0_100(group?.currentXLScore)}>
                                <div>
                                  <p className='text-2xl mb-2 font-bold text-center text-secondry-color/20'>XLScore</p>
                                  <p className='text-4xl font-bold text-center'>{mapper__100_100_to_0_100(group?.currentXLScore).toFixed(1)}</p>
                                  <p className='text-[12px] font-bold text-center' style={{color: group?.XLChange>0? "#00ff00":"#ff0000"}}>{parseFloat(group?.XLChange).toFixed(2)}% {group?.XLChange>0? "⬆":"⬇"}</p>
                                  <div className="bg-gray-300 rounded-md text-white mt-4 px-4 py-2 text-secondry-color/50 font-bold"><h4 className='mb-0'>OPQ: {typeof currentOPQ === "string"? "N/A":parseFloat(currentOPQ).toFixed(2)}</h4></div>
                                </div>
                              </CircularProgressbarWithChildren> */}
                              <div className='relative flex flex-wrap justify-center gap-2'>
                                  <CircularProgressBar XLScore_100_100={group?.currentXLScore}/>
                                  <div className='absolute top-0 left-0 w-full h-full'>
                                    <div className='w-full h-full  flex flex-col items-center justify-center'>
                                        <h4 className='text-xl mb-1 font-bold text-center text-secondry-color/20'>XLScore</h4>
                                        <p className='text-3xl font-bold text-center'>{mapper__100_100_to_0_100(group?.currentXLScore).toFixed(1)}</p>
                                        <p className='text-[12px] font-bold text-center' style={{color: group?.XLChange>0? "#00ff00":"#ff0000"}}>{parseFloat(group?.XLChange).toFixed(2)}% {group?.XLChange>0? "⬆":"⬇"}</p>
                                        <div className="w-[110px] text-center mx-auto bg-gray-300 rounded-md text-white mt-4 px-2  text-secondry-color/50 font-bold"><h4 className='mb-0'>OPQ: {typeof currentOPQ === "string"? "N/A":parseFloat(currentOPQ).toFixed(2)}</h4></div>
                                    </div>
                                  </div>
                              </div> 

                              {
                                  (moment(group?.dateOfTheCurrentXLScore).format("DD-MM-YYYY") !== moment().format("DD-MM-YYYY")) && <>
                                  <div className='mt-2 border border-secondry-color/20 rounded-md p-2'>
                                    <p className='text-blue-600 text-xs text-center'>
                                      <span className='font-bold'>Important:</span> Todays data is still being anonymised so the data shown is from { moment(group?.dateOfTheCurrentXLScore).format("DD-MM-YYYY")}. Please check back later.
                                    </p>
                                  </div>
                                  </>
                              }
                        </ShadowBox> 

                        {/* Heatmap box  */}
                        <ShadowBox className='flex flex-col'>
                          <h4 className='flex items-center gap-2 text-secondry-color'>XL Heatmap ({group?.histogramData?.length}/{users?.length}) <InfoIconWithToolTip content="XL scores/Team Members"/></h4>
                          <div className='h-[200px] lg:h[100%]'>
                            <HeatMap histogramData={historicalDataWithStreak}/>
                          </div>
                          
                        </ShadowBox> 
                      </div>

                      {/* right side  */}
                      <div className='flex flex-col w-[100%] lg:w-[75%] gap-4'>

                          {/* right top  */}
                          <div className='flex flex-col lg:flex-row gap-4'>
                              <ShadowBox className={"w-[100%] lg:w-[35%]"}>
                                  <h3 className="text-sm font-bold text-secondry-color">{groupname} XLRate score changed by <span style={{color: group?.XLChange>0? "#00ff00":"#ff0000"}}>{parseFloat(group?.XLChange).toFixed(2)}% {group?.XLChange>0? "⬆":"⬇"}</span></h3>

                                  <p className="text-xs text-secondry-color mt-2">They are currently in a <span style={{backgroundColor: brainColor}} className={`text-black px-1 rounded-md`}>{state.currentFlow?.quadrantLevel} {state.currentFlow?.quadrant}</span> state.</p>
                              
                                  <p className="text-xs text-secondry-color mt-2">{state.currentFlow.quadrantLevel2}</p>

                                  {
                                      labels.map((obj,idx) => <SecondryButton key={idx} style={{marginTop: "10px",padding: "3px 3px",backgroundColor: brainColor,color: "#1f1f1f",fontSize: "12px"}}><span className='mr-1'>{obj.text}</span><InfoIconWithToolTip id="asfd" content={obj.tooltip}/></SecondryButton>)
                                  }

                              </ShadowBox>
                              <ShadowBox  className='w-[100%] lg:w-[35%] flex flex-col items-center'>
                                <h5 className='relative z-20 text-sm font-bold text-secondry-color'>Team XL Factors <InfoIconWithToolTip place="bottom" content="These are the key influences shaping the teams XL Score, a dynamic blend of drivers and constrainers that influence their well-being and performance."/></h5>
                                <Factors 
                                  Fun={group.factors?.Fun} 
                                  Purpose={group.factors?.Purpose} 
                                  Growth={group.factors?.Growth} 
                                  Emotives={group.factors?.Emotives} 
                                  Utility={group.factors?.Utility} 
                                  Apathy={group.factors?.Apathy}
                                  factorsMinMax={factorsMinMax}
                                  percentageOfChangeOfFactors={group.percentageOfChangeOfFactors}
                                  mixPanelEvents={()=>{}}
                                />
                              </ShadowBox>
                              <ShadowBox  className='w-[100%] lg:w-[33%] overflow-auto'>
                                  <div className="text-secondry-color">
                                      {/* <h6>What kind of leader are you right now & how can you improve?</h6> */}
                                      <div className='rounded-lg'>
                                              <p className="text-xs font-bold">{group.leadershipStyle.title.replace("[team_name]", groupname)}</p>
                                              {
                                                group.leadershipStyle.description.map((desc,index) => {
                                                  return <p key={index} className='text-xs mb-3'>{desc}</p>
                                                })
                                              }
                                      </div>
                                  </div>

                                  <div className='border-t-2 border-secondry-color/20'>
                                    {
                                        group?.higestDiffrance && <ActionChallenge mixPanelEvent={()=>{}} currentActionChallanges={group.currentActionChallengesWithSteps} higestDiffrance={group?.higestDiffrance} forTeam={true} handleActionChallengeClick={handleActionChallengeClick}/>
                                    }
                                  </div>
                              </ShadowBox>
                          </div>

                          {/* right bottom */}
                          <ShadowBox className='h-[48%] mb-4'>
                              <div className='w-full h-[250px]'>
                                <MyChart
                                  setCurrentIndex={setIndex}
                                  setBrushSelection={setBrushSelection}
                                  chart={chart}
                                  setChart={setChart}
                                  streamGraphChartData={streamGraphChartData}
                                  events={events}
                                  setEventData={setEventData}
                                  setEventId={setEventId}
                                  setIsUpdateEvent={setIsUpdateEvent}
                                  setIsOpenAddEventsModal={setIsOpenAddEventsModal}
                                  />
                              </div>
                              <div className="relative top-2 right-2 flex gap-2 justify-end items-center">
                                <div className="text-center">{moment(group.dateOfTheCurrentXLScore).format("DD-MM-YYYY")}</div>
                                <PrimaryButton style={{width: "150px",padding: "5px 1px"}} onClick={()=>setIsOpenAddEventsModal(true)}>Add Event</PrimaryButton>
                                <PrimaryButton style={{width: "70px",padding: "5px 1px"}} disabled={PREV_DISABLED} onClick={handlePrev}>Prev</PrimaryButton>
                                <PrimaryButton style={{width: "70px",padding: "5px 1px"}} disabled={NEXT_DISABLED} onClick={handleNext}>Next</PrimaryButton>
                              </div>
                              <div className="mt-4">
                                  <div className="p-2 rounded-md shadow-sm bg-gray-200 min-w-[100px] min-h-[100px]">
                                      <div className="">
                                          <span className='font-bold'>Selected Range:</span>
                                          <span className='ml-[60px]'>{moment(SELECTED_RANGES[0]?.dateOfTheCurrentXLScore).format("DD/MM/YYYY")}</span>
                                          <span className='mx-1'>-</span>
                                          <span>{moment(SELECTED_RANGES[SELECTED_RANGES.length-1]?.dateOfTheCurrentXLScore).format("DD/MM/YYYY")}</span>
                                      </div>
                                      <div className="">
                                          <span className='font-bold'>Average XLScore:</span>
                                          <span className='ml-12'>{parseFloat(mapper__100_100_to_0_100(SUM_OF_XL_SCORES)).toFixed(2)}</span>
                                      </div>
                                      <div className="">
                                          <span className='font-bold'>Highest freq Factor/s:</span>
                                          <span className='ml-5'>{MOST_FREQUENT_FACTORS}</span>
                                      </div>
                                      <div className="">
                                          <span className='font-bold'>Optimal Performance Quotient <InfoIconWithToolTip content={toltipText} size={10} className="mb-2"/>:</span>
                                          <span className='ml-5'>{parseFloat(OPQ).toFixed(2)}</span>
                                      </div>
                                  </div>
                              </div>
                          </ShadowBox>
                      </div>
                      <EventModal    
                        isOpen={isOpenAddEventsModal}
                        setIsOpen={setIsOpenAddEventsModal}
                        userId={loggedInUser._id}
                        isUpdate={isUpdateEvent}
                        data={eventData}
                        setEvents={setEvents}
                        eventId={eventId}
                        cancelBtnText="Cancel"
                        isManagemaneEvent={true}
                        surveyTitle={null}
                      />
                      {
                       (onlyOneUserHaveDone && loggedInUser.role === "admin") && <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[400px] bg-gray-500 text-white p-10 rounded-md font-bold text-center text-xl z-40'>XLrate does not have enough data yet to preserve the anonymity of individuals in this group at this time. Please come back later.</div>
                      }

        </div>
        
        </>
       }
       <ActionChallengeModal show={showActionChallengePopup} setShow={setShowActionChallengePopup} currentActionChallenge={currentActionChallenge} factor={group?.higestDiffrance?.factor} userId={loggedInUser._id} forTeam={true}/>
    </>
  </>)
}

function useFrequencyOfFactorsCalculator(selectedRanges){
  const FREQUENCY_OF_FACTORS = {}
  const [incDec] = useIsIncreaseOrDicrease();

  selectedRanges.forEach(item => {
      if(FREQUENCY_OF_FACTORS[item.higestDiffrance.factor]){
          FREQUENCY_OF_FACTORS[item.higestDiffrance.factor] = FREQUENCY_OF_FACTORS[item.higestDiffrance.factor]+1;
      }
      else {
          FREQUENCY_OF_FACTORS[item.higestDiffrance.factor] = 1;
      }
  })

  let mostFrequent = undefined;
  const MOST_FREQUENT_WITH_SAME_WEATAGE = [];

  for (const key in FREQUENCY_OF_FACTORS) {
      if(!mostFrequent || mostFrequent.freq < FREQUENCY_OF_FACTORS[key]){
          mostFrequent = {factor: key, freq: FREQUENCY_OF_FACTORS[key]};
      }
      if(mostFrequent.freq < FREQUENCY_OF_FACTORS[key]){
          MOST_FREQUENT_WITH_SAME_WEATAGE.push({factor: key, freq: FREQUENCY_OF_FACTORS[key]});
      }
  }

  if(!mostFrequent){
      return `No Factors in this range`;
  }

  let finalString = `${incDec(mostFrequent.factor)} ${mostFrequent.factor}`;

  MOST_FREQUENT_WITH_SAME_WEATAGE.forEach(item => {;
      finalString += ` & ${incDec(item.factor)} ${item.factor}`;
  })
  
  return finalString;
}

function useIsIncreaseOrDicrease(){
  const  incDec = (factor) => {
      return ["Fun","Purpose","Growth"].indexOf(factor) === -1? "Decrease":"Increase"
  }
  
  return  [incDec];
}

function useCalculateStreakOfUser(allData,group,index) {
  if(!allData || !group){
    return [];
  }

  const userWithLowScore = group.histogramData.filter(item => item.XLScore < 0);

  const streaks = {};

  userWithLowScore.forEach(item => {
    for (let i = index; i > 0; i--) {
      const prevHistogramData = allData[i-1]?.histogramData.find(it => (it._id === item._id && it.XLScore < 0));
  
      if(prevHistogramData){
        if(streaks[item._id]){
          streaks[item._id] = streaks[item._id]+1;
        }
        else {
          streaks[item._id] = 1;
        }
      }
    }
  })

  const finalData = group.histogramData.map((item) => {
    for (const key in streaks) {
      if(key === item._id){
        return {...item,streak: streaks[key]}
      }
    }
    return item;
  })

  return finalData;
}

function useEventsChangeEffect({events,setstreamGraphChartData}){

  useEffect(() => {
    if(events){
      setstreamGraphChartData(prev => {
        const rem = prev.datasets.filter(dataSet => dataSet.label !== "Events");

        return {
          ...prev,
          datasets: [
            {
              type: "scatter",
              label: "Events",
              data: events.map(ev => {return {x: new Date(ev.date),y:-85,description: ev.description,tags: ev.tags}}),
              borderColor: "#db03fc",
              backgroundColor: "#db03fc",
              stack: 'combined',
            },
            ...rem
          ]
        }
      })
    }
  }, [events,setstreamGraphChartData]);

  return null;
}

function useHistoricalDataChangeEffect({historicalData,setstreamGraphChartData}){

  useEffect(() => {
    if(historicalData){
      setstreamGraphChartData(prev => {
        
        const rem = prev.datasets.filter(dataSet => [...XL_FACTORS,"XLScore"].indexOf(dataSet.label) === -1);
   
        return {
          ...prev,
          datasets: [
            ...rem,
             {
              type: 'line',
              label: "XLScore",
              data: historicalData.map(item => {return {x: new Date(item.year),y: item["XLScore"]}}),
              borderColor: FACTOR_COLORS["XLScore".toLocaleUpperCase()],
              backgroundColor: FACTOR_COLORS["XLScore".toLocaleUpperCase()],
              pointRadius: 0,
              fill: false,
              stack: 'combined',
            },
            ...XL_FACTORS.slice(0,6).map(factor => {
                return {
                  type: 'line',
                  label: factor,
                  data: historicalData.map(item => {return {x: new Date(item.year),y: item[factor]}}),
                  borderColor: FACTOR_COLORS[factor.toLocaleUpperCase()],
                  backgroundColor: FACTOR_COLORS[factor.toLocaleUpperCase()],
                  fill: true,
                  pointRadius: 0,
                }
            })
          ]
        }
      });
    }
  }, [historicalData,setstreamGraphChartData]);

  return null;
}

function MyChart({setCurrentIndex,setBrushSelection,chart,setChart,streamGraphChartData,events,setEventData,setEventId,setIsUpdateEvent,setIsOpenAddEventsModal}){
  const chartRef = useRef(null);
  

  function handeDoubleClick(){
      chart.resetZoom();
  }

  function handleOnClick(e){
      const points = chart.getElementsAtEventForMode(e,'nearest',{intersect: true},true);
      if(!points[0]){
        return;
      }
      const data = points[0];
      if(!data){
          return;
      }
      // if user click on event circle 
      if(data.datasetIndex === 0){
          const {index} = data;
          const event = events[index];
          setEventData(event);
          setEventId(event._id);
          setIsUpdateEvent(true);
          setIsOpenAddEventsModal(true);
          return;
      }
  }

  useEffect(() => {
      let chart = undefined;
      if(streamGraphChartData){
          
          const getOrCreateTooltip = (chart) => {
              let tooltipEl = chart.canvas.parentNode.querySelector('div');
            
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
                tooltipEl.style.borderRadius = '3px';
                tooltipEl.style.color = 'white';
                tooltipEl.style.opacity = 1;
                tooltipEl.style.pointerEvents = 'none';
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.transform = 'translate(-50%, 0)';
                tooltipEl.style.transition = 'all .1s ease';
            
                const table = document.createElement('table');
                table.style.margin = '0px';
            
                tooltipEl.appendChild(table);
                chart.canvas.parentNode.appendChild(tooltipEl);
              }
            
              return tooltipEl;
          };
            
          const externalTooltipHandler = (context) => {
              // Tooltip Element
              const {chart, tooltip} = context;
              const tooltipEl = getOrCreateTooltip(chart);

              if(!tooltip?.dataPoints){
                return;
              }
              if(!tooltip?.dataPoints[0]){
                return;
              }
              if(!tooltip?.dataPoints[0].dataset){
                return;
              }
              if(!tooltip?.dataPoints[0].dataset.label){
                return;
              }

              if(tooltip?.dataPoints[0]?.dataset?.label !== "Events" && tooltip?.dataPoints[0]?.dataset?.label !== "Action Challenges"){
                  return;
              }
            
              // Hide if no tooltip
              if (tooltip.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }
            
              // Set Text
              if (tooltip.body) {
                const titleLines = tooltip.title || [];
                const bodyLines = tooltip.body.map(b => b.lines);
            
                const tableHead = document.createElement('thead');
            
                titleLines.forEach((title,idx) => {
                  title = tooltip.dataPoints[idx].raw.x;
                  const tr = document.createElement('tr');
                  tr.style.borderWidth = 0;
            
                  const th = document.createElement('th');
                  th.style.borderWidth = 0;
                  const text = document.createTextNode(title);
            
                  th.appendChild(text);
                  tr.appendChild(th);
                  tableHead.appendChild(tr);
                });
            
                const tableBody = document.createElement('tbody');
                bodyLines.forEach((body, i) => {
                  const colors = tooltip.labelColors[i];
            
                  const span = document.createElement('span');
                  span.style.background = colors.backgroundColor;
                  span.style.borderColor = colors.borderColor;
                  span.style.borderWidth = '2px';
                  span.style.marginRight = '10px';
                  span.style.height = '10px';
                  span.style.width = '10px';
                  span.style.display = 'inline-block';
            
                  const tr = document.createElement('tr');
                  tr.style.backgroundColor = 'inherit';
                  tr.style.borderWidth = 0;
            
                  const td = document.createElement('td');
                  td.style.borderWidth = 0;

                  if(tooltip.dataPoints[i].dataset.label === "Events"){
                    const rawData = tooltip.dataPoints[i].raw;
                    const text = document.createTextNode(rawData.description);
                    td.appendChild(span);
                    td.appendChild(text);
                    rawData.tags.forEach(tag => {
                      const button = document.createElement("button");
                      button.innerText = tag;
                      button.style.backgroundColor = "blue";
                      button.style.marginLeft = "2px";
                      button.style.marginBottom = "2px";
                      button.style.padding = "0px 10px";
                      button.style.color = "#ffffff";
                      button.style.borderRadius = "5px"
                      td.appendChild(button)
                    });
                    tr.appendChild(td);
                    tableBody.appendChild(tr);
                  }
                  else if(tooltip.dataPoints[i].dataset.label === "Action Challenges"){
                    const rawData = tooltip.dataPoints[i].raw;
                    const text = document.createTextNode(rawData.actionChallenge);
                    td.appendChild(span);
                    td.appendChild(text);
                    rawData.steps.forEach(step => {
                      const button = document.createElement("button");
                      button.innerText = step.step;
                      button.style.backgroundColor = "blue";
                      button.style.marginLeft = "2px";
                      button.style.marginBottom = "2px";
                      button.style.padding = "0px 10px";
                      button.style.color = "#ffffff";
                      button.style.borderRadius = "5px"
                      td.appendChild(button)
                    });
                    tr.appendChild(td);
                    tableBody.appendChild(tr);
                  }
                  else {
                    const text = document.createTextNode(body);
            
                    td.appendChild(span);
                    td.appendChild(text);
                    tr.appendChild(td);
                    tableBody.appendChild(tr);
                  }
                });
            
                const tableRoot = tooltipEl.querySelector('table');
            
                // Remove old children
                while (tableRoot.firstChild) {
                  tableRoot.firstChild.remove();
                }
            
                // Add new children
                tableRoot.appendChild(tableHead);
                tableRoot.appendChild(tableBody);
              }
            
              const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
            
              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.background = '#ffffff';
              tooltipEl.style.color = '#000000';
              tooltipEl.style.border = '1px solid #000000';
              tooltipEl.style.left = positionX + tooltip.caretX + 'px';
              tooltipEl.style.top = positionY + tooltip.caretY + 'px';
              tooltipEl.style.font = tooltip.options.bodyFont.string;
              tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
          };


          chart = new ChartJS(chartRef.current, {
              type: 'bar',
              data: streamGraphChartData,
              options: {
                  scales: {
                  x: {
                                      type: 'time',
                                      ticks: {
                                      autoSkip: true,
                                      autoSkipPadding: 50,
                                      maxRotation: 0,
                                      },
                  },
                  y: {
                                      stacked: true,
                                      ticks: {
                                      callback: function(ctx,i){
                                          return i*10;
                                      }
                                      },
                                      min: -100,
                                      max: 100
                  }
                  },
                  maintainAspectRatio: false,
                  responsive: true,
                  plugins: {
                      title: {
                                          display: false,
                                          text: (ctx) => 'Test Chart'
                      },
                      tooltip: {
                          enabled: false,
                          position: 'nearest',
                          external: externalTooltipHandler
                      },
                      zoom: { 
                          limits: {
                              y: {min: -100,max: 100,minRange: 0}
                          },
                          pan: {
                              enabled: true,
                              modifierKey: 'ctrl',
                              mode: 'x',
                              onPanComplete: function(e){
                                  const chart = e.chart;
                                  const selection = {
                                      range: [new Date(chart.scales.x.min),new Date(chart.scales.x.max)]
                                  };
                                  if(chart.getZoomLevel() === 1){
                                      chart.update();
                                      setBrushSelection(selection);
                                      return;
                                  }
                                  const startTick = chart.scales.x.ticks[0];
                                  const endTick = chart.scales.x.ticks[chart.scales.x.ticks.length-1];
                                  const majorTicks = chart.data.labels.map((item,index) => {return {year: item,index}}).filter(item => moment(item.year).isBetween(moment(startTick.value),moment(endTick.value)));
                                  const highlightedTick = majorTicks[Math.round((majorTicks.length - 1) / 2)];
   
                                  if(highlightedTick){
                                      chart.options.plugins.annotation.annotations.line1.xMin = new Date(highlightedTick.year);
                                      chart.options.plugins.annotation.annotations.line1.xMax = new Date(highlightedTick.year);
                                      chart.update();
                                      setCurrentIndex(highlightedTick.index);
                                  }
                                  setBrushSelection(selection);
                              }
                          },
                          zoom: {
                              drag: {
                                  enabled: true,
                                  backgroundColor: "#00000055"
                              },
                              pinch: {
                                  enabled: true
                              },
                              mode: 'x',
                              onZoomComplete: function(e,f){
                                  const chart = e.chart;
                                  const selection = {
                                      range: [new Date(chart.scales.x.min),new Date(chart.scales.x.max)]
                                  };
                                  if(chart.getZoomLevel() === 1){
                                      chart.update();
                                      setBrushSelection(selection);
                                      return;
                                  }
                                  const startTick = chart.scales.x.ticks[0];
                                  const endTick = chart.scales.x.ticks[chart.scales.x.ticks.length-1];
                                  const majorTicks = chart.data.labels.map((item,index) => {return {year: item,index}}).filter(item => moment(item.year).isBetween(moment(startTick.value),moment(endTick.value)));
                                  const highlightedTick = majorTicks[Math.round((majorTicks.length - 1) / 2)];
   
                                  if(highlightedTick){
                                      chart.options.plugins.annotation.annotations.line1.xMin = new Date(highlightedTick.year);
                                      chart.options.plugins.annotation.annotations.line1.xMax = new Date(highlightedTick.year);
                                      chart.update();
                                      setCurrentIndex(highlightedTick.index);
                                  }
                                  setBrushSelection(selection);
                              }
                          }
                      },
                      legend: {
                                          labels: {
                                          boxWidth: 10,
                                          boxHeight: 10,
                                          font: 10
                                          },
                      },
                      annotation: {
                          annotations: {
                              line1: {
                                  type: 'line',
                                  yMin: -100,
                                  yMax: 100,
                                  xMin: streamGraphChartData.labels[streamGraphChartData.labels.length-1],
                                  xMax: streamGraphChartData.labels[streamGraphChartData.labels.length-1],
                                  borderColor: '#000000',
                                  borderWidth: 2,
                              }
                          }
                      }
                  }
              }
          });
          setChart(chart);
      }
      return () => {
          if(chart){
              chart.destroy();
          }
      }
  }, [streamGraphChartData,setEventData,setEventId,setIsUpdateEvent,setIsOpenAddEventsModal,events,setChart,setBrushSelection,setCurrentIndex]);


  if(!streamGraphChartData){
      return null;
  }


  return <canvas ref={chartRef} onDoubleClick={handeDoubleClick} onClick={handleOnClick}></canvas>
}

function Groups({groups,setGroups,selectedGroup,setSelectedGroup,setData}){
  const loggedInUser = useSelector(state => state.loggedInUser);
  // const [tree, setTree] = useState(null);
  // const [descentantUsers, setDescentantUsers] = useState(null);

  useEffect(() => {
    (async ()=> {
      const res = await getAllGroups();
      console.log(res);
      if(res.success){
        // const tree = makeTreeFromArray(res.groups,loggedInUser);
        // setTree(tree);
        setGroups(res.groups);
        // setDescentantUsers(res.descentantUsers);
      }
    })()
  }, [setGroups,loggedInUser]);

  if(!groups || groups?.length === 0 || loggedInUser.role === "user"){
    return null;
  }

  const handleChange = (e) => {
    const id = e.target.value;
    const group = groups.find(grp => grp._id === id);
    const users = new Set();

    visit(group);
    function visit(d){

      if(d.users){
        d.users.forEach(u => {
          users.add(u);
        })
      }

      if(d.children){
        d.children.forEach(child => {
          const c = groups.find(grp => grp._id === child._id);
          if(c){
            visit(c)
          }
        });
      }
    }

    setData({
      users: [...users].map(u => u._id),
      usersDetails: [...users],
      groupname: group.groupname,
      userSurveys: ""
    })
    setSelectedGroup(e.target.value)
  }

  // const onChange = (currentNode) => {
  //   const groupId = currentNode.value.split("---")[0];
  //   const grp = descentantUsers.find(u => u.groupId === groupId);

  //   if(grp.descendantUsers){
  //     const users = grp.descendantUsers.filter(u => u);
  //     if(users && users.length > 0){
  //       setData({
  //         users: users.map(u => u._id),
  //         usersDetails: users,
  //         groupname: currentNode.groupname,
  //         userSurveys: ""
  //       });
  //     }
  //   }

  //   if(currentNode.users && currentNode.users.length > 0){
  //     setData({
  //       users: currentNode.users.map(u => u._id),
  //       usersDetails: currentNode.users,
  //       groupname: currentNode.groupname,
  //       userSurveys: ""
  //     });
  //   }
  // }

  // if(!tree){
  //   return null;
  // }

  // const data = {
  //   label: 'View All Groups',
  //   value: 'root',
  //   children:tree
  // }

  return (<>
    <div className={`pt-1 [.radio-select]:bg-blue-300`}> 
      {/* <DropdownTreeSelect 
        data={data} 
        onChange={onChange} 
        mode="radioSelect"
        texts={{
          placeholder: "Select Group"
        }}
      /> */}
      <select value={selectedGroup} onChange={handleChange} className='w-full border border-secondry-color/20 bg-transparent rounded-md p-2'>
        <option value="" >Select a Group</option>
        {
          groups && groups.map((grp,idx) => <option key={idx} value={grp._id}>{grp.groupname}</option>)
        }
      </select>
    </div>
  </>)
}

function CircularProgressBar({XLScore_100_100}){
  const progressRef = useRef(null);

  
  useEffect(() => {
    const progress = new ChartJS(progressRef.current,{
        type: "doughnut",
        data: {
          labels: [
            'Gray',
            'Blue'
          ],
          datasets: [{
            label: 'XLScore',
            data: [mapper__100_100_to_0_100(XLScore_100_100), 100-mapper__100_100_to_0_100(XLScore_100_100)],
            backgroundColor: [
              getBrainColor(mapper__100_100_to_0_100(XLScore_100_100)),
              '#cccccc',
            ],
            borderWidth: 0,
          }]
        },
        options: {
          plugins: {
            legend: {
              display: false
            },
            tooltip: false,
          },
          rotation: 90,
          cutout: '80%' // gap inside the circle 
        },
        plugins: [
        //   {
        //   id: "doughnutLabel",
        //   beforeDatasetDraw(chart,args,pluginOptions){
        //     const {ctx} = chart;
        //     const xCord = chart.getDatasetMeta(0).data[0].x;
        //     const yCord = chart.getDatasetMeta(0).data[0].y;
        //     ctx.font = "bold 12px sans-serif";
        //     ctx.fillStyle = "rgba(54,162,235,1)";
        //     ctx.textAlign = "center";
        //     ctx.textBaseline = "middle";
        //     ctx.fillText("XLScore",xCord,yCord);
        //     ctx.font = "bold 8px sans-serif";
        //     ctx.fillStyle = "rgba(54,162,235,1)";
        //     ctx.textAlign = "center";
        //     ctx.textBaseline = "middle";
        //     ctx.fillText("10",xCord,yCord+10);
        //   }
        // }
      ]
    })
    
    return () => {
      if(progress){
        progress.destroy();
      }
    };
  }, [XLScore_100_100]);



  return <canvas ref={progressRef}/>
}
















