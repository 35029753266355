import {fetchRequest} from "../../utils";
import { 
    GET_SCHEDULE_TIMES,
    DOMAIN_NAME
} from "../../constants";



export const getAllScheduledTimes = () => async (dispatch) =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/surveyperiodfrequencyandtime/all`,"GET",{});

        // console.log(res.surveyPeriodFrequencyAndTimes);
        if(res.success){
           dispatch({type: GET_SCHEDULE_TIMES ,payload: res.surveyPeriodFrequencyAndTimes});
        }
        
    } catch (error) {
        // dispatch({type: USER_RESET ,payload: ''});
    }
}


export const getAllScheduledTimesForGroups = async () => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/surveyperiodfrequencyandtime/all`,"GET",{});

        return res;
        
    } catch (error) {
        // dispatch({type: USER_RESET ,payload: ''});
    }
}


export const addScheduledTime = async (data) => {

    try {

        const res = await fetchRequest(`${DOMAIN_NAME}/surveyperiodfrequencyandtime`,"POST",data);
       
        return res;

    } catch (error) {
        // dispatch({type: USER_RESET ,payload: ''});
    }
}


export const updateScheduledTime = async (timingId,data) =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/surveyperiodfrequencyandtime/${timingId}`,"PUT",data);

        return res;

    } catch (error) {
        // dispatch({type: USER_RESET ,payload: ''});
    }
}



export const deleteScheduledTime = async (timingId) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/surveyperiodfrequencyandtime/${timingId}`,"DELETE",{});

    return res;
}


export const getScheduledTimeByGroupId = async (groupId) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/surveyperiodfrequencyandtime/${groupId}`,"GET",{});

    return res;
}


export const getScheduledTimeById = async (id) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/surveyperiodfrequencyandtime/getbyid/${id}`,"GET",{});

    return res;
}
