import React, { useEffect, useState } from 'react';
import {
  // reward,
  checkMark
} from "../../assets";
import { getTodaysStreakOfUser,setImprovementStreak } from '../../Store/actions';
import {NUMBER_WITH_DAYS} from "../../constants";
import {flame} from "../../assets";
import {useDispatch} from "react-redux";
import { PopupContainer } from '../../layout';
import { SecondryButton } from '../SecondryButton';

function StreakModal({isOpen,handleOk,userId,setIsOpen}) {
  const [streakData, setStreakData] = useState(null);
  const dispatch = useDispatch();



  useEffect(() => {
    (async ()=> {
      if(userId && isOpen === true){
        const res = await getTodaysStreakOfUser(userId);
        console.log(res);
        if(res.success){
          const streakDays = res.streakDays.map(d => new Date(d).getDay());
          const uniqueStreakDays = [...new Set(streakDays)].filter(day => day <= new Date().getDay());                 
          const selectedDays = res.selectedDays;
          const streak = res.streak;
          setStreakData({
            streak,
            selectedDays,
            streakDays: uniqueStreakDays
          });
          dispatch(setImprovementStreak(streak));
        }
        else {
          setStreakData(null);
          setIsOpen(false);
        }
      }
    })()
  }, [userId,isOpen,dispatch,setIsOpen]);

  if(!isOpen || !streakData){
    return null;
  }  

  const start = streakData.streakDays[0];
  const end = streakData.streakDays[streakData.streakDays.length-1];
  const sdLen = streakData.selectedDays.length;
  const dayWidth = 100/sdLen;
  const flameWidth = 50+((streakData.streak <= 30? streakData.streak:30)*10);


  return (<>
      <PopupContainer>
          <div className="mb-4 flex flex-col items-center">
              {
                streakData.streak !== 0 && (<>
                    <div className="flex justify-center items-end">
                        <img src={flame} alt="flame" width={flameWidth} height={flameWidth}/>
                        <h1 style={{WebkitTextStroke: "3px black",WebkitTextFillColor: "transparent",WebkitTextCombine: false}} className='text-[30px] mb-2 absolute leading-none text-center text-blue-200 mb-0'>{streakData.streak}</h1>
                    </div>
                    <h2 className='text-center text-[25px] font-bold text-secondry-color'>day streak!</h2>
                    <p className='mb-0 text-secondry-color text-[14px] text-center' >You're on fire! Keep the self improvement flame lit every day!</p>
                    
                </>) 
              }
              
              {
                streakData.streak === 0 && (<>
                <img src="https://media.tenor.com/8McIGu0Tf_QAAAAi/fire-joypixels.gif" alt="flame" width={30} height={30}/>
                <p className='mb-0 text-green-600 text-[14px] text-center mt-3'>Nice! You just ignited the flame of self-improvement. Keep fanning it every day and watch it grow.</p>
                </>)
              }
          </div>

          <div className="border-[2px] rounded p-2 mb-14">
            <div className="flex justify-between w-full">
              {
                streakData.selectedDays.map((day,i) => (
                  <div kay={i} style={{width: `${dayWidth}%`,color: `${day === end? "green":""}`}} className="font-bold text-[16px] h-[30px] flex justify-center items-center">{NUMBER_WITH_DAYS[day]}</div>
                ))
              }
            </div>

            <div className="flex justify-between w-full border-b pb-2 relative text-secondry-color">
              {
                streakData.selectedDays.map((d,i) => {
                  if(end === start && d === start){
                    return <div style={{width: `${dayWidth}%`,borderTopLeftRadius: `15px`,borderBottomLeftRadius: `15px`,borderTopRightRadius: `15px`, borderBottomRightRadius: `15px`}} kay={i} className="h-[30px] flex justify-center items-center">
                              <div className="w-[30px] h-[30px] rounded-full flex justify-center items-center  bg-green-600">
                                <img src={checkMark} className="w-[60%] h-[60%]" alt="check-mark"/>
                              </div>  
                           </div>
                  }
                  if(end !== start && d === start){
                    return <div style={{width: `${dayWidth}%`,borderTopLeftRadius: `15px`,borderBottomLeftRadius: `15px`}} kay={i} className="h-[30px] flex justify-center items-center bg-green-600">
                              <div className="w-[30px] h-[30px] rounded-full flex justify-center items-center">
                                <img src={checkMark} className="w-[60%] h-[60%]" alt="check-mark"/>
                              </div>  
                           </div>
                  }
                  if(d === end){
                    return <div style={{width: `${dayWidth}%`,borderTopRightRadius: `15px`, borderBottomRightRadius: `15px`}} kay={i} className="h-[30px] flex justify-center items-center bg-green-600">
                              <div className="w-[30px] h-[30px] rounded-full flex justify-center items-center">
                                <img src={checkMark} className="w-[60%] h-[60%]" alt="check-mark"/>
                              </div>  
                           </div>
                  }
                  if(d > start && d < end){
                    return <div style={{width: `${dayWidth}%`}} kay={i} className="flex-1 h-[30px] flex justify-center items-center bg-green-600">
                               <div className="w-[30px] h-[30px] rounded-full flex justify-center items-center">
                                  <img src={checkMark} className="w-[60%] h-[60%]" alt="check-mark"/>
                               </div> 
                           </div>
                  }
                  return <div style={{width: `${dayWidth}%`}} kay={i} className={`h-[30px] flex justify-center items-center`}>
                            <div className="w-[30px] h-[30px] bg-gray-200 rounded-full"/>
                         </div>
                })
              }
            </div>

            <div className="pt-2">
              <p className='mb-0 text-center text-secondry-color'>Track your XL score every day to build your continuous improvement streak</p>
            </div>
          </div>

          <SecondryButton style={{padding: "12px 0"}} onClick={handleOk}>OK</SecondryButton>
      </PopupContainer>
  </>)
} 

export {StreakModal};