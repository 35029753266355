import {fetchRequest} from "../../utils";
import { 
    DOMAIN_NAME
} from "../../constants";


export const getActionChallengeOfUser = async ({userId}) => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/action-challenge/${userId}`,"GET", {})
        
        return res;

    } catch (error) {
        console.log(error);
    }
}

export const getActionChallengeOfTeam = async ({userId}) => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/action-challenge/team/${userId}`,"GET", {})
        
        return res;

    } catch (error) {
        console.log(error);
    }
}

export const getActionChallengeById = async ({user,actionChallengeId}) => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/action-challenge/${user}/${actionChallengeId}`,"GET", {})
        
        return res;

    } catch (error) {
        console.log(error);
    }
}

export const createActionChallenge = async (data) => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/action-challenge`,"POST", data)
        
        return res;

    } catch (error) {
        console.log(error);
    }
}

export const completeStep = async ({actionChallengeId,stepId}) => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/action-challenge/${actionChallengeId}/step/${stepId}`,"PUT", {})
        
        return res;

    } catch (error) {
        console.log(error);
    }
}

export const completeActionChallenge = async ({actionChallengeId}) => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/action-challenge/${actionChallengeId}`,"PUT", {})
        
        return res;

    } catch (error) {
        console.log(error);
    }
}

export const deleteActionChallenge = async ({actionChallengeId}) => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/action-challenge/${actionChallengeId}`,"DELETE", {})
        
        return res;

    } catch (error) {
        console.log(error);
    }
}