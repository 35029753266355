import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { 
  createPriPostSurvey,
  getSurveyTitleByUserIdAndSurveyId,
  updateLoggedInUser
 } from '../../Store/actions';
import { toast } from 'react-toastify';
import {
  Loading, 
  PrimaryButton,
  SurveyUI
} from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import {Main} from "../../layout";
import { replaceQuestionsPlaceholders } from '../OnBoarding/components/SetYourBaseline';
import { Header, Wrapper} from '../OnBoarding/components';



function SetYourBaseline({setScreen}) {
  const loggedInUser = useSelector(state => state.loggedInUser);


  return (
        <Main>
            <Wrapper>
                <Index setScreen={setScreen} preSurveyId={loggedInUser.preSurveyId} userId={loggedInUser._id}/>
            </Wrapper> 
        </Main> 
  )
}

export default SetYourBaseline;

function Index({setIsShow}) {
  const { userId, surveyId } = useParams();
  const [survey,setSurvey] = useState(null);
  const [user,setUser] = useState(null);
  const [questions,setQuestions] = useState(null);
  const [answers, setAnswers] = React.useState(null);
  const [isPreDone, setIsPreDone] = React.useState(false);
  const [isSurveyDone, setIsSurveyDone] = React.useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const loggedInUser = useSelector(state => state.loggedInUser);
  const dispatch = useDispatch();
  const [organizationName, setOrganizationName] = useState("");
  const location = useLocation();

  const handleSubmit = async (lastAns,lastFactor,starttime) => {
    setIsSubmiting(true);
    const myAnswers = [...answers,{rating: lastAns,XLFactor: lastFactor,starttime,endtime: new Date().getTime()}];
    const data = {
      answers: myAnswers,
      surveytitle: survey._id,
      user: user._id 
    };
    
    const res = await createPriPostSurvey(data);
    // console.log(res);
    if(res.success){
      if(location.state?.isPostSurvey){
        dispatch(updateLoggedInUser({isPostSurveyDone: true}));
      }
      if(setIsShow){
        dispatch(updateLoggedInUser({isPreSurveyDone: true}));
      }
      dispatch(updateLoggedInUser({isPreSurveyDone: true}));
      setIsSurveyDone(true);
    }
    setIsSubmiting(false);
  }

  useEffect(() => {
    (async()=>{
      const tUserId = userId || loggedInUser._id;
      const tSurveyId = surveyId || loggedInUser.preSurveyId;
      const res = await getSurveyTitleByUserIdAndSurveyId(tUserId, tSurveyId);
      if(res.success){
        setSurvey(res.surveyTitle);
        setUser(res.user);
        if(res.user.organizationName){
          const surveyName = res.user.activities[0].category;
          const title = res.user.activities[0].name;
          const questions = replaceQuestionsPlaceholders(res.surveyTitle.questions,res.user.organizationName,res.user.personalImprovementGoals,res.user.personalImprovementObstacles,surveyName,title);
          setQuestions(questions);
        }
      }
      else {
        toast.error("Something Went Wrong!");
      }
    })()
  }, [userId,surveyId,loggedInUser]);


  const handleBack = () => {
    setIsPreDone(false);
  }

  if(!survey || !user){
    return <div className="w-full h-[93vh]"><Loading/></div>
  }

  if(!questions){
    return <EnterOrganizationName survey={survey} setQuestions={setQuestions} organizationName={organizationName} setOrganizationName={setOrganizationName}/>
  }

  if(!isPreDone){
    return <PreSurvey user={user} setIsPreDone={setIsPreDone} survey={survey}/>
  }

  if(isSurveyDone){
    return <AfterSurveyPage survey={survey} user={user} setIsShow={setIsShow}/>
  }

  return (
    <>
      <Header handleBack={handleBack} title="Set Your Baseline"/>
      <div className='h-[20px]'/>
      <SurveyUI isSubmiting={isSubmiting} handleSubmit={handleSubmit} questions={questions} answers={answers} setAnswers={setAnswers}/>
    </>
  )
}


function PreSurvey({setIsPreDone}){


  const handleNext = () => {
    setIsPreDone(true);
  }

  
  return (
    <> 
                <Header title="Set Your Baseline"/>
                <p className='text-secondry-color mb-6'>Let's Personalise Your Journey</p>
                <p className='text-secondry-color mb-6'>We need some insights from you to tailor your experience and help you track your growth with XLRate</p>
                <p className='text-secondry-color mb-3 font-bold'>Why Provide Baseline Data?</p>
                <ul className='list-disc mb-6 pl-6 text-secondry-color'>
                  <li className=''><span className='font-bold'>Personalised Feedback:</span> Get tailored insights and actionable recommendations</li>
                  <li className=''><span className='font-bold'>Track Progress:</span> Measure your improvement and celebrate milestones</li>
                  <li className=''><span className='font-bold'>Optimise Performance:</span> Identify and enhance areas of growth</li>
                </ul>
                <p className='text-secondry-color mb-6'>Ready to start? Let’s set your baseline!</p>
              
                <PrimaryButton style={{padding: "12px 0"}} onClick={handleNext}>
                  Start Now
                </PrimaryButton>
    </>
  )
}


function AfterSurveyPage(){
  const navigator = useNavigate();

  
  const handleNext = async () => {
    navigator(`/my-dashboard`);
    return;
  }


  return (<>
    <>

            <p className='text-secondry-color mb-6'>Thank You!</p>

            <p className='text-secondry-color mb-6'>You've completed your baseline setting</p>
          
            <p className='text-secondry-color mb-3 font-bold'>Your personalised journey with XLRate begins now. Here’s what’s next</p>
            <ul className='list-disc mb-6 pl-6 text-secondry-color'>
              <li className=''>How XLRate works</li>
              <li className=''>Set Goals: Tailored goals based on your input</li>
              <li className=''>1st CogniPulse</li>
              <li className=''>Your Personal Growth Dashboard</li>
            </ul>

          
            <PrimaryButton style={{padding: "12px 0"}} onClick={handleNext}>
              Next
            </PrimaryButton>
    </>
  </>)
}


function EnterOrganizationName({setQuestions,survey,organizationName,setOrganizationName}){
  const dispatch = useDispatch();
  const loggedInUser = useSelector(state => state.loggedInUser);

  const handleClick = () => {
    dispatch(updateLoggedInUser({organizationName}));
    const surveyName = loggedInUser.activities[0].category;
    const title = loggedInUser.activities[0].name;
    const questions = replaceQuestionsPlaceholders(survey.questions,loggedInUser.organizationName,loggedInUser.personalImprovementGoals,loggedInUser.personalImprovementObstacles,surveyName,title);
    setQuestions(questions);
  }

  return (
    <>

           <div className='flex items-center justify-center w-full h-[calc(100vh-64px)]'>
              <div className='w-[500px] max-w-full w-full p-6 rounded-lg shadow-lg  md:mt-0 sm:max-w-md sm:p-8'>
                  <div className='mb-4'>
                    <img className='w-[400px] max-w-full' src='https://res.cloudinary.com/dsnjr85ys/image/upload/v1704211715/Copy_XLRate_new_logo_landscape_gewvxp.png' alt='logo'/>
                  </div>
                  
                  <p className='mb-6 text-secondry-color'>We'd Like to Personalise Your Experience</p>
                  {/* <p className='mb-6 text-secondry-color'>This helps us tailor your experience and provide relevant organisational insights</p> */}

                  <div className='flex flex-col text-secondry-color mb-8'>
                    <label htmlFor='organizationName' className='mb-2 font-bold' >Please enter the name of your organisation</label>
                    <input id='organizationName' placeholder='e.g. Acme Corporation' className='bg-secondry-color/20 border border-secondry-color rounded px-3 py-1' type='text' value={organizationName} onChange={(e)=> setOrganizationName(e.target.value)}/>
                    <small className='text-secondry-color/50 mt-1'>Your organisation name helps us personalise your experience. Your data is secure</small>
                  </div>
                
                  <PrimaryButton style={{padding: "12px 0"}} onClick={handleClick} disabled={!organizationName? true:false}>
                      Submit Organisation Name
                  </PrimaryButton>
              </div>
           </div>
    </>
  )
}



