import { useState,useEffect} from 'react';
import { Disclosure} from '@headlessui/react';
import { 
  NavLink, 
  useLocation, 
  useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActionChallengeOfTeam,
  getTodaysUnCompletedSurveyLinkOfUser,
  logoutUser,
  setIsMenuOpen, 
  getActionChallengeOfUser,
  setNumberOfActionChallenges,
  createTestUser
} from "../Store/actions";
import {MdSelfImprovement} from "react-icons/md";
import {IoMdRocket} from "react-icons/io";
import {BsLightningFill} from "react-icons/bs";
import {stagger, useAnimate} from "framer-motion";
import { GiStairsGoal } from "react-icons/gi";
import { IoWarningOutline } from "react-icons/io5";
import { APP_VERSION } from '../constants';
import { toast } from 'react-toastify';
import { firstLetterUpperCase } from '../utils';


const navigation = [
  { name: 'Your Dashboard', href: '/my-dashboard', current: false, user: true,admin: true,manager: true},
  { name: 'Dashboard Guide', href: '/my-dashboard', current: false, user: true,admin: true,manager: true,payload: {isShowDashboard: true}},
  { name: 'My Profile', href: '/profile', current: true,admin: true,manager: true},
  { name: 'Leadership Dashboard', href: '/dashboard/management', current: true,admin: true,manager: true},
  { name: 'Manage Client Users', href: '/admin/manage/users', current: true,admin: true },
  { name: 'Manage Survey', href: '/admin/manage/surveytitle', current: false,admin: true },
  { name: 'Manage Universal Survey', href: '/admin/manage/universal/surveytitle', current: false,admin: true },
  { name: 'Manage Groups', href: '/admin/manage/groups', current: false ,admin: true,manager: true},
  { name: 'Completed Surveys', href: '/admin/surveys/completed-surveys', current: false,admin: true },
  { name: 'Un Regular Com Surveys', href: '/admin/un-regular-completed-surveys', current: false,admin: true },
  { name: 'Add Client Users', href: '/admin/add/user', current: false,admin: true },
  { name: 'Waiting List', href: '/admin/waiting-list', current: false,admin: true },
  { name: 'Edit Activity Title', href: '/user-survey-selection', current: false ,user: true,admin: true,manager: true,payload: "updateOnlyActivity"},
  { name: 'Purpose & Goals', href: '/purpose-and-goal', current: false ,user: true,admin: true,manager: true},
  { name: 'Manage Notifications', href: '/manage/notifications', current: false ,user: true,admin: true,manager: true}
];


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function Navbar() {
  const loggedInUser = useSelector(state=> state.loggedInUser);
  const previousRoute = useSelector(state => state.previousRoute);


  useEffect(() => {
    if(loggedInUser){
      (async () => {
            const res = await getTodaysUnCompletedSurveyLinkOfUser(loggedInUser._id);

            if(res.success && res.surveyLinks.length > 0){
                const url = res.surveyLinks[0].url.replace("https://xlrate.10x.systems","");
                if(previousRoute && !previousRoute.includes("/survey")){
                    return navigator(previousRoute);
                }
                return navigator(`${url}`);
            }
      })()
    }
  }, [loggedInUser,previousRoute]);
  
  return (
    <>
    <Disclosure as="nav" className="bg-primary-color fixed w-full top-0 z-[500] border-b border-secondry-color/20">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          <Left loggedInUser={loggedInUser}/>
          <Middle/>
          <Right loggedInUser={loggedInUser}/>
        </div>
      </div>
    </Disclosure>
    </>
  )
}



function Left({loggedInUser}){
  const dispatch = useDispatch();
  const isMenuOpen = useSelector(state => state.globalStates.isMenuOpen);
  const scope = useMenuAnimation(isMenuOpen);
  const [isAddingGroup, setIsAddingGroup] = useState(false);


  const handleLogOut = (e) => {
    dispatch(logoutUser());
    dispatch(setIsMenuOpen(false));
  }
  
  const handleHemburgerClick = (e) => {
    dispatch(setIsMenuOpen(!isMenuOpen));

    if(!isMenuOpen){
      return;
    }
  }
  
  const menuItemsHoveredClick = (e) => {
    dispatch(setIsMenuOpen(false));
  }


  const handleReset = async () => {
    
    setIsAddingGroup(true);
    const res = await createTestUser();
    setIsAddingGroup(false);
    if(res.success){
      console.log(res);
      toast.success("User Reset Successfully!");
    }
    else {
      toast.error("Unable to Reset!");
    }
  }


  return(<>
        <div className="flex items-center relative">
          <img id='plus' onClick={handleHemburgerClick} className='w-10 h-10 cursor-pointer hover:bg-gray-300' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRUlEQVR4nO3aMU4CQRjF8X8CjZZSKZZqqdhKrAC5AN5BE4nX8Bp4GxIDt1A7qLBTM2Eqk81OwYdPeb/k9d+bZZfdnQUzMzMzMzOzEkPgGZgCLyKZ5pkGBHsEPoEv0aTZxlHlW8CHQMm6pBkPIhagL1CuNGnWjesIFCvNecQCNIGZQLm6pItigyCn4ouQyp8QrAFcACPgViSjPFPYkTczM1s7Bu6BJ7GkmdoEuwGWAjc8VVlGPhLvA+8CJevyCuxFLMC1QLnSdCMWoCtQrDRXUafA2y6fAuQLzEKgZFXSbD2CHQF3An97P5NmOowub2Zmu66Z9wjUXop2tvFS9AyYC9zwVGWWX92HHfm5QMmSRQj5JVwKlCtN2iPYuL5AsV/dHG39ke3xVdT2OPnjA/UPJB4INgAmgp/ITLbxKGxmZmZmZmb8A9+32md246d89AAAAABJRU5ErkJggg==" alt='menu'/>
          {/* <img id='plus' onClick={handleHemburgerClick} className='w-7 h-7 cursor-pointer hover:bg-gray-300' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAlklEQVR4nO3YQQ6EIBBE0X885f4nkINgIOOOjrhw0pn5L2EnJEZLSkDSUwWoQLsZ/Zo9ydyplcWucSSZO9Uejgxzp7wRfCKDr1bEjGBG3slI/ZUNcf9cuLLYlmSu9E3FGk+ur1azxuP/SGf7jZgRzMhgRiLWeKzx+g/FGo81vnNDjFgasTQOZiRiRkiWkeppPJ7GSyR1Agev6ZGS8X3hAAAAAElFTkSuQmCC" alt=''/> */}
          {/* <img id='plus' onClick={handleHemburgerClick} className='p-[5px] rounded-md border border-secondry-color/20 text-white cursor-pointer hover:bg-gray-300' src={menu} alt='menu'/> */}

          {/* {
            isMenuOpen && <> */}
              <nav
               ref={scope}
               style={{display: "none"}}
               className={`transition-all absolute top-[40px] rounded menu menu-compact dropdown-content w-52`}>
                <div id='ul' className='flex flex-col gap-1 bg-primary-button-color shadow rounded-md p-2 mt-4 max-h-[90vh] overflow-auto'>
                  {
                    navigation.map((item) => {
                        return ((item.admin && loggedInUser?.role === "admin") || (item.manager && loggedInUser?.role === "manager") || (item.user && loggedInUser?.role === "user")) && <NavLink 
                                                          key={item.name}
                                                          to={item.href}
                                                          state={{payload: item.payload,time: new Date().getTime()}}
                                                          onClick={menuItemsHoveredClick}
                                                          className={classNames(
                                                            item.name === isMenuOpen ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                            `px-3 py-2 rounded-md text-sm font-medium no-underline li`
                                                          )}
                                                          // onMouseEnter={menuItems}
                                                          > 
                                                            {item.name}
                                                          </NavLink>
                    })
                  }

                    {
                      (loggedInUser && loggedInUser.role) && <button
                      onClick={handleLogOut}
                      // onMouseEnter={menuItems}
                      className="li bg-secondry-button-color text-gray-800 hover:bg-secondry-button-color/80 hover:text-white px-3 py-2 rounded-md text-sm font-medium no-underline"
                      >
                        Logout 
                      </button>
                    }
                    {
                      (loggedInUser && loggedInUser.role === "admin") && <button
                      onClick={handleReset}
                      id='reset-test-user'
                      className="li border border-gray-200 text-gray-200 px-3 py-2 rounded-md text-sm font-medium no-underline"
                      >
                        {isAddingGroup? "Please wait...":"Onboarding User demo"} 
                      </button>
                    }


                    <span className='text-white/50 p-1 text-[10px] font-bold ml-1 rounded-lg'>v{APP_VERSION.replace(/\{\{(.*?)\}\}/, '$1')}</span>                    
                </div>
              </nav>
            {/* </>
          } */}
        </div>
  </>)
}

function Middle(){
  return(<>
    <div className='order-[10] md:order-[0] px-2'>
      <NavLink to="/" className="no-underline text-white text-lg">
          {/* XLRate */}
      </NavLink>
    </div>
  </>)
}

function Right({loggedInUser}){
  return(<>
  <div>
    {
      !loggedInUser? <>
      {
        window.location.pathname !== "/terms-and-conditions" && <LoginAndSignupBtns/>
      }
      </>:<>
      <ScoreAndUserInfo loggedInUser={loggedInUser}/>
      </> 
    }
  </div>
  </>)
}

function LoginAndSignupBtns(){


  return(<>
                   <NavLink 
                  to="/login"
                  className="bg-secondry-button-color text-gray-800 hover:secondry-button-color/80 hover:text-white px-3 py-2 rounded-md text-sm font-medium no-underline"
                  > 
                    Login
                  </NavLink>
                   {/* <NavLink 
                  to="/signup"
                  className="bg-brand-aqua-green ml-3 text-gray-800 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium no-underline"
                  > 
                    Signup
                  </NavLink> */}

  </>)
}


function ScoreAndUserInfo({loggedInUser}){
  const PIPoints = useSelector(state => state.globalStates.PIPoints);
  const IStreak = useSelector(state => state.globalStates.IStreak);
  const [scope] = useAnimate();
  const [currentIStreak] = useState(IStreak);
  const [currentPIPoints] = useState(PIPoints);
  const [currentPILevel] = useState(parseFloat((PIPoints || 0)/500+1).toFixed(2));
  // const {userStats} = useMixPanelEvents(loggedInUser?._id);



  
  return(<>
      <div className='text-secondry-color flex items-center md:gap-4'>
        <div className=''>
                {
                  loggedInUser && <h6 
                  // onMouseEnter={(e)=>userStats(e,"User Name")} 
                  className='mb-0 text-center hidden md:block'>{firstLetterUpperCase(loggedInUser.forename)} {firstLetterUpperCase(loggedInUser.surname)}</h6>
                }
        </div>
        <div ref={scope} className='flex items-center gap-2'>
            <OutstandingWarning/>
            <ActionChallenge loggedInUser={loggedInUser}/>
            <div 
            // onMouseEnter={(e)=>userStats(e,"Personal Improvement Points")} 
            className="relative item flex items-center" 
            title="Personal Improvement Points">
                    <span id='personal-improvement-points' className="inline-block item flex items-center rounded-full">
                          <span><MdSelfImprovement/></span>
                          <span>{currentPIPoints}</span>
                     </span>
            </div>
            <div 
            // onClick={(e)=>userStats(e,"Personal Improvement Level")} 
            // onMouseEnter={(e)=>userStats(e,"Personal Improvement Level")} 
            className="relative item flex items-center" 
            title="Personal Improvement Level">
                    <span 
                      id='personal-improvement-level'
                      className="inline-block item flex items-center rounded-full">
                          <span><IoMdRocket/></span>
                          <span>{currentPILevel}</span>
                      </span>
            </div>
            <div 
            // onClick={(e)=>userStats(e,"Improvement Streak")} 
            // onMouseEnter={(e)=>userStats(e,"Improvement Streak")} 
            className="relative item flex items-center" 
            title="Improvement Streak">
                      <span 
                      id='improvement-streak'
                      className="item flex items-center rounded-full">
                          <span><BsLightningFill/></span>
                          <span>{currentIStreak}</span>
                      </span>
            </div>
        </div>
      </div>
  </>)
}


function ActionChallenge({loggedInUser}) {
  const numberOfActionChallenges = useSelector(state => state.numberOfActionChallenges);
  const navigator = useNavigate();
  const dispatch = useDispatch();
  

  const handleClick = () => {
    navigator("/action-challenges");
  }


  useEffect(() => {
    (async ()=> {
          let len = 0;
          const res = await getActionChallengeOfUser({userId: loggedInUser._id});
          const res1 = await getActionChallengeOfTeam({userId: loggedInUser._id});

          if(res.success){
            len += res.actionChallenges.length;
          }
          if(res1.success){
            len += res1.actionChallenges.length
          }
          dispatch(setNumberOfActionChallenges(len));
    })()
  }, [loggedInUser,dispatch]);

  return (
    <>
    <div id="introSelector12" className="relative item flex items-center" title="Action Challenges">
        <span onClick={handleClick} id='Action Challenges' className="cursor-pointer inline-block item flex items-center gap-1 rounded-full">
              <span><GiStairsGoal /></span>
              <span>{numberOfActionChallenges}</span>
         </span>
    </div>
    </>
  )
}


function OutstandingWarning(){
      const loggedInUser = useSelector(state => state.loggedInUser);
      const [outstandingSurveyLinks, setoutstandingSurveyLinks] = useState(null);
      const location = useLocation();
      const navigator = useNavigate();


      const handleClick = () => {
        if(!outstandingSurveyLinks){
          return;
        }
        navigator(outstandingSurveyLinks[0],{state: {isPostSurvey: true}});
      }

      useEffect(() => {
        (async ()=> {
          const res = await getTodaysUnCompletedSurveyLinkOfUser(loggedInUser._id);

          if(res.success){
            const urls = res.surveyLinks.map(sl => sl.url.replace("https://xlrate.10x.systems",""));
            // console.log(loggedInUser.preSurveyId);
            // if post survey date is today then redirect user to do post survey 
            if(!loggedInUser.isPostSurveyDone && loggedInUser.postSurveyDate && new Date(loggedInUser.postSurveyDate) < new Date()){
              const url = `/pre-post-survey/${loggedInUser._id}/${loggedInUser.postSurveyId}`;
              urls.push(url);
            }

            if(!loggedInUser.isPreSurveyDone){
              const url = `/pre-post-survey/${loggedInUser._id}/${loggedInUser.preSurveyId}`;
              urls.push(url);
            }

            if(urls.length){
              setoutstandingSurveyLinks(urls);
            }
            else {
              setoutstandingSurveyLinks(null);
            }
          }
        })()
      }, [loggedInUser,location.pathname]);      

      if(!outstandingSurveyLinks){
        return null;
      }

      return (
        <>
          <div className="relative item flex items-center" title="Outstanding">
              <span onClick={handleClick} id='Action Challenges' className="cursor-pointer inline-block item flex items-center gap-1 rounded-full">
                    <span><IoWarningOutline className='text-secondry-color font-bold'/></span>
                    <span>{outstandingSurveyLinks?.length}</span>
              </span>
          </div>
        </>
      )      

}

const staggerMenuItems = stagger(0.05, { startDelay: 0.05 });

function useMenuAnimation(isOpen) {
  const loggedInUser = useSelector(state=> state.loggedInUser);
  const [scope, animate] = useAnimate(null);

  useEffect(() => {
    if(scope !== null && loggedInUser){
      if(isOpen){
        animate(scope.current,{display: "unset"});
      }

      animate(
        "#ul",
        {
          clipPath: isOpen
            ? "inset(0% 0% 0% 0% round 10px)"
            : "inset(0% 100% 100% 0% round 10px)",
        },
        {
          type: "spring",
          bounce: 0,
          duration: 0.5,
        }
      );

      animate(
        ".li",
        isOpen
          ? { opacity: 1, scale: 1, filter: "blur(0px)" }
          : { opacity: 0, scale: 0.3, filter: "blur(20px)" },
        {
          duration: 0.1,
          delay: isOpen ? staggerMenuItems : 0,
        }
      );

      if(!isOpen){
        animate(scope.current,{display: "none"},{delay: .5});
      }
    }
  }, [isOpen,animate,scope,loggedInUser]);

  return scope;
}






