import { useState } from "react";
import { BsFillEyeFill } from "react-icons/bs";
import { BsFillEyeSlashFill } from "react-icons/bs";

function PasswordInputWithEye({value,onChange,name,id,placeholder}){
    const [type, setType] = useState("password");

    return (
        <div className="relative flex justify-end items-center">
            <input value={value} onChange={onChange} type={type} name={name} id={id} placeholder={placeholder || "••••••••"} className="bg-secondry-color/20 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" required=""/>
            {
                type === "password" && <BsFillEyeSlashFill onClick={()=>setType("text")} className='absolute mr-3 cursor-pointer'/>
            }
            {
                type === "text" && <BsFillEyeFill onClick={()=>setType("password")} className='absolute mr-3 cursor-pointer'/>
            }
        </div>
    )
}


export {PasswordInputWithEye};