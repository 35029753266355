import React from 'react';

function FinalMessage({handleSubmit}) {

  const handleNext = () => {
    handleSubmit();
  }


  return (
    <div className='container mx-auto'>
        <div className="max-w-[600px] mx-auto pt-10">
            <h5 className='text-center'>Awesome. You've set everything up for now.</h5>
            <h5 className='text-center mt-5'>Now. Let's track your activity for the first time & then take a quick peek at your Personal Growth dashboard!</h5>

              <div className="flex justify-center">
                  <button onClick={handleNext} className='mt-10 rounded-md px-4 py-2 text-white bg-blue-700 font-bold'>Continue</button>
              </div>
        </div>
    </div>
  )
}

export default FinalMessage;