import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PrimaryButton } from "../../../components";
// import {useNavigate} from "react-router-dom";

function UserInfo({info,setActivities,setInfo,selectedActivities,handleSubmit,isCommingFromPrePostSurvey,setSections}){

   
    const [isReadytoNext, setIsReadytoNext] = useState(false);
    const loggedInUser = useSelector(state => state.loggedInUser);
    const placeHolder = "Enter activity name";
    // const navigator = useNavigate();
   

    const handleChange =(e) => {
        setInfo(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        });   
    }

    useEffect(()=>{
        let fillCount = 0;

        for (const iterator in info) {
            const act = selectedActivities.find(ac => ac.surveyName === iterator);
            if(act && info[iterator]){
                fillCount++;
            }
        }

        if(fillCount === selectedActivities.length){
            setIsReadytoNext(true);
        }
        else{
            setIsReadytoNext(false);
        }

    },[info,selectedActivities]);


    useEffect(() => {
        setInfo(prev => {
            const temp = {};

            for (const key in prev) {
                const t = loggedInUser.activities?.find(s => s.category === key);
                if(t){
                    temp[key] = t.name;
                }
                else {
                    temp[key] = "";
                }
            }

            return {
                ...prev,
                ...temp
            }
        })
    }, [loggedInUser.activities,setInfo]);


    useEffect(() => {
        setActivities((prev) => {
            return prev.map(act => {
                const cat = loggedInUser.activities.find(a => a.category === act.surveyName);
                return cat? {...act,selected: true}: act;
            })
        });
    }, [loggedInUser.activities,setActivities]);


    const giveInfo = (act) => {
            switch (act.surveyName) {
                case "work":
                    return <Field preText={"My"} value={info.work} name={"work"} onChange={handleChange} placeHolder={placeHolder} postText={"Work"}/>
                case "relationship":
                    return <Field preText={"My relationship with"} value={info.relationship} name={"relationship"} onChange={handleChange} placeHolder={placeHolder} postText={""}/>

                case "hobby":
                    return <Field preText={"My"} value={info.hobby} name={"hobby"} onChange={handleChange} placeHolder={placeHolder} postText={"Hobby"}/>
                
                case "study":
                    return <Field preText={"My"} value={info.study} name={"study"} onChange={handleChange} placeHolder={placeHolder} postText={"Study"}/>

                case "sport":
                    return <Field preText={"My"} value={info.sport} name={"sport"} onChange={handleChange} placeHolder={placeHolder} postText={"Sport"}/>

                case "business":
                    return <Field preText={"My"} value={info.business} name={"business"} onChange={handleChange} placeHolder={placeHolder} postText={"Business"}/>

                default:
                    break;
            }
    }


    const handleNext = () => {
        if(isCommingFromPrePostSurvey){
            setSections(prev => {
                return {
                    ...prev,
                    userInfo: false,
                    finalMessage: true
                }
            })
        }
        else {
            handleSubmit();
        }
    }

    return (<>
        <h1 className='text-center pt-10 mb-1 text-4xl font-bold text-secondry-color'>XLRate</h1>
        <p className='text-center pb-10 text-secondry-color w-[500px] max-w-full mx-auto font-bold'>Enter the specific titles or names of the things you want track and improve.</p>
        

        {/* activities */}
        <div className='flex flex-col items-center gap-10 flex-wrap'>
            {
                selectedActivities.map((act) => {
                    return <div key={act.text} className="w-[500px] max-w-full">{giveInfo(act)}</div>;
                })
            }
        </div>

        {/* next btn  */}
        <div className='w-[500px] max-w-full mx-auto mt-20'>
            <PrimaryButton style={{padding: "12px 0"}} onClick={handleNext} disabled={!isReadytoNext}>Next</PrimaryButton>
        </div>

    </>)
}



export default UserInfo;



function Field({preText,postText,value,name,onChange,placeHolder}){

    return (<>
        <div className="text-secondry-color flex justify-start items-center gap-3 w-full">
            <span className="font-bold">{preText}</span> 
            <input className="flex-1 bg-secondry-color/20 rounded-md px-4 py-2" type="text" value={value} name={name} onChange={onChange} placeholder={placeHolder}/> 
            <span className="font-bold">{postText}</span>
        </div>
    </>)
}