
export const defaultRadioValues = [
    {
        day: 1,
        dayName: "Mon",
        time: "00:00",
        times: [],
        isChecked: false
    },
    {
        day: 2,
        dayName: "Tues",
        time: "00:00",
        times: [],
        isChecked: false
    },
    {
        day: 3,
        dayName: "Wed",
        time: "00:00",
        times: [],
        isChecked: false
    },
    {
        day: 4,
        dayName: "Thurs",
        time: "00:00",
        times: [],
        isChecked: false
    },
    {
        day: 5,
        dayName: "Fri",
        time: "00:00",
        times: [],
        isChecked: false
    },
    {
        day: 6,
        dayName: "Sat",
        time: "00:00",
        times: [],
        isChecked: false
    },
    {
        day: 0,
        dayName: "Sun",
        time: "00:00",
        times: [],
        isChecked: false
    }
]