import {fetchRequest,setToken} from "../../utils";
import {
    LOGIN_USER, 
    RESET_LOADING, 
    SET_COMPLETED_SUVRYES, 
    SET_GROUPS, 
    SET_IMPROVEMENT_STREAK, 
    SET_LOADING, 
    SET_PERSOANL_IMPROVEMENT_POINTS, 
    SET_SURVEYS , 
    USER_RESET,
    DOMAIN_NAME
} from "../../constants";
import {toast} from "react-toastify";



export const getLoggedInUser = () => async (dispatch) =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/user/mydetails/me`,"GET",{});
        // console.log(res);
        if(res.success){
           dispatch({type: LOGIN_USER ,payload: res.user});
           dispatch({type: SET_PERSOANL_IMPROVEMENT_POINTS ,payload: res.user.personalgrowthpoints});
           dispatch({type: SET_IMPROVEMENT_STREAK ,payload: res.streak});
           //  toast.success("Login Success!");
        }
        else {
            // toast.error(res.message)
        }
    } catch (error) {
        dispatch({type: USER_RESET ,payload: ''});
    }
}


export const createUser = (user) => async (dispatch) =>{

    try {
        dispatch({type:SET_LOADING,payload:null});
        const res = await fetchRequest(`${DOMAIN_NAME}/user/signup`,"POST",user);
        if(res.success){
            setToken(res.token);
            dispatch({type:LOGIN_USER,payload:res.user});
            dispatch({type:RESET_LOADING,payload:null});
        }
        else {
            toast.error(res.message);
            dispatch({type:RESET_LOADING,payload:null});
        }
    } catch (error) {
        dispatch({type:RESET_LOADING,payload:null});
    }

    // console.log(res);
}


export const createUsers = async ({users})  =>{

    try {
        users = users.map(user => {return {...user,onbordingEmailTime: new Date(user.onbordingEmailTime)}});

        const res = await fetchRequest(`${DOMAIN_NAME}/user/createmaney`,"POST",{users});

        return res;
     
        
    } catch (error) {
    }
}


export const updateBatchUsers = async (data)  =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/user/update/batch/users`,"PUT",data);

        return res;
     
        
    } catch (error) {
    }
}




export const getAllUsers = async () =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/user/all`,"GET",{})

        return res;

    } catch (error) {
        toast.error(error.message);
    }    
}


export const getOutStandingSurveys = async (userId) =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/user/out-standing-surveys/of/user/${userId}`,"GET",{})

        return res;

    } catch (error) {
        toast.error(error.message);
    }    
}

export const getAllSurveyLinks = async (userId) =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/user/all-surveys/of/user/${userId}`,"GET",{})

        return res;

    } catch (error) {
        toast.error(error.message);
    }    
}


export const onBoardUser = async (userId) =>{

    try {
        
        const res = await fetchRequest(`${DOMAIN_NAME}/user/onboard-user/${userId}`,"POST",{test: "this is test"})
        console.log(res);
        return res;

    } catch (error) {
        console.log(error);
        toast.error(error.message);
    }    
}

export const markOnboardingDone = async () =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/user/mark-onboarding-done`,"PUT",{})

        return res;

    } catch (error) {
        toast.error(error.message);
    }    
}

export const markStepsDone = async (userId,stepName) =>{
    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/user/mark-steps-done/${userId}`,"PUT",{stepName})

        return res;

    } catch (error) {
        toast.error(error.message);
    }    
}


export const getAllSurveys = () => async (dispatch) =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/surveytitle/all`,"GET",{})
   

        if(res.success){             
          dispatch({type: SET_SURVEYS,payload: res.surveyTitles});
        }
        else {
            toast.error(res.message)
        }

    } catch (error) {
        dispatch({type: USER_RESET ,payload: ''});
    }
      
    
}


export const getAllGroups = () => async (dispatch) =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/group/all`,"GET",{})

        
        if(res.success){            
            dispatch({type: SET_GROUPS,payload: res.groups});
        }
        else {
            // console.log(res);
            // console.log("aa");
            // toast.error(res.message);
        }

    } catch (error) {
        dispatch({type: USER_RESET ,payload: ''});
    }
      
    
}

export const getAllCompletedSurveys = () => async (dispatch) =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/survey/all`,"GET",{})
   

        if(res.success){     
            console.log(res);       
          dispatch({type: SET_COMPLETED_SUVRYES,payload: res.surveys});
        }
        else {
            toast.error(res.message)
        }
    } catch (error) {
        console.log(error)
        dispatch({type: USER_RESET ,payload: ''});
    }
      
    
}


export const allowStopAccessToDashboardOfUsers = (users,canAccessDashboard) => async (dispatch) => {

    try {

        const res = await fetchRequest(`${DOMAIN_NAME}/user/allow/stop/access/to/dashboard`,"PUT",{users,canAccessDashboard})
        
   
        if(res.success){            
            toast.success(`Access ${canAccessDashboard? "allowed":"Denyed"}`);
            dispatch({type: SET_GROUPS,payload: res.groups});
        }
        else {
            console.log(res);
            console.log("aa");
            toast.error(res.message);
        }

    } catch (error) {
        
    }
}


export const getAllUsersSurnameForename = async () => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/user/all-users/surname/forename`,"GET",null)
        
        return res;

    } catch (error) {
        
    }
}





export const deleteUser = async (id) => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/user/${id}`,"DELETE",{})
   
        return res;
    
    } catch (error) {
        toast.error(error.message)
    }
 
}


export const updateUser = async (id,data) => {

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/user/${id}`,"PUT",data);

        return res;

    } catch (error) {
        toast.error(error.message)
    } 
}


export const assignRemoveUserFromGroup = async (id,data) =>{

    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/group/add/${id}`,"PUT",{groups: data});
   
        return res;

    } catch (error) {
        toast.error(error.message);
    }
}


export const getUserById = async (id) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/user/${id}`,"GET",{});

    return res;
}

export const getUserByResetPasswordToken = async (token) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/user/by/reset/token?resetToken=${token}`,"GET",{});
    
    return res;
}

export const assignRemoveManagementGroups = async (userId,groups)=>{
    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/user/assign-remove-management-groups/${userId}`,"PUT",{groups});
        return res;

    } catch (error) {
        toast.error(error.message);
    }
}


export const updateDashboardSeenDate = async (userId) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/user/dashboardseenon/${userId}`,"GET",{});

    return res;
}


export const getGrowthPointsById = async (userId) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/user/growthpoints/${userId}`,"GET",{});

    return res;
}


export const addOrUpdateSurveyPreference = async (userId,surveyPreference) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/user/add-survey-preference/${userId}`,"PUT",{surveyPreference});

    return res;
}


export const setSurveyScreenSeen = async (userId,screen) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/user/screen/seen/${userId}`,"PUT",{screen});

    return res;
    
}


export const updateUserActivities = async (userId,activities) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/user/${userId}/activities/purpose/goal`,"PUT",{activities});

    return res;
}


export const setFirebaseToken = async (token) => {

    const res = await fetchRequest(`${DOMAIN_NAME}/user/firebase/token`,"PUT",{token});

    return res;
}

export const createTestUser = async () => {

    const res = await fetchRequest(`${DOMAIN_NAME}/user/create-test-user`,"POST",{});

    return res;
}



