export function displayTime(time){
    if(time.hour !== undefined && time.minute !== undefined){
        let hour = time.hour;
        let minute = time.minute;
        let ampm = hour >= 12 ? 'pm' : 'am';
        hour = hour % 12;
        hour = hour ? hour : 12; // the hour '0' should be '12'
        minute = minute < 10 ? '0'+minute : minute;

        return `${hour}:${minute} ${ampm}`;
    }

    let [hour,minute] = time.split(":");
    hour = Number(hour)
    minute = Number(minute)
    let ampm = hour >= 12 ? 'pm' : 'am';
    hour = hour % 12;
    hour = hour ? hour : 12; // the hour '0' should be '12'
    minute = minute < 10 ? '0'+minute : minute;

    return `${hour}:${minute} ${ampm}`;
}