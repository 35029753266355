import React, { useState } from 'react';
import { useEffect } from 'react';
import {GoCheck} from "react-icons/go";



function MultiStep({steps,completedSteps}) {
    const [showGlow, setShowGlow] = useState(false);
    
    useEffect(()=> {
        setShowGlow(true);
        setTimeout(()=>{
            setShowGlow(false);
        },500)
    },[completedSteps]);

  return (
    <div>
        <ol className="flex items-center w-full mb-4 pl-0 sm:mb-5">
            {
                new Array(steps).fill(1).map((st,i)=> {
                    let item = i+1;
                    
                    if(item === steps){
                        if(completedSteps === steps){
                            return <li key={i} className="flex text-white items-center">
                                    <div className={`transition-shadow ${showGlow && item === completedSteps? "shadow-[0_0_10px_10px_rgba(0,0,0,0.3)]":""} shadow-brand-peach flex items-center justify-center w-6 h-6 bg-primary-button-color rounded-full lg:h-8 lg:w-8 dark:bg-blue-800 shrink-0`}>
                                      <GoCheck size={20}/>
                                    </div>
                                </li>
                        }
                        else {
                            return <li key={i} className="flex items-center">
                                        <div className="flex items-center justify-center w-6 h-6 bg-gray-400 rounded-full lg:h-8 lg:w-8 dark:bg-gray-700 shrink-0"/>
                                    </li>
                        }
                        
                    }


                    if (item <= completedSteps) {
                        return <li key={i} className="flex w-full items-center text-white dark:text-green-500 after:content-[''] after:w-full after:h-1 after:border-b after:border-primary-button-color after:border-4 after:inline-block dark:after:border-green-800">
                                    <div className={`transition-shadow ${showGlow && item === completedSteps? "shadow-[0_0_10px_10px_rgba(0,0,0,0.3)]":""} shadow-primary-button-color/50 flex items-center justify-center w-6 h-6 bg-primary-button-color rounded-full lg:h-8 lg:w-8 dark:bg-blue-800 shrink-0`}>
                                      <GoCheck size={20}/>
                                    </div>
                                </li>
                    }
                    else {
                        return  <li key={i} className="flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-400 after:border-4 after:inline-block dark:after:border-gray-700">
                                    <div className="flex items-center justify-center w-6 h-6 bg-gray-400 rounded-full lg:h-8 lg:w-8 dark:bg-gray-700 shrink-0">
                                       {/* {item} */}
                                    </div>
                                </li>
                    }
                })
            }
        </ol>
    </div>
  )
}

export default MultiStep